import React from 'react';
import { Tabs } from 'antd';
import { AddAggregation } from './AddAggregation';
import { AddWorldAggregation } from './AddWorldAggregation';
import { DeleteAggregation } from './DeleteAggregation';
import { EditAggregation } from './EditAggregation';

const { TabPane } = Tabs;

export const AggregationOperations = () => {
    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Add Aggregation" key="1">
                <AddAggregation />
            </TabPane>
            {
                /*
                    <TabPane tab="Add World Aggregation Data" key="2">
                        <AddWorldAggregation />
                    </TabPane>
                    <TabPane tab="Edit Aggregation" key="2">
                        <EditAggregation />
                    </TabPane>
                    <TabPane tab="Delete Aggregation" key="3">
                        <DeleteAggregation />
                    </TabPane>
                */
            }
        </Tabs>
    );
};