import React, { useEffect, useState } from 'react';
import { Modal, Descriptions, Spin } from 'antd';
import AxiosOICStat from '../../AxiosOICStat';


import "../../style/App.scss"

export const IndicatorShowExternal = ({ visible, record, onCancel }) => {
    const [indicatorData, setIndicatorData] = useState({});
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        if (record && record.ind_code) {
            AxiosOICStat.get(`/indicators/info-box/${record.ind_code}`)
                .then(response => {
                    console.log("infobox", response.data);
                    setTitle(`${response.data.ind_name_eng} [${response.data.unit_name}]`);
                    setIndicatorData(response.data);
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                });
        }
    }, [record]);





    return (
        <Modal
            visible={visible}
            title={title}
            onCancel={onCancel}
            width="75%"
            footer={[]}
        >
            {loading ? (
                <Spin size="large" />
            ) : (
                indicatorData && (
                    <Descriptions column={1}>
                        <Descriptions.Item label="Definition:">
                            {indicatorData.ind_eng_exp}
                        </Descriptions.Item>
                        <Descriptions.Item label="Category(ies):">
                            {indicatorData.categories}
                        </Descriptions.Item>
                        <Descriptions.Item label="Source:">
                            {indicatorData.source_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Data Period:">
                            {indicatorData.data_period}
                        </Descriptions.Item>
                        <Descriptions.Item label="Last Update Date:">
                            {indicatorData.last_update_date}
                        </Descriptions.Item>
                    </Descriptions>
                )
            )}
        </Modal>
    );
};