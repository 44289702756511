import React from 'react';
import { Row, Col, Grid } from 'antd';

const { useBreakpoint } = Grid;

const ColumnBasedLayout = ({ items, renderItem, responsiveColumns, isJustifyStart = false, rowBasedLayout = false }) => {
    const screens = useBreakpoint();

    // Determine the number of columns based on the screen size
    const columnCount = screens.xl ? responsiveColumns.xl :
        screens.lg ? responsiveColumns.lg :
            screens.md ? responsiveColumns.md :
                screens.sm ? responsiveColumns.sm :
                    responsiveColumns.xs;

    // Determine the usual number of items per column
    const rowsPerColumn = Math.ceil(items.length / columnCount);
    if (rowBasedLayout) {
        return (
            <Row gutter={isJustifyStart ? [16, 16] : [4, 8]} justify={isJustifyStart ? "start" : "stretch"}>
                {items.map((item, idx) => (
                    <Col key={idx} flex={isJustifyStart ? "none" : `0 0 ${100 / columnCount}%`}>
                        <div style={{ marginBottom: idx === items.length - 1 ? '0px' : '8px' }}>
                            {renderItem(item)}
                        </div>
                    </Col>
                ))}
            </Row>
        );
    } else {
        const columns = Array.from({ length: columnCount }, (_, colIndex) => {

            const start = colIndex * rowsPerColumn;
            const end = start + rowsPerColumn;
            return items.slice(start, Math.min(end, items.length));

        });

        return (
            <Row gutter={isJustifyStart ? [16, 16] : [4, 8]} justify={isJustifyStart ? "start" : "stretch"}>
                {columns.map((columnItems, idx) => (
                    <Col key={idx} flex={isJustifyStart ? "none" : `0 0 ${100 / columnCount}%`}>
                        {columnItems.map((item, itemIdx) => (
                            <div key={itemIdx} style={{ marginBottom: itemIdx === columnItems.length - 1 ? '0px' : '8px' }}>
                                {renderItem(item)}
                            </div>
                        ))}
                    </Col>
                ))}
            </Row>
        );
    }
};

export default ColumnBasedLayout;
