import React from 'react';
import { RegionMappings } from '../components/RegionMapping';
import { WorldMappings } from '../components/WorldMapping';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export const RegionTable  = () => {
    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="OIC Regions" key="1">
                <RegionMappings />
            </TabPane>
            <TabPane tab="World Regions" key="2">
                <WorldMappings />
            </TabPane>
        </Tabs>
    );
};