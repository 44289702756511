import React from 'react';
import { Card, Statistic } from 'antd';

const cardStyle = {
    borderRadius: '10px',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    height: '100%',
};

const titleStyle = {
    textAlign: 'center',
    fontSize: '16px',
    color: '#555',
    marginBottom: '20px',
};

const valueStyle = {
    textAlign: 'center',
    fontSize: '24px',
    color: '#000',
    fontWeight: 'bold',
};

const noteStyle = {
    textAlign: 'center',
    color: '#999',
    fontSize: '12px',
    marginTop: '12px',
};

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

const DataCard = ({ title, value, note = "", isValueDate = false }) => (
    <Card style={cardStyle} bordered={false}>
        <div style={titleStyle}>{title}</div>
        {isValueDate && <div style={valueStyle}>{formatDate(value)}</div>}
        {!isValueDate &&
            <Statistic
                value={value}
                valueStyle={valueStyle}
            // You can add precision and prefix/suffix if needed
            // precision={2}
            // prefix="$"
            // suffix="USD"
            />
        }
        {note && <div style={noteStyle}>{note}</div>}
    </Card>
);

export default DataCard;