import React from "react";
// import { createRoot } from 'react-dom/create';
import 'react-toastify/dist/ReactToastify.css';

import "./style/theme.scss";
import "./style/App.scss";
import "./style/scrollbar.css";

import { DefaultRouterProvider } from "./router/router";
import { ConfigProvider, theme } from 'antd';
import { ToastContainer } from 'react-toastify';
import { MessageProvider } from "./components/providers/MessageContext";

function App() {
  return (
    <React.Fragment>
      <ConfigProvider
        theme={{
          token: {
            colorBgLayout: '#d4e6f5',
            colorPrimary: '#519BD6',

            colorText: '#000',
          }
        }}
      >
        <MessageProvider>
          <DefaultRouterProvider />
        </MessageProvider>
      </ConfigProvider>
      <ToastContainer position="bottom-right" />
    </React.Fragment>
  );
}

export default App;
