import React, { useState, useEffect } from 'react';
import AxiosOICStat from '../AxiosOICStat';
import { Select, Button, Row, Col, Tooltip, Checkbox, Collapse, Radio, Typography, message } from 'antd';
import DataTable from '../components/DataTable';
import { useSelector } from 'react-redux';

const { Option } = Select;
const { Group: RadioGroup } = Radio;

export function DataMatching({ auto_search = false }) {
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const [selectedYear, setSelectedYear] = useState(undefined); // Single year selection
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const countriesState = useSelector((state) => state.countries);
    const indicatorsState = useSelector((state) => state.indicators);
    const unitsState = useSelector((state) => state.units);
    const [countryMap, setCountryMap] = useState({});
    const [regionMappingsData, setRegionMappingsData] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [overwriteOption, setOverwriteOption] = useState("skip"); // Default option is "skip"
    const [isMatching, setIsMatching] = useState(false);

    const renderTagPlaceholder = (omittedValues) => {
        // Sort the omitted values alphabetically based on their labels
        const sortedOmittedValues = [...omittedValues].sort((a, b) => parseInt(a.value) - parseInt(b.value));

        // Create a multiline string of all sorted omitted values
        const fullList = sortedOmittedValues.map(val => val.label).join('\n');
        return (
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{fullList}</span>}>
                <span>{`+${omittedValues.length} more`}</span>
            </Tooltip>
        );
    };

    const availableYears = Array.from({ length: 2024 - 1970 + 1 }, (_, i) => 1970 + i).reverse();

    useEffect(() => {
        // Assume these are the endpoints to fetch platform and mission data
        if (!auto_search) {

        }
        else {
            handleSearch();
        }
    }, [auto_search]);

    const handleSearch = () => {
        setLoading(true);

        const formattedCountries = selectedCountries.map(country => country.split(' - ')[0]);
        const formattedIndicators = selectedIndicators.map(indicator => indicator.split(' - ')[0]);

        AxiosOICStat.post('/data/query', {
            countries: formattedCountries,
            indicators: formattedIndicators,
            years: [selectedYear]
        }).then(response => {
            const updatedData = response.data.map((element, index) => {
                element.key = index;
                element.u_name = unitsState.value[element.u_code]?.unit_name_eng;
                return element;
            });
            console.log("updatedData", updatedData);
            setSearchResults(updatedData);
            setLoading(false);
        });

    };


    const handleMatch = () => {
        setIsMatching(true); // Disable the button and change text to 'Matching...'

        // Log the option to confirm it
        console.log(`Matching data with overwrite option: ${overwriteOption}`);

        // Extract the required parameters
        const formattedCountries = selectedCountries.map(country => country.split(' - ')[0]);
        const formattedIndicators = selectedIndicators.map(indicator => indicator.split(' - ')[0]);

        // Construct the request payload
        const payload = {
            countries: formattedCountries,
            indicators: formattedIndicators,
            year: selectedYear, // Single year selection
            overwrite: overwriteOption // Include the overwrite option in the payload
        };

        
        // TODO discuss and enable /data/match again
        setIsMatching(false); // Re-enable the button and change text back to 'Match'

        // Send the API request using AxiosOICStat
        // AxiosOICStat.post('/data/match', payload)
        //     .then(response => {
        //         console.log("Match response:", response.data);
        //         message.success('Data matching completed successfully');
        //     })
        //     .catch(error => {
        //         console.error("Match API call failed:", error);
        //         message.error('Data matching failed');
        //     }).finally(() => {
        //         setIsMatching(false); // Re-enable the button and change text back to 'Match'
        //     });
    };

    useEffect(() => {
        // sort the selected values
        setSelectedCountries(selectedCountries.sort((a, b) => parseInt(a) - parseInt(b)));
        setSelectedIndicators(selectedIndicators.sort((a, b) => parseInt(a) - parseInt(b)));
    },
        [selectedCountries, selectedIndicators]
    );

    useEffect(() => {
        // Fetch the region mappings when the component is mounted
        const fetchRegionMappings = async () => {
            try {
                const response = await AxiosOICStat.get('/region-mappings/'); // Adjust the endpoint as needed
                setRegionMappingsData(response.data); // Assuming the response body will be the array of region mappings
            } catch (error) {
                console.error('Failed to fetch region mappings:', error);
                // Handle errors, e.g., show notification
            }
        };

        fetchRegionMappings();
    }, []);

    const fetchRegionCountries = async (regionId) => {
        try {
            const response = await AxiosOICStat.get(`/regions-countries/${regionId}`); // Adjust the endpoint as needed
            return response.data; // Assuming the response body will be the array of country codes
        } catch (error) {
            console.error('Failed to fetch region countries:', error);
            // Handle errors, e.g., show notification
        }
    };

    const regions = [
        {
            id: 1,
            label: 'Regions',
            children: <Row gutter={[8, 8]}>
                {regionMappingsData && regionMappingsData.map((region) => (
                    <Col span={6} key={region.id}>
                        <Checkbox
                            key={region.id}
                            checked={selectedRegions.includes(region.id)}
                            onChange={(e) => {
                                fetchRegionCountries(region.id).then(countries => {
                                    let updatedCountries = countries.map(fetchedCountry => {
                                        // find the country object in `countriesState` by its ID
                                        let country = Object.values(countriesState.value)
                                            .filter(stateCountry => stateCountry.c_code === fetchedCountry.country_id)[0]

                                        if (country && country.c_short_name_eng) {
                                            country = country.c_short_name_eng;
                                        } else return '';
                                        return `${fetchedCountry.country_id} - ${country}`;
                                    });

                                    updatedCountries = updatedCountries.filter(country => country !== '');

                                    const differenceCountries = []

                                    if (e.target.checked) {
                                        setCountryMap(prevMap => {
                                            const newMap = { ...prevMap };
                                            updatedCountries.forEach(country => {
                                                if (newMap[country] === undefined || newMap[country] === 0) {
                                                    newMap[country] = 1;
                                                    differenceCountries.push(country);
                                                } else {
                                                    newMap[country] += 1;
                                                }
                                            });
                                            return newMap;
                                        });
                                    } else {
                                        setCountryMap(prevMap => {
                                            const newMap = { ...prevMap };
                                            updatedCountries.forEach(country => {
                                                if (newMap[country] > 1) {
                                                    newMap[country] -= 1;
                                                } else {
                                                    newMap[country] = 0;
                                                    differenceCountries.push(country);
                                                }
                                            });
                                            return newMap;
                                        });
                                    }

                                    // handle checkbox change
                                    setSelectedRegions(e.target.checked
                                        ? [...selectedRegions, region.id]
                                        : selectedRegions.filter(selectedRegion => selectedRegion !== region.id)
                                    );

                                    setSelectedCountries(prevCountries => {
                                        if (e.target.checked) {
                                            return [...new Set([...prevCountries, ...differenceCountries])];
                                        } else {
                                            return prevCountries.filter(country => !differenceCountries.includes(country));
                                        }
                                    });

                                });
                            }}
                            value={region.id}
                        >
                            {region.region_name}
                        </Checkbox>
                    </Col>
                ))}
            </Row>
        }
    ]

    return (
        <>
            {!auto_search &&
                <Row style={{ marginBottom: '10px' }}>
                    <Collapse size="small" items={regions} bordered={false} style={{ marginBottom: "10px", width: "100%", backgroundColor: "white" }} />
                    <Row>
                        <Select
                            mode="multiple"
                            placeholder="Select Countries"
                            onChange={setSelectedCountries}
                            maxTagCount={2}
                            maxTagPlaceholder={renderTagPlaceholder}
                            style={{ minWidth: '200px', marginBottom: '5px', marginRight: '10px' }}
                            showSearch
                            value={selectedCountries}
                        >
                            {Object.values(countriesState.value)
                                .filter(country => selectedCountries.includes(String(country.c_code)))
                                .concat(Object.values(countriesState.value).filter(country => !selectedCountries.includes(String(country.c_code))))
                                .map(country => <Option key={country.c_code} value={`${country.c_code} - ${country.c_short_name_eng}`}>{`${country.c_code} - ${country.c_short_name_eng}`}</Option>)}
                        </Select>
                        <Select
                            mode="multiple"
                            placeholder="Select Indicators"
                            //defaultValue={preSelectedIndicators} 
                            onChange={setSelectedIndicators}
                            style={{ minWidth: '500px', marginBottom: '5px', marginRight: '10px' }}
                            maxTagCount={3}
                            maxTagPlaceholder={renderTagPlaceholder}
                            showSearch
                        >
                            {Object.values(indicatorsState.value)
                                .filter(indicator => selectedIndicators.includes(String(indicator.ind_code)))
                                .concat(Object.values(indicatorsState.value).filter(indicator => !selectedIndicators.includes(String(indicator.ind_code))))
                                .map(indicator => <Option key={indicator.ind_code} value={`${indicator.ind_code} - ${indicator.ind_name_eng}`}>{`${indicator.ind_code} - ${indicator.ind_name_eng}`}</Option>)}
                        </Select>
                        <Typography.Text strong style={{ marginRight: '10px', marginBottom: '5px', lineHeight: '32px' }}>
                            Select Base Year to fill the next years:
                        </Typography.Text>
                        <Select
                            placeholder="Select Year"
                            style={{ minWidth: '150px', marginRight: '10px', marginBottom: '5px' }}
                            onChange={value => setSelectedYear(value)}
                            value={selectedYear}
                        >
                            {availableYears.map(year => (
                                <Option key={year} value={year}>{year}</Option>
                            ))}
                        </Select>

                        <Button type="primary" onClick={handleSearch} style={{ marginLeft: '10px', }}>Search</Button>
                    </Row>
                </Row>
            }
            {/* Row with Radio buttons and Match button */}
            {
                searchResults.length > 0 && <Row gutter={[16, 16]} align="middle" style={{ marginBottom: '10px' }}>
                    <Col>
                        <RadioGroup onChange={(e) => setOverwriteOption(e.target.value)} value={overwriteOption}>
                            <Radio value="overwrite">Overwrite Existing Values</Radio>
                            <Radio value="skip">Skip Existing Values</Radio>
                        </RadioGroup>
                    </Col>
                    <Button type="primary" onClick={handleMatch} disabled={isMatching}>
                        {isMatching ? 'Matching...' : 'Match'}
                    </Button>
                </Row>
            }

            <Row style={{ marginBottom: '10px' }}>
                <Typography.Text style={{
                    backgroundColor: '#f8d7da', // soft red for contrast
                    color: '#721c24', // dark red text for readability
                    padding: '10px',
                    borderRadius: 'px',
                    display: 'block',
                    border: '1px solid #f5c6cb' // light red border
                }}>
                    Warning: If the country, indicator, and base year tuple does not exist, the matching process for this tuple will be skipped.
                </Typography.Text>
            </Row>

            <Row>
                <Col xs={24}>
                    <DataTable data={searchResults} columns={["c_name", "ind_name", "d_year", "d_date", "u_name", "d_value"]} widths={['10%', '60%', '10%', '10%']} loading={loading} />
                </Col>
            </Row>
        </>
    );
};