import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosOICStat from '../AxiosOICStat';

export const fetchMe = createAsyncThunk(
    'user/me',
    async () => {
        const response = await AxiosOICStat.get(`me`);
        console.log("user", response.data);
        return response.data;
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        status: 'idle',
        username: '',
        first_name: '',
        last_name: '',
        role: 0
    },
    reducers: {
        setUser: (state, action) => {
            state.name = action.payload.name;
            state.email = action.payload.email;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMe.pending, (state) => {
            state.status = 'loading';
        }).addCase(fetchMe.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.username = action.payload.username;
            state.first_name = action.payload.first_name;
            state.last_name = action.payload.last_name;
            state.role = action.payload.role;
        }).addCase(fetchMe.rejected, (state) => {
            state.status = 'failed';
        });
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
