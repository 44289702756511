import React from 'react';
import xmlbuilder from 'xmlbuilder';
import { Button } from 'antd';

const SDMXExporter = ({ indicatorData }) => {
    const handleExport = () => {
        // Check if there is data to export
        if (!indicatorData) {
            console.error('No data to export.');
            return;
        }

        // Transform indicator data to SDMX format
        const sdmxData = transformToSDMX(indicatorData);

        // Generate SDMX XML
        const sdmxXML = generateSDMXXML(sdmxData);

        // Create a Blob from the XML data
        const blob = new Blob([sdmxXML], { type: 'application/xml' });

        // Create a download link
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'exported_data.sdmx'; // You can change the filename as needed

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger a click on the link to start the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };

    const transformToSDMX = (indicatorData) => {
        if (!indicatorData) {
            return null;
        }

        // Extract relevant fields from the indicatorData object
        const { ...fields } = indicatorData;

        // Transform the fields into SDMX format
        const dimensions = Object.entries(fields).reduce((acc, [key, value]) => {
            // You might want to customize this part based on your SDMX format
            acc[key] = value;
            return acc;
        }, {});

        return {
            observation: {
                dimensions,
            },
        };
    };

    // Function to generate SDMX XML (customize this based on your needs)
    const generateSDMXXML = (sdmxData) => {
        const root = xmlbuilder.create('sdmx');

        // Your XML generation logic here
        // Example: Add elements to the XML based on the transformed SDMX data
        const obsElement = root.ele('observation');
        obsElement.ele('dataPoint', sdmxData.observation.dataPoint);

        const dimensionsElement = obsElement.ele('dimensions');
        Object.entries(sdmxData.observation.dimensions).forEach(([key, value]) => {
            dimensionsElement.ele(key, value);
        });

        // Return the formatted XML
        return root.end({ pretty: true });
    };

    return (
        <Button type="primary" onClick={handleExport}>
            Export to SDMX
        </Button>
    );
};

export default SDMXExporter;