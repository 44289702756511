import dayjs from 'dayjs';
import { Col, Select, Radio, Button, DatePicker } from 'antd';

const { RangePicker } = DatePicker;
const { Option } = Select;

const rangePresets = [
    {
        label: 'Last 7 Days',
        value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
        label: 'Last 14 Days',
        value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
        label: 'Last 30 Days',
        value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
        label: 'Last 90 Days',
        value: [dayjs().add(-90, 'd'), dayjs()],
    },
    {
        label: 'Last 180 Days',
        value: [dayjs().add(-180, 'd'), dayjs()],
    },
    {
        label: 'Last year',
        value: [dayjs().add(-1, 'y'), dayjs()],
    },
];


export const CustomRangePicker = ({ dateRange, setDateRange, type, setType, setFrequency, frequency, fetchData }) => (
    <Col span={24}>
        <Select value={type} onChange={setType}>
            <Option value="date">Date</Option>
            <Option value="week">Week</Option>
            <Option value="month">Month</Option>
            <Option value="quarter">Quarter</Option>
            <Option value="year">Year</Option>
        </Select>
        <RangePicker
            value={dateRange}
            onChange={(dates) => {
                setDateRange(dates);
            }}
            presets={[
                ...rangePresets,
            ]}
            picker={type}
            style={{ marginLeft: '20px' }}
        />
        <Radio.Group
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            style={{ marginLeft: '20px' }}
        >
            <Radio.Button value="daily">Daily</Radio.Button>
            <Radio.Button value="monthly">Monthly</Radio.Button>
        </Radio.Group>
        <Button
            type="primary"
            onClick={fetchData}
            style={{ marginLeft: '20px' }}
        >
            Fetch Data
        </Button>
    </Col>
)