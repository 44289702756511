export const convertDataForLineChart = (originalData) => {
    const convertedData = originalData.map((item, index) => ({
        x: item.date_calculated, // Assuming x-axis represents the index of the data point
        y: item.data_counts, // Use data_counts as the y-axis value
    }));

    return [
        {
            id: 'Data Count', // Provide a unique id for your series
            color: 'hsl(348, 70%, 50%)', // Set a color for your series
            data: convertedData,
        },
        // Add more series if needed
    ];
};

export const convertDataForIndicatorsCount = (originalData) => {
    const convertedPublicData = originalData.map((item, index) => ({
        x: item.date_calculated, // Assuming x-axis represents the index of the data point
        y: item.public_indicators_count, // Use indicators_count as the y-axis value
    }));

    const convertedPrivateData = originalData.map((item, index) => ({
        x: item.date_calculated, // Assuming x-axis represents the index of the data point
        y: item.private_indicators_count, // Use indicators_count as the y-axis value
    }));

    return [
        {
            id: 'Private Indicators', // Provide a unique id for your series
            color: 'hsl(348, 70%, 50%)', // Set a color for your series
            data: convertedPrivateData,
        },
        {
            id: 'Public Indicators', // Provide a unique id for your series
            color: 'hsl(348, 70%, 50%)', // Set a color for your series
            data: convertedPublicData,
        }
    ];
};

// for every number split by 3 digits
export const formatYWithDecimalDigits = (value) => {
    let formattedValue = "";
    while (value > 0) {
        let remainder = value % 1000;
        value = Math.floor(value / 1000);
        // add leading zeros
        if (value > 0 && remainder < 100) {
            let temp = "" + remainder;
            while (temp.length < 3) {
                temp = "0" + temp;
            }
            remainder = temp;
        }
        if (formattedValue.length > 0) {
            formattedValue = "." + formattedValue;
        }
        formattedValue = remainder + formattedValue;
    }
    return formattedValue;
};