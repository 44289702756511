import React, { useState } from 'react';
import { Form, Input, Button, Card, Row, Col, message } from 'antd';
import AxiosOICStat from '../AxiosOICStat';
import { Link  } from 'react-router-dom'; // Import useHistory
import { useDispatch } from 'react-redux';
import { login } from '../features/authSlice';
import { toast } from 'react-toastify';

export const Login = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onFinish = (values) => {
        setLoading(true);

        const formData = new FormData();
        formData.append('username', values.username);
        formData.append('password', values.password);

        AxiosOICStat.post('/token', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            dispatch(login(response.data.access_token));
            toast.success('Login successful!', { autoClose: 1000 });
            setTimeout(() => {
                window.location.href = '/';
            }, 500);
        }).catch(error => {
            console.log(error);
            if (error?.response?.status === 401) {
                toast.error(`Login failed. ${error?.response?.data?.detail}`);
                return;
            }
            toast.error(`Unknown error. Please try again later.${error}`);
        }).finally(() => {
            setLoading(false);
        });
    };

    const loginRowStyle = {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const loginCardStyle = {
        boxShadow: '0px 4px 8px 0 rgba(0,0,0,0.2)',
        width: '100%',
        maxWidth: '500px'
    };
    const registerButtonStyle = {
        display: 'block',
        textAlign: 'center',
        marginTop: '2rem'
    };

    return (
        <Row style={loginRowStyle}>
            <Col xs={24} sm={12} md={8} lg={6}>
                <Card style={loginCardStyle} title="Login" bordered={false}>
                    <Form name="login" initialValues={{ remember: true }} onFinish={onFinish} layout="vertical">
                        <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
                            <Input placeholder="Username" />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
                            <Input.Password placeholder="Password" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block loading={loading}>Log in</Button>
                            <span style={registerButtonStyle}>Don't have an account? <Link to="/register">Sign up</Link></span>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};
