import React, { useState, useRef } from 'react';
import { Button, Input, Checkbox } from 'antd';

export const FilterDropdown = ({
    options,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    numberFilter = false,
}) => {
    const [searchText, setSearchText] = useState('');
    const inputRef = useRef(null); // Create a ref for the input

    const onSearch = (value) => {
        setSearchText(value);
    };

    // Filter options based on search text
    const filteredOptions = options.filter(option => {
        if (numberFilter) {
            return option.text.toString().includes(searchText);
        } else {
            return option.text.toLowerCase().includes(searchText.toLowerCase())
        }
    });

    // Sort options so selected items are on top
    const sortedFilteredOptions = filteredOptions.sort((a, b) => {
        const aSelected = selectedKeys.includes(a.value);
        const bSelected = selectedKeys.includes(b.value);
        return bSelected - aSelected; // true values (selected) come first
    });

    const handleCheckboxChange = (optionValue) => {
        const newSelectedKeys = selectedKeys.includes(optionValue)
            ? selectedKeys.filter(key => key !== optionValue)
            : [...selectedKeys, optionValue];
        setSelectedKeys(newSelectedKeys);
        setSearchText('');

        // Focus on the input box after changing the checkbox state
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleClearFilters = () => {
        setSelectedKeys([]);
        setSearchText('');

        // set timer to confirm
        setTimeout(() => {
            confirm();
        }, 100);

        return clearFilters();
    };

    return (
        <div style={{ padding: 8, width: 250, maxHeight: 400, overflowY: 'auto' }}>
            <Input
                ref={inputRef}
                placeholder="Search filter"
                value={searchText}
                onChange={(e) => onSearch(e.target.value)}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                {sortedFilteredOptions.map(option => (
                    <div key={option.value} style={{ margin: '5px 0' }}>
                        <Checkbox
                            checked={selectedKeys.includes(option.value)}
                            onChange={() => handleCheckboxChange(option.value)}
                        >
                            {option.text}
                        </Checkbox>
                    </div>
                ))}
            </div>
            <Button
                type="primary"
                onClick={() => confirm()}
                style={{ width: '100%', marginBottom: 8 }}
            >
                Filter
            </Button>
            <Button onClick={handleClearFilters} style={{ width: '100%' }}>
                Reset
            </Button>
        </div>
    );
};
