import React from 'react'

import Sidebar from './sidebar'
import Topbar from './topbar'
// import SettingOffCanvas from '../../components/setting/SettingOffCanvas'
import { Outlet } from "react-router-dom";
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const { Header, Sider, Content } = Layout;

const RedirectToLogin = () => {
  return (
    <Navigate to='/login' />
  );
}

export const AppLayout = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <>
      {isAuthenticated ?
        <Layout className='layout-root'>
          <Header className='akd-header'>
            <Topbar />
          </Header>
          <Layout className='layout-page' style={{ minHeight: '100%' }}>
            <Sider className='akd-sidebar'>
              <Sidebar />
            </Sider>
            <Layout className='' style={{ padding: "16px" }}>
              <Content>
                <Outlet />
              </Content>
            </Layout>
          </Layout>
        </Layout>
        : <RedirectToLogin />
      }
    </>
  );
};