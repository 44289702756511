import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Input, Select, message, Popconfirm } from "antd";
import AxiosOICStat from "AxiosOICStat";

const { Option } = Select;

export const AdminPanel = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingUser, setEditingUser] = useState(null);

    const [form] = Form.useForm();

    // Fetch users
    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await AxiosOICStat.get("/users");
            setUsers(response.data);
        } catch (error) {
            message.error("Failed to fetch users.");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    // Handle edit
    const handleEdit = (user) => {
        setEditingUser(user);
        form.setFieldsValue(user);
        setIsModalOpen(true);
    };

    // Handle delete
    const handleDelete = async (userId) => {
        try {
            await AxiosOICStat.delete(`/users/${userId}`);
            message.success("User deleted successfully.");
            fetchUsers();
        } catch (error) {
            message.error("Failed to delete user.");
            console.error(error);
        }
    };

    // Handle form submission (edit/create)
    const handleFormSubmit = async (values) => {
        try {
            if (editingUser) {
                // Update user
                await AxiosOICStat.put(`/users/${editingUser.id}`, values);
                message.success("User updated successfully.");
            } else {
                // Create user
                await AxiosOICStat.post("/users", values);
                message.success("User created successfully.");
            }
            setIsModalOpen(false);
            fetchUsers();
        } catch (error) {
            message.error("Failed to save user.");
            console.error(error);
        }
    };

    // Open modal for creating a new user
    const handleAddNewUser = () => {
        setEditingUser(null);
        form.resetFields();
        setIsModalOpen(true);
    };

    // Fetch users on component mount
    useEffect(() => {
        fetchUsers();
    }, []);

    // Table columns
    const columns = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "First Name",
            dataIndex: "first_name",
            key: "first_name",
        },
        {
            title: "Last Name",
            dataIndex: "last_name",
            key: "last_name",
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            render: (role) => (role === 1 ? "Admin" : "User"), // Adjust as per role logic
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, user) => (
                <>
                    <Button type="link" onClick={() => handleEdit(user)}>
                        Edit
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to delete this user?"
                        onConfirm={() => handleDelete(user.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link" danger>
                            Delete
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    return (
        <div>
            <h1>Admin Panel</h1>
            <Button type="primary" onClick={handleAddNewUser} style={{ marginBottom: 16 }}>
                Add New User
            </Button>
            <Table
                dataSource={users}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 10 }}
            />
            <Modal
                title={editingUser ? "Edit User" : "Add New User"}
                visible={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onOk={() => form.submit()}
            >
                <Form form={form} onFinish={handleFormSubmit} layout="vertical">
                    <Form.Item
                        name="username"
                        label="Username"
                        rules={[{ required: true, message: "Please enter a username." }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            { required: !editingUser, message: "Please enter a password." },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="first_name"
                        label="First Name"
                        rules={[{ required: true, message: "Please enter the first name." }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        label="Last Name"
                        rules={[{ required: true, message: "Please enter the last name." }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="role"
                        label="Role"
                        rules={[{ required: true, message: "Please select a role." }]}
                    >
                        <Select>
                            <Option value={1}>Administrator</Option>
                            <Option value={2}>Researcher</Option>
                            <Option value={3}>User</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
