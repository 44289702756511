import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Modal, Form, message, Input, Select, Checkbox } from 'antd';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import AxiosOICStat from '../AxiosOICStat';
import { useSelector } from 'react-redux';
import { IndicatorTableShowModal } from '../components/modals/IndicatorTableShow';

const { Option } = Select;

const CategoryAddModal = ({ visible, onCancel, onAdd }) => {
    const [form] = Form.useForm();

    const handleAddSubmit = (values) => {
        // Handle add new category
        AxiosOICStat.post(`categories`, values).then(response => {
            message.success('Category added successfully');
            onAdd(response.data);
            onCancel();
        }).catch(err => {
            message.error('Error adding category');
            onCancel();
        });
    };

    return (
        <Modal
            visible={visible}
            title="Add New Category"
            onCancel={onCancel}
            onOk={() => form.submit()}
        >
            <Form form={form} onFinish={handleAddSubmit}>
                <Form.Item label="Code" name="cat_code">
                    <Input />
                </Form.Item>
                <Form.Item label="Name" name="cat_name_eng">
                    <Input />
                </Form.Item>
                <Form.Item label="Explanation" name="cat_eng_exp">
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label="Name (French)" name="cat_name_fr">
                    <Input />
                </Form.Item>
                <Form.Item label="Name (Arabic)" name="cat_name_arb">
                    <Input />
                </Form.Item>

            </Form>
        </Modal>
    );
};

const EditCategoryModal = ({ visible, onCancel, category, onEdit }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (category) {
            form.setFieldsValue(category);
        }
    }, [category]);

    const handleEditSubmit = (values) => {
        // Convert `cat_code` and `is_private` to the correct types
        const updatedValues = {
            ...values,
            cat_code: parseInt(values.cat_code, 10),
            is_private: values.is_private ? 1 : 0,
        };

        AxiosOICStat.put(`categories/edit`, updatedValues)
            .then((response) => {
                onEdit(response.data);
                onCancel();
            })
            .catch((err) => {
                console.error("Error editing category:", err.response?.data || err.message);
                message.error('Error editing category');
            });
    };

    return (
        <Modal
            visible={visible}
            title="Edit Category"
            onCancel={onCancel}
            onOk={() => form.submit()}
        >
            <Form form={form} onFinish={handleEditSubmit}>
                <Form.Item label="Code" name="cat_code">
                    <Input />
                </Form.Item>
                <Form.Item label="Name" name="cat_name_eng">
                    <Input />
                </Form.Item>
                <Form.Item label="Explanation" name="cat_eng_exp">
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label="Name (French)" name="cat_name_fr">
                    <Input />
                </Form.Item>
                <Form.Item label="Name (Arabic)" name="cat_name_arb">
                    <Input />
                </Form.Item>
                <Form.Item name="is_private" valuePropName="checked">
                    <Checkbox>Is Private</Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    );
};


const GoalSelectionModal = ({ visible, onCancel, onOk }) => {
    const [form] = Form.useForm();

    const handleOk = () => {
        form
            .validateFields()
            .then(values => {
                form.resetFields();
                onOk(values.goal);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <Modal
            visible={visible}
            title="Select Goal"
            onCancel={onCancel}
            onOk={handleOk}
        >
            <Form form={form} layout="vertical" name="goalSelectionForm">
                <Form.Item
                    name="goal"
                    label="Goal"
                    rules={[{ required: true, message: 'Please select a goal' }]}
                >
                    <Select placeholder="Select a goal">
                        <Option value="1">SDG1</Option>
                        <Option value="2">SDG2</Option>
                        <Option value="3">SDG3</Option>
                        <Option value="4">SDG4</Option>
                        <Option value="5">SDG5</Option>
                        <Option value="6">SDG6</Option>
                        <Option value="7">SDG7</Option>
                        <Option value="8">SDG8</Option>
                        <Option value="9">SDG9</Option>
                        <Option value="10">SDG10</Option>
                        <Option value="11">SDG11</Option>
                        <Option value="12">SDG12</Option>
                        <Option value="13">SDG13</Option>
                        <Option value="14">SDG14</Option>
                        <Option value="15">SDG15</Option>
                        <Option value="16">SDG16</Option>
                        <Option value="17">SDG17</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export const Categories = () => {
    const [categories, setCategories] = useState([]);
    const [originalCategories, setOriginalCategories] = useState([]);
    const [showModalVisible, setShowModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [goalSelectionVisible, setGoalSelectionVisible] = useState(false);
    const [catCodeToDownload, setCatCodeToDownload] = useState(null);
    const user = useSelector((state) => state.user);
    const [searchValue, setSearchValue] = useState('');
    const { Search } = Input;
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleEditCategory = (record) => {
        setSelectedCategory(record);
        setEditModalVisible(true);
    };

    const handleAddNew = () => {
        setAddModalVisible(true);
    };

    const handleDelete = (catCode) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this category?',
            onOk: () => {
                AxiosOICStat.delete(`categories/${catCode}`)
                    .then(() => {
                        message.success('Category deleted successfully');
                        setCategories(prevCategories => prevCategories.filter(cat => cat.cat_code !== catCode));
                    })
                    .catch(err => {
                        message.error('Error deleting category');
                    });
            }
        });
    };

    useEffect(() => {
        AxiosOICStat.get(`categories`)
            .then(response => {
                console.log("categories", response.data);
                setCategories(response.data);
                setOriginalCategories(response.data)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleShow = (record) => {
        setSelectedRecord(record);
        setShowModalVisible(true);
        AxiosOICStat.get(`indicators/category/${record.cat_code}`)
            .then(response => {
                setSelectedIndicators(response.data);
            })
            .catch(err => {
                console.error('Error fetching indicators for category', record.cat_code, ':', err);
            });
    };

    const handleShowModalCancel = () => {
        setShowModalVisible(false);
        setSelectedIndicators([]);
    };

    const handleDownloadCategory = (catCode, goal = null) => {
        const endpoint = goal ? `data/get-category-csv/${catCode}?goal=${goal}` : `data/get-category-csv/${catCode}`;
        AxiosOICStat.get(endpoint, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;

                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'sesric_data.csv';
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
                    if (fileNameMatch.length === 2)
                        fileName = fileNameMatch[1];
                }

                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.error('Error downloading category CSV:', err);
                message.error('Error downloading category CSV');
            });
    };

    const handleDownloadButtonClick = (catCode) => {
        if (catCode === 27) {
            setCatCodeToDownload(catCode);
            setGoalSelectionVisible(true);
        } else {
            handleDownloadCategory(catCode);
        }
    };

    const handleGoalSelectionOk = (goal) => {
        setGoalSelectionVisible(false);
        handleDownloadCategory(catCodeToDownload, goal);
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        const lowerCaseValue = value.toLowerCase();
        const filtered = originalCategories.filter(category => {
            const categoryCode = category.cat_code || '';
            const categoryName = category.cat_name_eng || '';
            return categoryName.toLowerCase().includes(lowerCaseValue) || categoryCode.toString().includes(lowerCaseValue);
        });
        setCategories(filtered);
    };

    const columns = [
        {
            title: 'Code',
            dataIndex: 'cat_code',
            key: 'cat_code',
            sorter: (a, b) => a.cat_code - b.cat_code,
        },
        {
            title: 'Name',
            dataIndex: 'cat_name_eng',
            key: 'cat_name_eng',
            sorter: (a, b) => a.cat_name_eng.localeCompare(b.cat_name_eng),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button icon={<EyeOutlined />} onClick={() => handleShow(record)}>View Indicators</Button>
                    {user.role !== 2 && <Button style={{ marginLeft: 8 }} type="danger" icon={<DeleteOutlined />} onClick={() => handleDelete(record.cat_code)}></Button>}
                    <Button style={{ marginLeft: 8 }} onClick={() => handleDownloadButtonClick(record.cat_code)}>Download Category</Button>
                    <Button style={{ marginLeft: 8 }} onClick={() => handleEditCategory(record)}>Edit</Button>
                </>
            ),
        },
        {
            title: 'Is Private?',
            dataIndex: 'is_private',
            key: 'is_private',
            render: (text) => text ? 'Yes' : 'No',
        }
    ];

    const paginationConfig = {
        pageSizeOptions: ['10', '20', '50', '100'],
        defaultPageSize: 20,
        showSizeChanger: true,
    };

    return (
        <Row>
            <Col xs={24}>
                {user.role !== 2 && <Button type="primary" onClick={handleAddNew}>Add New Category</Button>}
                <br/>
                <br/>
                <Search
                    placeholder="Search"
                    allowClear
                    value={searchValue}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ marginBottom: 10 }}
                />
                <Table
                    rowKey="cat_code"
                    dataSource={categories}
                    columns={columns}
                    loading={loading}
                    pagination={{pageSize:"10"}}
                />
                
            </Col>

            <IndicatorTableShowModal
                visible={showModalVisible}
                onCancel={handleShowModalCancel}
                record={selectedRecord}
                indicators={selectedIndicators}
            />

            <CategoryAddModal
                visible={addModalVisible}
                onCancel={() => setAddModalVisible(false)}
                onAdd={(newCategory) => setCategories([...categories, newCategory])}
            />

            <GoalSelectionModal
                visible={goalSelectionVisible}
                onCancel={() => setGoalSelectionVisible(false)}
                onOk={handleGoalSelectionOk}
            />

            <EditCategoryModal
                visible={editModalVisible}
                onCancel={() => setEditModalVisible(false)}
                category={selectedCategory}
                onEdit={() => {
                    AxiosOICStat.get(`categories`)
                        .then(response => {
                            setCategories(response.data);
                            setOriginalCategories(response.data);
                            message.success('Categories updated successfully');
                        })
                        .catch(err => {
                            console.error('Error fetching updated categories:', err);
                            message.error('Error fetching updated categories');
                        });
                    setEditModalVisible(false);
                }}
            />


        </Row>
    );
};

export default Categories;
