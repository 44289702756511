import React from "react";
import { Menu, Typography } from "antd";
import { Link, useLocation } from 'react-router-dom';
import './style.scss';
const { Title } = Typography;

const Topbar = () => {
  const currentLocation = useLocation();

  const getCurrentPageName = () => {
    const path = currentLocation.pathname;
    if (path === "/") {
      return "Home";
    } else if (path === "/logs") {
      return "Mission Logs";
    } else if (path === "/info_cards") {
      return "Information Cards"
    }

    let pageName = path.substring(1);

    if (pageName.length > 1) {
      return pageName.charAt(0).toUpperCase() + pageName.slice(1);
    }

    return pageName.toUpperCase();
  }


  const items = [
    { key: 'home_logo', label: <Link to="/"><img src={require("../../assets/images/sesric_logo.png")} alt="SESRIC Logo" /><span className="site-name">OICStat</span></Link>, className: "home-logo" },
    { key: 'page_name', label: <Title level={4}>{getCurrentPageName()}</Title>, className: "page-name" },
  ];

  return (
    <>
      <Menu theme="dark" selectable={false} items={items} />
    </>
  );
};

export default Topbar;
