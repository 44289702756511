import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AxiosOICStat from '../AxiosOICStat';
import { Space, Tabs, Tag, Icon } from 'antd';
import { CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import sdgIcon from '../assets/images/sdg-icon.png';
import imfIcon from '../assets/images/imf-data-logo.png';
import wdgIcon from '../assets/images/wdi-logo.png';
import iloIcon from '../assets/images/ilo-logo.png';

const { TabPane } = Tabs;

export const ExternalMappings = () => {
    const [selectedTags, setSelectedTags] = useState([]);

    const tags = ["SDG", "IMF", "WDG", "ILO"];
    const tagsWithIcons = [
        { label: 'SDG', icon: sdgIcon },
        { label: 'IMF', icon: imfIcon },
        { label: 'WDG', icon: wdgIcon },
        { label: 'ILO', icon: iloIcon },
    ];

    const toggleTag = (tag) => {
        const currentIndex = selectedTags.indexOf(tag);
        const newSelectedTags = [...selectedTags];

        if (currentIndex === -1) {
            newSelectedTags.push(tag);
        } else {
            newSelectedTags.splice(currentIndex, 1);
        }

        setSelectedTags(newSelectedTags);
    };

    return (
        <>
            <div style={{ marginBottom: '16px', fontSize: '16px', fontWeight: 'bold' }}>Filters:</div>
            <Space size={[0, 8]} wrap>
                {tagsWithIcons.map(tag => (
                    <Tag
                        key={tag.label}
                        color={selectedTags.includes(tag.label) ? undefined : 'default'}
                        onClick={() => toggleTag(tag.label)}
                        bordered={false}
                        style={{
                            cursor: 'pointer',
                            marginBottom: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: selectedTags.includes(tag.label) ? '#519BD6' : undefined,
                            padding: '4px 8px',
                            fontSize: '12px',
                            fontFamily: 'Readex Pro, sans-serif',
                            borderRadius: '2em',
                            userSelect: 'none',
                        }}
                    >
                        <img
                            src={tag.icon}
                            alt={tag.label}
                            style={{ width: '32px', height: '32px', marginRight: '8px', userSelect: 'none', pointerEvents: 'none', objectFit: 'cover' }}
                            onDragStart={(e) => e.preventDefault()}
                        />
                        <span style={{ color: selectedTags.includes(tag.label) ? 'white' : undefined }}>{tag.label}</span>
                    </Tag>
                ))}
            </Space>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Unmapped Indicators" key="1">
                    
                </TabPane>
                <TabPane tab="List of Mapped Indicators" key="2">
                </TabPane>
                { /*
             
                <TabPane tab="Dump / Existing Matching" key="3">
                    <WorldMappings />
                </TabPane>
             */}
            </Tabs>
        </>
    );
};