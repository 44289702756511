import React, { useEffect, useState } from 'react';
import AxiosOICStat from '../AxiosOICStat';
import { Row, Col, Table } from 'antd';

export const Users = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
        { title: 'ID', dataIndex: 'id' },
        { title: 'username', dataIndex: 'username' },
        { title: 'First Name', dataIndex: 'first_name' },
        { title: 'Last Name', dataIndex: 'last_name' },
        { title: 'Role', dataIndex: 'role' },
    ];

    useEffect(() => {
        AxiosOICStat.get(`users`)
            .then((response) => {
                setUsers(response.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <Row>
            <Col xs={24}>
                <Table
                    rowKey="id"
                    dataSource={users}
                    columns={columns}
                    size="small"
                    pagination={{ pageSize: 10 }}
                    loading={loading}
                />
            </Col>
        </Row>
    );
};

export default Users;