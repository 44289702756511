import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, Table, Button, Select, Typography, message, Modal, Checkbox, Input } from 'antd';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import AxiosOICStat from '../AxiosOICStat';
import { useSelector, useDispatch } from 'react-redux';
import { Switch } from 'antd';
import { FilterDropdown } from '../components/FilterDropdown';
import { IndicatorEditModal, setFetch, getFetch } from '../components/modals/IndicatorEdit';
import { Link } from "react-router-dom";
import { ExpandableTextView } from '../components/ExpandableTextView';
import { Divider } from 'antd';
import * as XLSX from 'xlsx';

const { Text } = Typography;
const { Search } = Input;

export const Indicators = () => {
    const [indicators, setIndicators] = React.useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [showModalVisible, setShowModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [loading, setLoading] = React.useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isAddMode, setIsAddMode] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const user = useSelector((state) => state.user);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [searchValue, setSearchValue] = React.useState('');
    const [initialFetch, setInitialFetch] = useState(true);
    const indicatorsState = useSelector(state => Object.values(state.indicators.value));

    const unitsState = useSelector((state) => state.units);
    //console.log('unitsState:', unitsState);

    const categoriesState = useSelector((state) => state.categories);
    //console.log('categoriesState:', categoriesState);

    const sourcesState = useSelector((state) => state.sources);
    //console.log('sourcesState:', sourcesState);


    // Define filter options for Units, Categories, and Sources
    const unitFilterOptions = useMemo(() => createFilterOptions(unitsState.value, 'unit_name_eng'), [unitsState.value]);
    const categoryFilterOptions = useMemo(() => createFilterOptions(categoriesState.value, 'cat_name_eng'), [categoriesState.value]);
    const sourceFilterOptions = useMemo(() => createFilterOptions(sourcesState.value, 'source_name_eng'), [sourcesState.value]);
    const aggregationMethodOptions = useMemo(generateAggregationMethodOptions, [indicators]);
    const indicatorFilterOptions = useMemo(() => createFilterOptions(indicators, 'ind_name_eng'), [indicators]);
    const indCodeFilterOptions = useMemo(() => createFilterOptions(indicators, 'ind_code'), [indicators]);

    const [selectedPrimaryCategory, setSelectedPrimaryCategory] = useState(null);
    const [selectedIsPrivate, setSelectedIsPrivate] = useState(null);
    const [selectedAggrMethod, setSelectedAggrMethod] = useState(null);


    // Function to create filter options from state values
    function createFilterOptions(stateValues, nameKey) {
        const uniqueNames = new Set(Object.values(stateValues).map(item => item[nameKey]));
        return Array.from(uniqueNames).map(name => ({ text: name, value: name }));
    }

    function generateAggregationMethodOptions() {
        // Assuming 'indicators' is your data array
        const uniqueMethods = new Set(indicators.map(item => item.aggr_method));
        return Array.from(uniqueMethods).map(method => ({
            text: method !== null ? method : 'None',
            value: method,
        }));
    };

    // Function to handle select all or deselect all
    const onSelectAllChange = (e) => {
        if (e.target.checked) {
            // Select all rows currently displayed in the table
            const displayedRowKeys = filteredData.map(indicator => indicator.ind_code);
            setSelectedRowKeys(displayedRowKeys);
        } else {
            // Deselect all rows
            setSelectedRowKeys([]);
        }
    };

    const downloadXLSX = async () => {
        try {
            setDownloadLoading(true);
            const response = await AxiosOICStat.get('/indicators/indicators-csv/download', {
                responseType: 'blob', // This tells Axios to handle the response as a Blob
            });

            // Use FileReader to read the blob's data
            const reader = new FileReader();
            reader.onload = (evt) => {
                const data = evt.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });

                // Assuming there is at least one worksheet in the workbook
                const wsname = workbook.SheetNames[0];
                const ws = workbook.Sheets[wsname];

                // Convert the worksheet to JSON, specifying raw format for dates
                const jsonData = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: 'yyyy-mm-dd' });

                const newWorkbook = XLSX.utils.book_new();
                const newWs = XLSX.utils.json_to_sheet(jsonData, {
                    cellDates: true,
                    dateNF: 'yyyy-mm-dd'  // Ensure date format is consistent
                });

                XLSX.utils.book_append_sheet(newWorkbook, newWs, "Indicators");
                XLSX.writeFile(newWorkbook, 'Indicator_Data.xlsx');
            };
            reader.readAsBinaryString(response.data);
        } catch (error) {
            message.error('Failed to download XLSX');
            console.error('Download XLSX error:', error);
        } finally {
            setDownloadLoading(false);
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            {
                key: 'selectAllOrNone',
                text: 'Select All/None',
                onSelect: (changeableRowKeys) => {
                    if (selectedRowKeys.length === changeableRowKeys.length) {
                        // If all are selected, deselect all
                        setSelectedRowKeys([]);
                    } else {
                        // Otherwise, select all
                        setSelectedRowKeys(changeableRowKeys);
                    }
                },
            },
        ],
        renderCell: (checked, record, index, originNode) => {
            if (index === -1) { // This is the header row
                return (
                    <Checkbox
                        indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < indicators.length}
                        onChange={onSelectAllChange}
                        checked={selectedRowKeys.length === indicators.length}
                    />
                );
            }
            return originNode; // For all other rows, return the original node
        },
    };

    const hasSelected = selectedRowKeys.length > 0;

    const handleAddNew = () => {
        setSelectedRecord(null);
        setEditModalVisible(true);
        setIsAddMode(true);
    };

    // Function to reset bulk edit UI values
    const resetBulkEditUI = () => {
        setSelectedPrimaryCategory(null);
        setSelectedIsPrivate(null);
        setSelectedAggrMethod(null);
    };

    const handleDelete = (indCode) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this indicator?',
            onOk: () => {
                AxiosOICStat.delete(`indicators/${indCode}`)
                    .then(() => {
                        message.success('Indicator deleted successfully');
                        setIndicators(prevIndicators => prevIndicators.filter(ind => ind.ind_code !== indCode));
                    })
                    .catch(err => {
                        message.error('Error deleting indicator');
                    });
            }
        });
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        const filtered = indicators.filter(indicator => {
            const indicatorName = indicator.ind_name_eng || '';
            const indicatorCode = indicator.ind_code || '';
            const lowerCaseValue = value.toLowerCase();
            return indicatorName.toLowerCase().includes(lowerCaseValue) || indicatorCode.toString().includes(lowerCaseValue);
        });
        setFilteredData(filtered);
    }

    const handleBulkEdit = async () => {
        let updateData = {};
        if (selectedPrimaryCategory !== null) updateData.cat_code = selectedPrimaryCategory;
        updateData.is_private = selectedIsPrivate ? 1 : 0; // Assuming your backend expects 1 or 0
        if (selectedAggrMethod !== null) updateData.aggr_method = selectedAggrMethod;
        if (selectedAggrMethod === 'None') updateData.aggr_method = null;


        // Create an array of requests, one for each selected indicator
        const updateRequests = selectedRowKeys.map(indCode =>
            AxiosOICStat.put(`indicators/${indCode}`, updateData)
        );

        try {
            // Execute all requests
            await Promise.all(updateRequests);
            setSelectedRowKeys([]);

            // Refresh the data
            // Fetch the updated list of indicators
            const response = await AxiosOICStat.get('indicators/descriptions');
            setIndicators(response.data);
            console.log("Updated Indicators", indicators);
            resetBulkEditUI();
            message.success('Indicators updated successfully');
            
        } catch (error) {
            message.error('Error updating indicators');
            resetBulkEditUI();
        }
    };


    const columns = [
        {
            title: 'Code', dataIndex: 'ind_code',
            filters: indCodeFilterOptions,
            onFilter: (value, record) => record.ind_code === value,
            sorter: (a, b) => a.ind_code - b.ind_code,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <FilterDropdown
                    options={indCodeFilterOptions}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    numberFilter={true}
                />
            ),
            render: (indCode) => <Link to={`/indicator/${indCode}`}>{indCode}</Link>,
        },
        {
            title: 'Name',
            dataIndex: 'ind_name_eng',
            filters: indicatorFilterOptions,
            onFilter: (value, record) => record.ind_name_eng === value,
            sorter: (a, b) => a.ind_name_eng.localeCompare(b.ind_name_eng),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <FilterDropdown
                    options={indicatorFilterOptions}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                />
            ),
            render: (indNameEng, record) => <Link to={`/indicator/${record.ind_code}`}>{indNameEng}</Link>,
        },
        {
            title: 'Explanation', dataIndex: 'ind_eng_exp',
            render: (text) => <ExpandableTextView text={text} />,
        },
        {
            title: 'Unit',
            dataIndex: 'unit_code',
            filters: unitFilterOptions,
            onFilter: (value, record) => unitsState.value[record.unit_code]?.unit_name_eng === value,
            sorter: (a, b) => {
                const unitA = unitsState.value[a.unit_code]?.unit_name_eng || a.unit_code;
                const unitB = unitsState.value[b.unit_code]?.unit_name_eng || b.unit_code;
                return unitA.localeCompare(unitB);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <FilterDropdown
                    options={unitFilterOptions}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                />
            ),
            render: (unitCode) => unitsState.value[unitCode]?.unit_name_eng || unitCode,
        },
        {
            title: 'Primary Category', dataIndex: 'cat_code',
            filters: categoryFilterOptions,
            onFilter: (value, record) => categoriesState.value[record.cat_code]?.cat_name_eng === value,
            sorter: (a, b) => {
                const categoryA = categoriesState.value[a.cat_code]?.cat_name_eng || a.cat_code;
                const categoryB = categoriesState.value[b.cat_code]?.cat_name_eng || b.cat_code;
                return categoryA.localeCompare(categoryB);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <FilterDropdown
                    options={categoryFilterOptions}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                />
            ),
            render: (catCode) => categoriesState.value[catCode]?.cat_name_eng || catCode,
        },
        {
            title: 'Is private?',
            dataIndex: 'is_private',
            sorter: (a, b) => a.is_private - b.is_private, // Assuming 'is_private' is a numeric value
            render: (isPrivate) => (isPrivate === 1 ? 'private' : 'non-private'),
        },
        {
            title: 'Source',
            dataIndex: 'source_code',
            filters: sourceFilterOptions,
            onFilter: (value, record) => sourcesState.value[record.source_code]?.source_name_eng === value,
            sorter: (a, b) => {
                const sourceA = sourcesState.value[a.source_code]?.source_name_eng || a.source_code;
                const sourceB = sourcesState.value[b.source_code]?.source_name_eng || b.source_code;
                return sourceA.localeCompare(sourceB);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <FilterDropdown
                    options={sourceFilterOptions}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                />
            ),
            render: (sourceCode) => sourcesState.value[sourceCode]?.source_name_eng || sourceCode,
        },
        {
            title: 'Aggregation Method', dataIndex: 'aggr_method',
            filters: aggregationMethodOptions,
            onFilter: (value, record) => record.aggr_method === value,
            sorter: (a, b) => {
                const methodA = a.aggr_method || 'None';
                const methodB = b.aggr_method || 'None';
                return methodA.localeCompare(methodB);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <FilterDropdown
                    options={aggregationMethodOptions}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                />
            ),
            render: (aggrMethod) => (aggrMethod !== null ? aggrMethod : '-'),
        },
        {
            title: 'Aggregation Formula', dataIndex: 'aggr_formula',
            filters: aggregationMethodOptions,
            onFilter: (value, record) => record.aggr_formula === value,
            sorter: (a, b) => {
                const methodA = a.aggr_formula || 'None';
                const methodB = b.aggr_formula || 'None';
                return methodA.localeCompare(methodB);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <FilterDropdown
                    options={aggregationMethodOptions}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                />
            ),
            render: (aggrFormula) => (aggrFormula !== null ? aggrFormula : '-'),
        },
        {
            title: 'Competence Rate',
            dataIndex: 'comp_rate',
            render: (comp_rate) => {
                const formattedRate = (comp_rate * 100).toFixed(2);
        
                let color;
                if (comp_rate < 0.30) {
                    color = 'red';
                } else if (comp_rate >= 0.30 && comp_rate <= 0.70) {
                    color = 'orange';
                } else {
                    color = 'green';
                }
        
                return <span style={{ color: color }}>{formattedRate}%</span>;
            }
        },
        {
            title: 'Last Update Date',
            dataIndex: 'operation_datetime',
            sorter: (a, b) => new Date(a.operation_datetime) - new Date(b.operation_datetime),
            render: (date) => new Date(date).toLocaleDateString(),
        },
        {
            title: 'Actions',
            dataIndex: '',
            key: 'actions',
            render: (text, record) => (
                <>
                    <span style={{ display: "flex" }}>
                        <Button icon={<EyeOutlined />} onClick={() => handleShow(record)}></Button>
                        {user.role !== 2 &&
                            <>
                                <Button style={{ marginLeft: 8 }} type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record)}></Button>
                                <Button style={{ marginLeft: 8 }} type="danger" icon={<DeleteOutlined />} onClick={() => handleDelete(record.ind_code)}></Button>
                            </>
                        }
                    </span>
                </>
            ),
        },
    ];


    useEffect(() => {
        let isThereUpdate = false;
        if (editModalVisible) return;
        console.log("Test");
        console.log(getFetch());
        if(!getFetch()){
            
        }
        else{
            console.log("Test1");
            AxiosOICStat.get('indicators/details')
            .then((response) => {
                setIndicators(response.data);
                setFilteredData(response.data);

            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false); // Set loading to false when fetch is complete
                isThereUpdate = true;
            });
        }
        
        if(indicatorsState.length > 0 && !isThereUpdate && initialFetch){
            console.log("Test2");
            setIndicators(indicatorsState);
            setFilteredData(indicatorsState);
            console.log("Indicators",indicatorsState);
            setLoading(false);
            setFetch(false);
            setInitialFetch(false);
        }
    }, [indicatorsState]);

    useEffect(() => {
        //console.log('Countries state changed:', unitsState);

    }, [unitsState]);

    useEffect(() => {
        //console.log('Categories state changed:', categoriesState);
        // 
    }, [categoriesState]);

    useEffect(() => {
        //console.log('Sources state changed:', sourcesState);
        //
    }, [sourcesState]);

    const handleEdit = (record) => {
        setIsAddMode(false);
        setSelectedRecord(record);
        setEditModalVisible(true);
    };

    const handleShow = (record) => {
        setSelectedRecord(record);
        setShowModalVisible(true);
    };

    const handleEditModalCancel = () => {
        setEditModalVisible(false);
    };

    const handleShowModalCancel = () => {
        setShowModalVisible(false);
    };

    const bulkEditUI = hasSelected ? (
        <Row style={{ marginBottom: 16 }}>
            <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                <Text>Primary Category:</Text>
                <Select
                    placeholder="Select Primary Category"
                    onChange={value => setSelectedPrimaryCategory(value)}
                    value={selectedPrimaryCategory}
                    style={{ width: '75%', marginLeft: 8 }}
                >
                    {Object.values(categoriesState.value).map(category => (
                        <Select.Option key={category.cat_code} value={category.cat_code}>
                            {category.cat_name_eng}
                        </Select.Option>
                    ))}
                </Select>
            </Col>
            <Divider type="vertical" style={{ height: 'auto' }} />
            <Col span={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ marginRight: 8 }}>Is Private:</Text>
                <Switch
                    checked={selectedIsPrivate}
                    onChange={checked => setSelectedIsPrivate(checked)}
                />
            </Col>
            <Divider type="vertical" style={{ height: 'auto' }} />
            <Col span={10} style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ marginRight: 8 }}>Aggregation Method:</Text>
                <Select
                    placeholder="Select Aggregation Method"
                    style={{ width: '75%', marginLeft: 8 }}
                    onChange={value => setSelectedAggrMethod(value)}
                    value={selectedAggrMethod}
                >
                    <Select.Option value={"None"}>None</Select.Option>
                    <Select.Option value="Sum">Sum</Select.Option>
                    <Select.Option value="Weighted">Weighted</Select.Option>
                    <Select.Option value="Median">Median</Select.Option>
                    <Select.Option value="Average">Average</Select.Option>
                </Select>
            </Col>
            <Col span={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Button type="primary" onClick={handleBulkEdit}>
                    Apply Changes
                </Button>
            </Col>
        </Row>
    ) : null;

    return (
        <Row>
            {indicators &&
                <Col style={{ marginBottom: "10px" }}>
                    {user.role !== 2 && <Button type="primary" onClick={handleAddNew}>Add New Indicator</Button>}
                    <Button onClick={downloadXLSX} type="primary" style={{ marginLeft: 8 }} disabled={downloadLoading}> 
                        {downloadLoading ? 'Exporting...' : 'Export to Excel'}
                    </Button>
                </Col>
                
            }
            <Search
                                placeholder="Search"
                                allowClear
                                value={searchValue}
                                onChange={(e) => handleSearch(e.target.value)}
                                style={{ marginBottom: 10 }}
                            />
                <Col xs={24}>
                    {user.role !== 2 && bulkEditUI}
                    <div style={{ overflowX: 'auto', width: '100%' }}>
                        <Table
                            rowKey="ind_code"
                            rowSelection={user.role === 2 && rowSelection}
                            dataSource={filteredData}
                            columns={columns}
                            size="small"
                            onChange={(pagination, filters, sorter, extra) => {
                                setFilteredData(extra.currentDataSource);
                            }}
                            pagination={{pageSize: "10"}}
                            loading={{ spinning: loading, tip: 'Loading' }}
                            scroll={{ x: 'fit-content'}} // Restrict table height and enable vertical scrolling
                        />
                    </div>
                </Col>


            <IndicatorEditModal
                visible={showModalVisible}
                onCancel={handleShowModalCancel}
                record={selectedRecord}
                editable={false}
            />

            <IndicatorEditModal
                visible={editModalVisible}
                onCancel={handleEditModalCancel}
                onEdit={handleEdit}
                record={selectedRecord}
                editable={true}
                isAddMode={isAddMode}
            />
        </Row>
    );
};