import React, { useState, useEffect } from 'react';
import AxiosOICStat from '../AxiosOICStat';
import { Select, Button, Row, Col, Tooltip, Checkbox, Card, Collapse, Switch } from 'antd';
import DataTable from '../components/DataTable';
import { useSelector } from 'react-redux';
import DatePicker from 'react-multi-date-picker';
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import PresetValuesPlugin from '../components/datePickerPlugins/PresetValuesPlugin';
import Icon from "react-multi-date-picker/components/icon";
import { CarryForwardCheck } from 'components/CarryForwardCheck';
import * as XLSX from 'xlsx';

const { Option } = Select;

export function DataQuery({ auto_search = false }) {
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const countriesState = useSelector((state) => state.countries);
    const indicatorsState = useSelector((state) => state.indicators);
    const unitsState = useSelector((state) => state.units);
    const [regionMappingsData, setRegionMappingsData] = useState([]);
    const [countryMap, setCountryMap] = useState({});
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [carryForwardCheck, setCarryForwardCheck] = useState(false);
    const [carryForwardBaseYear, setCarryForwardBaseYear] = useState('')
    const categoriesState = useSelector((state) => state.categories);

    const renderTagPlaceholder = (omittedValues) => {
        // Sort the omitted values alphabetically based on their labels
        const sortedOmittedValues = [...omittedValues].sort((a, b) => parseInt(a.value) - parseInt(b.value));

        // Create a multiline string of all sorted omitted values
        const fullList = sortedOmittedValues.map(val => val.label).join('\n');
        return (
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{fullList}</span>}>
                <span>{`+${omittedValues.length} more`}</span>
            </Tooltip>
        );
    };

    useEffect(() => {
        // Assume these are the endpoints to fetch platform and mission data
        if (!auto_search) {

        }
        else {
            handleSearch();
        }
    }, [auto_search]);

    const handleSearch = () => {
        setLoading(true);
        const formattedYears = [];

        selectedYears.forEach(range => {
            if (range.length === 2) {
                for (let i = range[0].year; i <= range[1].year; i++) {
                    formattedYears.push(i);
                }
            } else {
                formattedYears.push(range[0].year);
            }
        });

        const formattedCountries = selectedCountries.map(country => country.split(' - ')[0]);

        const formattedIndicators = selectedIndicators.map(indicator => indicator.split(' - ')[0]);

        AxiosOICStat.post('/data/query', {
            countries: formattedCountries,
            indicators: formattedIndicators,
            years: formattedYears
        }).then(response => {
            const updatedData = response.data.map((element, index) => {
                if(element.d_value == null){
                    //pass
                }
                else{
                    element.d_value = Number(element.d_value);
                }
                element.key = index;
                element.u_name = unitsState.value[element.u_code]?.unit_name_eng;
                return element;
            });
            if(carryForwardCheck){
                executeCarryForward(updatedData);
            }
            console.log("check",updatedData)
            setSearchResults(updatedData);
            setLoading(false);
        });

    };

    const executeCarryForward = (updatedData) => {
        const mappedData = updatedData.reduce((acc, item) => {
            const key = `${item.c_code}_${item.ind_code}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
    
            return acc;
        }, {});
    
        for (const key in mappedData) {
            if (mappedData.hasOwnProperty(key)) {
                let carriedValue = 0;
                let carriedYear = 0;
                let carryFlag = false;
    
                mappedData[key].forEach(item => {
                    if (Number(item.d_year) >= Number(carryForwardBaseYear) && item.d_value != null) {
                        carriedValue = item.d_value;
                        carriedYear = item.d_year;
                        carryFlag = true;
                    } else if (Number(item.d_year) >= Number(carryForwardBaseYear) && item.d_value == null) {
                        if (carriedYear != 0 && carriedValue != 0) {
                            item.d_value = carriedValue;
                            item.d_date = 'Carried From ' + carriedYear;
                        }
                        else{
                            item.d_date = 'Data Not Available';
                            item.d_value = NaN;
                        }
                    }
                    if (item.d_value == null) {
                        item.d_value = NaN;
                        item.d_date = 'Data Not Available';
                    }
                });
    
                if (carryFlag == false) {
                    mappedData[key].forEach(item => {
                        item.d_value = NaN;
                        item.d_date = 'Data Not Available';
                    });
                }
            }
        }
    }; 

    const handleCarryForward = (checked) => {
        console.log(checked)
        setCarryForwardCheck(checked);
    };

    const handleYearChange = (year) => {
        setCarryForwardBaseYear(year);
    }

    const exportCarryForwardExcel = () => {
        const fileName = "Carry-Forward Results";
    
        const customHeaders = [
            'Country Name', 'Indicator', 'Unit', 'Year', 'Date', 'Value'
        ];
    
        // Assuming searchResults is your original data
        const modifiedData = searchResults.map(entry => ({
            'Country Name': entry.c_name,
            'Indicator': entry.ind_name,
            'Unit': entry.u_name,
            'Year': entry.d_year,
            'Date': entry.d_date,
            'Value': Number(entry.d_value)
        }));
    
        const worksheet = XLSX.utils.json_to_sheet(modifiedData);
    
        XLSX.utils.sheet_add_aoa(worksheet, [customHeaders], { origin: "A1" });
    
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };

    const exportToExcel = () => {
        const formattedYears = [];

        if(carryForwardCheck){
            exportCarryForwardExcel()
            return;
        }

        selectedYears.forEach(range => {
            if (range.length === 2) {
                for (let i = range[0].year; i <= range[1].year; i++) {
                    formattedYears.push(i);
                }
            } else {
                formattedYears.push(range[0].year);
            }
        });

        const formattedCountries = selectedCountries.map(country => country.split(' - ')[0]);

        const formattedIndicators = selectedIndicators.map(indicator => indicator.split(' - ')[0]);

        AxiosOICStat.post('/data/query-csv', {
            countries: formattedCountries,
            indicators: formattedIndicators,
            years: formattedYears,
            formatOptions: {}
        }).then(response => {
            //console.log("response", response.data);

            // Dynamically construct headers based on the fixed fields and the years array
            const headers = [
                "Country", "Category", "Indicator", "Unit",
                ...formattedYears.map(year => year.toString()) // Convert each year to a string
            ];

            // Map response data to the format suitable for Excel export
            const dataForExport = response.data.map(item => {
                // Start with fixed fields
                const row = {
                    "Country": item.c_name,
                    "Category": `"${item.category}"`,
                    "Indicator": `"${item.ind_name}"`,
                    "Unit": `"${item.unit}"`
                };

                // Add dynamic year data
                item.years.forEach((value, index) => {
                    // Assuming formattedYears and item.years are aligned by index
                    const year = formattedYears[index];
                    row[year] = value !== null ? `"${value}"` : "\" \""; // Replace null with space for Excel
                });

                return row;
            });

            // Convert dataForExport to CSV string and include UTF-8 BOM
            const BOM = '\uFEFF';
            const csvContent = BOM + [
                headers.join(','), // Add the headers row
                ...dataForExport.map(row => headers.map(header => row[header]).join(',')) // Map each row to CSV format
            ].join('\n');

            // Trigger download of the CSV file
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'oicstat_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    useEffect(() => {
        // sort the selected values
        setSelectedCountries(selectedCountries.sort((a, b) => parseInt(a) - parseInt(b)));
        setSelectedIndicators(selectedIndicators.sort((a, b) => parseInt(a) - parseInt(b)));
        setSelectedYears(selectedYears.sort((a, b) => parseInt(a) - parseInt(b)));
    },
        [selectedCountries, selectedIndicators, selectedYears]
    );

    useEffect(() => {
        // Fetch the region mappings when the component is mounted
        const fetchRegionMappings = async () => {
            try {
                const response = await AxiosOICStat.get('/region-mappings/'); // Adjust the endpoint as needed
                setRegionMappingsData(response.data); // Assuming the response body will be the array of region mappings
            } catch (error) {
                console.error('Failed to fetch region mappings:', error);
                // Handle errors, e.g., show notification
            }
        };

        fetchRegionMappings();
    }, []);

    const fetchRegionCountries = async (regionId) => {
        try {
            const response = await AxiosOICStat.get(`/regions-countries/${regionId}`); // Adjust the endpoint as needed
            return response.data; // Assuming the response body will be the array of country codes
        } catch (error) {
            console.error('Failed to fetch region countries:', error);
            // Handle errors, e.g., show notification
        }
    };

    const regions = [
        {
            id: 1,
            label: 'Regions',
            children: <Row gutter={[8, 8]}>
                {regionMappingsData && regionMappingsData.map((region) => (
                    <Col span={6} key={region.id}>
                        <Checkbox
                            key={region.id}
                            checked={selectedRegions.includes(region.id)}
                            onChange={(e) => {
                                fetchRegionCountries(region.id).then(countries => {
                                    let updatedCountries = countries.map(fetchedCountry => {
                                        // find the country object in `countriesState` by its ID
                                        let country = Object.values(countriesState.value)
                                            .filter(stateCountry => stateCountry.c_code === fetchedCountry.country_id)[0]

                                        if (country && country.c_short_name_eng) {
                                            country = country.c_short_name_eng;
                                        } else return '';
                                        return `${fetchedCountry.country_id} - ${country}`;
                                    });

                                    updatedCountries = updatedCountries.filter(country => country !== '');

                                    const differenceCountries = []

                                    if (e.target.checked) {
                                        setCountryMap(prevMap => {
                                            const newMap = { ...prevMap };
                                            updatedCountries.forEach(country => {
                                                if (newMap[country] === undefined || newMap[country] === 0) {
                                                    newMap[country] = 1;
                                                    differenceCountries.push(country);
                                                } else {
                                                    newMap[country] += 1;
                                                }
                                            });
                                            return newMap;
                                        });
                                    } else {
                                        setCountryMap(prevMap => {
                                            const newMap = { ...prevMap };
                                            updatedCountries.forEach(country => {
                                                if (newMap[country] > 1) {
                                                    newMap[country] -= 1;
                                                } else {
                                                    newMap[country] = 0;
                                                    differenceCountries.push(country);
                                                }
                                            });
                                            return newMap;
                                        });
                                    }

                                    // handle checkbox change
                                    setSelectedRegions(e.target.checked
                                        ? [...selectedRegions, region.id]
                                        : selectedRegions.filter(selectedRegion => selectedRegion !== region.id)
                                    );

                                    setSelectedCountries(prevCountries => {
                                        if (e.target.checked) {
                                            return [...new Set([...prevCountries, ...differenceCountries])];
                                        } else {
                                            return prevCountries.filter(country => !differenceCountries.includes(country));
                                        }
                                    });

                                });
                            }}
                            value={region.id}
                        >
                            {region.region_name}
                        </Checkbox>
                    </Col>
                ))}
            </Row>
        }
    ]

    const handleCategoryChange = (categoryId, isChecked) => {
        const indicatorsInCategory = Object.values(indicatorsState.value || {}).filter(
            (indicator) => indicator.cat_code === categoryId
        ).map((indicator) => `${indicator.ind_code} - ${indicator.ind_name_eng}`); // Match Select items' format
    
        if (isChecked) {
            // Add category and its indicators
            setSelectedCategories((prev) => [...prev, categoryId]);
            setSelectedIndicators((prev) => [...new Set([...prev, ...indicatorsInCategory])]);
        } else {
            // Remove category and its indicators
            setSelectedCategories((prev) => prev.filter((id) => id !== categoryId));
            setSelectedIndicators((prev) =>
                prev.filter((indicator) => !indicatorsInCategory.includes(indicator))
            );
        }
    };
    
    const categoriesCollapse = (
        <Collapse
            defaultActiveKey={['1']} // Keeps the collapse expanded by default
            bordered={false}
            style={{ backgroundColor: 'white' }}
        >
            <Collapse.Panel header="Categories" key="1">
                <Row gutter={[8, 8]}>
                    {Object.values(categoriesState.value || {})
                        .sort((a, b) => a.cat_name_eng.localeCompare(b.cat_name_eng)) // Sort categories alphabetically
                        .map((category, index) => (
                            <Col span={8} key={category.cat_code}> {/* Adjust span to divide into 3 columns */}
                                <Checkbox
                                    checked={selectedCategories.includes(category.cat_code)}
                                    onChange={(e) => handleCategoryChange(category.cat_code, e.target.checked)}
                                >
                                    {category.cat_name_eng}
                                </Checkbox>
                            </Col>
                        ))}
                </Row>
            </Collapse.Panel>
        </Collapse>
    );
    
    

    //<div style={{alignItems:"center", justifyContent:"center", alignSelf:"center", marginRight:"5px"}}>
    //                        <Switch
    //                            checked={carryForwardCheck}
    //                            onChange={handleCarryForward}
    //                            checkedChildren={<span>Carry Forward On</span>}
    //                            unCheckedChildren={<span>Carry Forward Off</span>}
    //                        />
    //                    </div>
    //                    {carryForwardCheck && (
    //                        <DatePicker
    //                        picker="year"
    //                        style={{ marginLeft: '10px' }}
    //                        />
    //                    )}

    return (
        <>
            {!auto_search &&
                <Col style={{ marginBottom: '10px' }}>
                    <Collapse size="small" items={regions} bordered={false} style={{ marginBottom: "10px", width: "100%", backgroundColor: "white" }} />
                    {categoriesCollapse}
                    <br></br>
                    <Row style={{marginBottom: '10px'}}>
                        <CarryForwardCheck carryForwardCheck={carryForwardCheck} onYearChange={handleYearChange} onSwitchChange={handleCarryForward}></CarryForwardCheck>
                    </Row>
                    <Row>
                        <Select
                            mode="multiple"
                            placeholder="Select Countries"
                            onChange={setSelectedCountries}
                            maxTagCount={2}
                            maxTagPlaceholder={renderTagPlaceholder}
                            style={{ minWidth: '200px', marginBottom: '5px', marginRight: '10px' }}
                            showSearch
                            value={selectedCountries}
                        >
                            {Object.values(countriesState.value)
                                .filter(country => selectedCountries.includes(String(country.c_code)))
                                .concat(Object.values(countriesState.value).filter(country => !selectedCountries.includes(String(country.c_code))))
                                .map(country => <Option key={country.c_code} value={`${country.c_code} - ${country.c_short_name_eng}`}>{`${country.c_code} - ${country.c_short_name_eng}`}</Option>)}
                        </Select>
                        <Select
                            mode="multiple"
                            placeholder="Select Indicators"
                            onChange={setSelectedIndicators}
                            style={{ minWidth: '500px', marginBottom: '5px', marginRight: '10px' }}
                            maxTagCount={3}
                            maxTagPlaceholder={renderTagPlaceholder}
                            showSearch
                            value={selectedIndicators} // Reflects the current selected indicators
                        >
                            {Object.values(indicatorsState.value)
                                .filter(indicator => selectedIndicators.includes(`${indicator.ind_code} - ${indicator.ind_name_eng}`))
                                .concat(Object.values(indicatorsState.value).filter(indicator => !selectedIndicators.includes(`${indicator.ind_code} - ${indicator.ind_name_eng}`)))
                                .map(indicator => (
                                    <Option key={indicator.ind_code} value={`${indicator.ind_code} - ${indicator.ind_name_eng}`}>
                                        {`${indicator.ind_code} - ${indicator.ind_name_eng}`}
                                    </Option>
                                ))}
                        </Select>
                        <Button shape="circle" value="large" type="primary" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <DatePicker
                                onlyYearPicker
                                multiple
                                range
                                sort
                                highlightToday={false}
                                placeholderText={"Please select a date"}
                                selected={selectedYears}
                                value={selectedYears}
                                onChange={setSelectedYears}
                                maxDate={new Date()}
                                minDate={new Date(1970, 0, 1)}
                                plugins={[
                                    <DatePanel />,
                                    <PresetValuesPlugin position="left" setValue={setSelectedYears} />,
                                ]}
                                render={<Icon style={{ color: 'white', width: '85%', marginTop: '2px' }} />}
                            />
                        </Button>
                        
                        <Button type="primary" onClick={handleSearch} style={{ marginLeft: '10px', }}>Search</Button>
                    </Row>
                </Col>
            }
            <Row>
                <Col xs={24}>
                    <DataTable data={searchResults} columns={["c_name", "ind_name", "d_year", "d_date", "u_name", "d_value"]} widths={['10%', '60%', '10%', '10%']} loading={loading}  
                     decimalSymbol={"."}/>
                </Col>
            </Row>

            <Button onClick={exportToExcel} type="primary" style={{ marginLeft: '10px' }}>
                Export to CSV
            </Button>
        </>
    );
};