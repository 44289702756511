import { configureStore } from '@reduxjs/toolkit';
import authReducer, { login, logout } from './features/authSlice';
import userReducer, { fetchMe } from './features/userSlice';
import countriesReducer, { fetchCountries } from './features/countriesSlice';
import indicatorsReducer, { fetchIndicators } from './features/indicatorsSlice';
import unitsReducer, { fetchUnits } from './features/unitsSlice';
import categoriesReducer, { fetchCategories } from './features/categoriesSlice';
import sourcesReducer, { fetchSources } from './features/sourcesSlice';
import { useEffect } from 'react';


export const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        countries: countriesReducer,
        indicators: indicatorsReducer,
        units: unitsReducer,
        categories: categoriesReducer,
        sources: sourcesReducer,
    },
});

store.dispatch(login(localStorage.getItem('token')))
console.log("store", store.getState());
if (store.getState().auth.isAuthenticated) {
    store.dispatch(fetchMe()).unwrap().catch(() => {
        console.log('Invalid token');
        store.dispatch(logout());
    });
}


const retryAction = async (actionCreator, retries = 3) => {
    for (let i = 0; i < retries; i++) {
        try {
            if(store.getState().auth.isAuthenticated){
                await store.dispatch(actionCreator()).unwrap();
                break; // Break the loop if the action succeeds
            }
        } catch (error) {
            if (i === retries - 1) throw error; // Throw the error on the last attempt
        }
    }
};



retryAction(fetchCountries);
retryAction(fetchIndicators);
retryAction(fetchUnits);
retryAction(fetchCategories);
retryAction(fetchSources);

/* this one is more correct

store.dispatch(login(localStorage.getItem('token')));
if (store.getState().auth.isAuthenticated) {
    store.dispatch(fetchMe()).unwrap().catch(() => {
        console.log('Invalid token');
        store.dispatch(logout());
    }).then(() => {
        retryAction(fetchCountries);
        retryAction(fetchIndicators);
        retryAction(fetchUnits);
        retryAction(fetchCategories);
        retryAction(fetchSources);
    });
}

// old one 
store.dispatch(fetchCountries());
store.dispatch(fetchIndicators());
store.dispatch(fetchUnits());
store.dispatch(fetchCategories());
store.dispatch(fetchSources());
*/
// export default store;