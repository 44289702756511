import React, { useState, useEffect } from 'react';
import { Select, Button, message } from 'antd';
import AxiosSSM from '../../AxiosOICStat';

const { Option } = Select;

const LogLevelManager = () => {
    const [logNames, setLogNames] = useState(['SSM', 'pymodbus.logging', 'SSM.powerbox']); // Example log names
    const [logLevels, setLogLevels] = useState(['DEBUG', 'INFO', 'WARNING', 'ERROR', 'CRITICAL']); // Example log levels
    const [selectedLogName, setSelectedLogName] = useState(null);
    const [selectedLogLevel, setSelectedLogLevel] = useState(null);

    useEffect(() => {
        if (selectedLogName) {
            // Fetch the current log level for the selected log name
            AxiosSSM.get(`/logs/log_level?name=${selectedLogName}`)
                .then(response => {
                    const { log_level } = response.data;
                    console.log('Fetched log level:', log_level);
                    setSelectedLogLevel(log_level);
                })
                .catch(error => {
                    console.error('Error fetching log level:', error);
                    message.error('Failed to fetch log level');
                });
        }
    }, [selectedLogName]);

    useEffect(() => {
        // Fetch the current log level for the selected log name
        AxiosSSM.get(`/logs/log_names`)
            .then(response => {
                setLogNames(response.data.log_names);
                console.log(response.data.log_names);
            })
            .catch(error => {
                if (error.akd_generic_error) return;
                console.error('Error fetching log names:', error);
                message.error('Failed to fetch log level');
            });
    }, []);

    const handleLogNameChange = value => {
        setSelectedLogName(value);
    };

    const handleLogLevelChange = value => {
        setSelectedLogLevel(value);
    };

    const updateLogLevel = () => {
        if (selectedLogName && selectedLogLevel) {
            AxiosSSM.post(`/logs/log_level?name=${selectedLogName}&level=${selectedLogLevel}`)
                .then(response => {
                    message.success('Log level updated successfully');
                })
                .catch(error => {
                    console.error('Error updating log level:', error);
                    message.error('Failed to update log level');
                });
        }
    };

    return (
        <div>
            <Select
                value={selectedLogName}
                onChange={handleLogNameChange}
                placeholder="Select Log Name"
                style={{ width: 200, marginRight: '10px' }}
            >
                {logNames.map(name => (
                    <Option key={name} value={name}>{name}</Option>
                ))}
            </Select>
            <Select
                value={selectedLogLevel}
                onChange={handleLogLevelChange}
                placeholder="Select Log Level"
                style={{ width: 200, marginRight: '10px' }}
                disabled={!selectedLogName}
            >
                {logLevels.map(level => (
                    <Option key={level} value={level}>{level}</Option>
                ))}
            </Select>
            <Button type="primary" onClick={updateLogLevel} disabled={!selectedLogName || !selectedLogLevel}>
                Set Log Level
            </Button>
        </div>
    );
};

export default LogLevelManager;