import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AxiosOICStat from '../AxiosOICStat';
import { Table, Button, Form, Input, Popconfirm, message, Modal, Select, Tabs } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { FilterDropdown } from '../components/FilterDropdown';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        { required: true, message: `Please Input ${title}!` },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const AddMappingModal = ({ visible, onCancel, onAdd }) => {
    const [form] = Form.useForm();
    const countries = useSelector((state) => state.countries);

    const handleSubmit = async (values) => {
        try {
            // Add API call to add new mapping
            const response = await AxiosOICStat.post('/countries/countries-mappings/', values);
            message.success('Mapping added successfully');
            onAdd(response.data); // Add the new mapping to the table
        } catch (error) {
            console.error('Error adding mapping:', error);
            message.error('Error adding mapping');
        }
    };

    return (
        <Modal
            visible={visible}
            title="Add New Mapping"
            onCancel={onCancel}
            onOk={() => form.submit()}
            destroyOnClose={true}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="Country ID"
                    name="countryid"
                    rules={[{ required: true, message: 'Please select a country!' }]}
                >
                    <Select placeholder="Select a country">
                        {Object.values(countries.value).map(country => (
                            <Select.Option key={country.c_code} value={country.c_code}>
                                {country.c_short_name_eng}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Country Mapping Name"
                    name="country_mapping_name"
                    rules={[{ required: true, message: 'Please input the mapping name!' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export const ConcordanceTable = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const countries = useSelector((state) => state.countries);
    const [loading, setLoading] = useState(false);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const user = useSelector((state) => state.user);

    const isEditing = (record) => record.id === editingId;

    const edit = (record) => {
        form.setFieldsValue({ ...record });
        setEditingId(record.id);
    };

    const cancel = () => {
        setEditingId(null);
    };

    const handleDelete = async (id) => {
        try {
            await AxiosOICStat.delete(`/countries/countries-mappings/${id}`);
            message.success('Mapping deleted successfully');
            setData(data.filter((item) => item.id !== id));
        } catch (error) {
            console.error('Error deleting mapping:', error);
            message.error('Error deleting mapping');
        }
    };

    const handleAddNewMapping = () => {
        setAddModalVisible(true);
    };

    const handleAddModalCancel = () => {
        setAddModalVisible(false);
    };

    const handleAddMapping = (newMapping) => {
        newMapping.country_name = countries.value[newMapping.countryid]?.c_short_name_eng || 'Unknown';
        setData([...data, newMapping]);

        setAddModalVisible(false);
    };

    const save = async (id) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => id === item.id);

            if (index > -1) {
                const item = newData[index];
                // Update the mapping on the server
                const response = await AxiosOICStat.put(`/countries/countries-mappings/${item.id}`, {
                    ...item,
                    ...row
                });

                if (response && response.data) {
                    // Replace the item in the local state with updated data from server
                    newData[index] = response.data;
                    // update the name of the country in the local state
                    newData[index].country_name = countries.value[newData[index].countryid]?.c_short_name_eng || 'Unknown';
                    setData(newData);
                    message.success('Mapping updated successfully');
                } else {
                    message.error('Error updating mapping');
                }
                setEditingId(null);
            }
        } catch (errInfo) {
            console.error('Validate Failed:', errInfo);
            message.error('Error during saving');
        }
    };

    // Function to extract unique filter options from data
    const getFilterOptions = (data, column) => {
        const uniqueValues = new Set(data.map(item => item[column]));
        return Array.from(uniqueValues).map(value => ({
            text: value,
            value: value,
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await AxiosOICStat.get('/countries/countries-mappings/');
                response.data.forEach((item, index) => {
                    item.country_name = countries.value[item.countryid]?.c_short_name_eng || '';
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                message.error('Error fetching data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [countries]);


    const countryIdFilterOptions = getFilterOptions(data, 'countryid').sort((a, b) => a.text - b.text).map(option => ({
        ...option,
        text: option.text.toString(),
    }));

    const countryNameFilterOptions = getFilterOptions(data, 'country_name');

    const columns = [
        {
            title: 'Country ID',
            dataIndex: 'countryid',
            key: 'countryid',
            editable: false,
            filters: countryIdFilterOptions,
            onFilter: (value, record) => record.countryid === value,
            sorter: (a, b) => a.countryid < b.countryid ? -1 : 1,
            defaultSortOrder: 'ascend',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <FilterDropdown
                    options={countryIdFilterOptions}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                />
            ),
        },
        {
            title: 'Country Name',
            dataIndex: 'country_name',
            key: 'country_name',
            editable: false,
            filters: countryNameFilterOptions,
            onFilter: (value, record) => record.country_name === value,
            sorter: (a, b) => a.country_name.localeCompare(b.country_name),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <FilterDropdown
                    options={countryNameFilterOptions}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                />
            ),
        },
        {
            title: 'Country Mapping Name',
            dataIndex: 'country_mapping_name',
            key: 'country_mapping_name',
            editable: true,
            sorter: (a, b) => a.country_mapping_name.localeCompare(b.country_mapping_name),
        }
    ];

    user.role !== 2 && columns.push(
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a onClick={() => save(record.id)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <a onClick={cancel}>Cancel</a>
                    </span>
                ) : (
                    <span style={{ display: "flex", alignItems: "center" }}>
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => edit(record)}
                            style={{ marginRight: 8 }}
                            disabled={editingId !== null}
                        />
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
                            <Button icon={<DeleteOutlined />} type="danger" />
                        </Popconfirm>
                    </span>
                );
            },
        },
    );

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            {user.role !== 2 &&
                <Button type="primary" onClick={
                    handleAddNewMapping} style={{ marginTop: 16 }}>
                    Add New Mapping
                </Button>
            }
            <br/>
            <br/>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                rowKey="id"
                loading={loading}
            />
            <AddMappingModal
                visible={addModalVisible}
                onCancel={handleAddModalCancel}
                onAdd={handleAddMapping}
            />
        </Form>
    );
};