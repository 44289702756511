import React, { useEffect, useState, useMemo } from 'react';
import { Input, Table, message, Switch } from 'antd';
import AxiosOICStat from '../AxiosOICStat';
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tabs, Button, Modal, Row, Col, Form } from 'antd';
import * as XLSX from 'xlsx';
import { useSelector } from 'react-redux';

export const Source = () => {
    const [sources, setSources] = useState([]);
    const [originalSources, setOriginalSources] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [isAddMode, setIsAddMode] = useState(false);
    const user = useSelector((state) => state.user);
    const [form] = Form.useForm();
    const [searchValue, setSearchValue] = useState('');
    const { Search } = Input;

    /*
    const [showModalVisible, setShowModalVisible] = useState(false);
    const [wdgSources, setwdgSources] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.worldbank.org/v2/sources/all?format=json&per_page=100');
                // Assuming the actual data is in the second element of the response array
                const data = response.data[1].map(item => ({
                    id: item.id,
                    lastUpdated: item.lastupdated,
                    name: item.name,
                    code: item.code,
                    description: item.description,
                    url: item.url,
                    dataAvailability: item.dataavailability,
                    metadataAvailability: item.metadataavailability,
                    concepts: item.concepts
                }));
                setwdgSources(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const wdgColumns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: 'Last Updated', dataIndex: 'lastUpdated', key: 'lastUpdated' },
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Code', dataIndex: 'code', key: 'code' },
        { title: 'Description', dataIndex: 'description', key: 'description' },
        { title: 'URL', dataIndex: 'url', key: 'url' },
        { title: 'Data Availability', dataIndex: 'dataAvailability', key: 'dataAvailability' },
        { title: 'Metadata Availability', dataIndex: 'metadataAvailability', key: 'metadataAvailability' },
        { title: 'Concepts', dataIndex: 'concepts', key: 'concepts' }
    ];
    */

    // Function to convert JSON data to Excel and trigger download
    const exportToExcel = () => {
        // convert isVisible to string
        sources.forEach(source => {
            source.is_visible = source.is_visible ? 'visible' : 'non-visible';
            source.is_private = source.is_private ? 'private' : 'non-private';
        });
        const worksheet = XLSX.utils.json_to_sheet(sources);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sources");

        // Define file name
        let fileName = 'sources.xlsx';

        // Write and download the file
        XLSX.writeFile(workbook, fileName);
    };


    const handleEdit = (record) => {
        // Implement the edit functionality here
        console.log('Edit', record);

        // Set selectedRecord and editModalVisible to open the modal
        form.resetFields();
        setSelectedRecord(record);
        setEditModalVisible(true);
        setIsAddMode(false);

    };

    const handleEditModalCancel = () => {
        setEditModalVisible(false);
        setIsAddMode(false);
        form.resetFields();
        setSelectedRecord(null);
    };

    /*
    const handleShowModalCancel = () => {
        setShowModalVisible(false);
    };

    const handleShow = (record) => {
        // Implement the show functionality here
        setSelectedRecord(record);
        setShowModalVisible(true);
    };
    */


    const handleEditModalSubmit = (values) => {
        console.log('Received values of form: ', values);

        if (isAddMode) {
            AxiosOICStat.post('sources', values)
                .then(response => {
                    message.success('Source added successfully');
                    setSources(prevSources => [...prevSources, response.data]);
                    setEditModalVisible(false);
                })
                .catch(err => {
                    message.error('Error adding source');
                });
        } else {
            values.source_code = selectedRecord.source_code;
            AxiosOICStat.post(`sources/${selectedRecord.source_code}`, values)
                .then(response => {
                    message.success('Source updated successfully');
                    setSources(prevSources => prevSources.map(source => source.source_code === selectedRecord.source_code ? response.data : source));
                    setEditModalVisible(false);
                })
                .catch(err => {
                    message.error('Error updating source');
                });
        }
    };

    const handleDelete = (source) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this source?',
            onOk: () => {
                AxiosOICStat.delete(`sources/${source}`)
                    .then(() => {
                        console.log('Deleted source:', source);
                        message.success('Source deleted successfully');
                        setSources(prevSources => prevSources.filter(prev_source => prev_source.source_code !== source));
                    })
                    .catch(err => {
                        message.error('Error deleting source');
                    });
            }
        });
    };

    const handleAddNew = () => {
        setSelectedRecord(null);
        setEditModalVisible(true);
        setIsAddMode(true);
        form.resetFields();
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        const lowerCaseValue = value.toLowerCase();
        const filtered = originalSources.filter(source => {
            const sourceCode = source.source_code || '';
            const sourceName = source.source_name_eng || '';
            return sourceName.toLowerCase().includes(lowerCaseValue) || sourceCode.toString().includes(lowerCaseValue);
        });
        setSources(filtered);
    };

    useEffect(() => {
        const fetchSources = async () => {
            setLoading(true);
            try {
                const response = await AxiosOICStat.get('/sources/all'); // Replace with your actual API endpoint
                console.log('Fetched sources:', response.data);
                setSources(response.data);
                setOriginalSources(response.data);
            } catch (error) {
                console.error('Error fetching sources:', error);
                message.error('Error while fetching sources');
            } finally {
                setLoading(false);
            }
        };

        fetchSources();
    }, []);

    // Function to create filter options
    const createFilterOptions = (data, key) => {
        const uniqueItems = new Set(data.map(item => item[key]));
        return Array.from(uniqueItems).map(value => ({ text: value, value }));
    };

    // Assuming 'sources' array is already in the desired format
    const sourceNameFilterOptions = useMemo(() => createFilterOptions(sources, 'source_name_eng'), [sources]);

    const columns = [
        {
            title: 'Source Code',
            dataIndex: 'source_code',
            key: 'source_code',
            sorter: (a, b) => a.source_code - b.source_code,
        },
        {
            title: 'Name (English)',
            dataIndex: 'source_name_eng',
            key: 'source_name_eng',
            sorter: (a, b) => a.source_name_eng.localeCompare(b.source_name_eng),
            filters: sourceNameFilterOptions,
            onFilter: (value, record) => record.source_name_eng.includes(value),
            width: '50%'
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            sorter: (a, b) => a.source_name_eng.localeCompare(b.source_name_eng),
            filters: sourceNameFilterOptions,
            onFilter: (value, record) => record.source_name_eng.includes(value),
            render: (text) => <a href={text} target="_blank" rel="noopener noreferrer">{text}</a>,
        },
        {
            title: 'Is private?',
            dataIndex: 'is_private',
            sorter: (a, b) => a.is_private - b.is_private,
            render: (isPrivate) => (isPrivate === 1 ? 'private' : 'non-private'),
        },
        {
            title: 'Is visible?',
            dataIndex: 'is_visible',
            sorter: (a, b) => a.is_visible - b.is_visible,
            render: (isVisible) => (isVisible === true ? 'visible' : 'non-visible'),
        },
    ];

    user.role !== 2 && columns.push({
        title: 'Actions',
        dataIndex: '',
        key: 'actions',
        render: (text, record) => (
            <>
                <span style={{ display: "flex" }}>
                    {
                        //<Button icon={<EyeOutlined />} onClick={() => handleShow(record)}></Button>
                    }
                    <Button style={{ marginLeft: 8 }} type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record)}></Button>
                    <Button style={{ marginLeft: 8 }} type="danger" icon={<DeleteOutlined />} onClick={() => handleDelete(record.source_code)}></Button>
                </span>
            </>
        ),
    });


    const items = [
        {
            key: '1',
            label: 'Source Table',
            children: <Row>
                <Col style={{marginTop: "10px", marginBottom:"10px"}}>
                    {user.role !== 2 && <Button type="primary" onClick={handleAddNew}>Add New Source</Button>}
                    <Button onClick={exportToExcel} type="primary" style={{ marginLeft: 8 }}>
                        Export to Excel
                    </Button>
                </Col>
                <Search
                placeholder="Search"
                allowClear
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ marginBottom: 10 }}
            />
                <Col xs={24}>
                    <Table
                        rowKey="source_code"
                        dataSource={sources}
                        columns={columns}
                        size="small"
                        pagination={{pageSize:"10"}}
                        loading={{ spinning: loading, tip: 'Loading' }}
                    />
                </Col>

                <Modal
                    title="Edit Source"
                    visible={editModalVisible}
                    onCancel={handleEditModalCancel}
                    footer={null}
                    destroyOnClose={true}
                >
                    {/* Show the fields of source here */}
                    <Form form={form} layout="vertical" onFinish={handleEditModalSubmit}>
                        { /**
                        <Form.Item
                            label="Source Code"
                            name="source_code"
                            rules={[{ required: true, message: 'Please input the source code!' }]}
                            initialValue={selectedRecord?.source_code}
                        >
                            <Input />
                        </Form.Item>
                        **/}
                        <Form.Item
                            label="Name (English)"
                            name="source_name_eng"
                            rules={[{ required: true, message: 'Please input the name (English)!' }]}
                            initialValue={selectedRecord?.source_name_eng}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Is private?"
                            name="is_private"
                            valuePropName="checked"
                            initialValue={selectedRecord?.is_private}
                        >
                            <Switch />
                        </Form.Item>
                        <Form.Item
                            label="Is visible?"
                            name="is_visible"
                            valuePropName="checked"
                            initialValue={selectedRecord?.is_visible}
                        >
                            <Switch />
                        </Form.Item>
                        <Form.Item
                            label="Link"
                            name="link"
                            initialValue={selectedRecord?.link}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Link (Arabic)"
                            name="link_ar"
                            initialValue={selectedRecord?.link_ar}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Link (French)"
                            name="link_fr"
                            initialValue={selectedRecord?.link_fr}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Row>,
        },
        /*
        {
            key: '2',
            label: 'WDG Source Table',
            children: <Table dataSource={wdgSources} columns={wdgColumns} rowKey="id" />,
        }
        */
    ];


    return (
        <Tabs defaultActiveKey="1" items={items} />
    );
};