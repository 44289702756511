import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, message, Select, InputNumber } from 'antd';
import AxiosOICStat from '../../AxiosOICStat';
import { Switch } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setIndicator, setIndicators } from '../../features/indicatorsSlice';
import "../../style/App.scss"

let isFetch = true;

export const setFetch = (newValue) => {
    isFetch = newValue;
};

export const getFetch = () => isFetch;

export const IndicatorEditModal = ({ visible, record, onCancel, onEdit, editable, isAddMode }) => {
    const [form] = Form.useForm();
    const [indicatorData, setIndicatorData] = useState({});
    const [initialSecondaryCategories, setInitialSecondaryCategories] = useState([]);
    const [secondaryCategories, setSecondaryCategories] = useState([]);
    const unitsState = useSelector((state) => state.units);
    const categoriesState = useSelector((state) => state.categories);
    const sourcesState = useSelector((state) => state.sources);
    const dispatch = useDispatch();


    const fetchData = () => {
        // Example fetch function
        AxiosOICStat.get('indicators/details')
            .then(response => {
                // Handle the response, e.g., set state or dispatch an action to update Redux
                console.log('Fetched data:', response.data);
                dispatch(setIndicators(response.data));
                // Optionally update state or dispatch another action here
            })
            .catch(err => {
                console.error('Error fetching data:', err);
            });
    };
    

    useEffect(() => {
        if (isAddMode) {
            // Reset form for add mode
            form.resetFields();
            setIndicatorData({});
            setInitialSecondaryCategories([]);
            setSecondaryCategories([]);
        } else if (record && record.ind_code) {
            AxiosOICStat.get(`indicators/${record.ind_code}`)
                .then(response => {
                    setIndicatorData(response.data);
                    form.setFieldsValue(response.data);
                })
                .catch(err => {
                    console.log(err);
                });

            AxiosOICStat.get(`secondary-categories/${record.ind_code}`)
                .then(response => {
                    const categoryCodes = response.data.map(item => item.cat_code);
                    // add secondary categories to the indicator data and set the form fields
                    setSecondaryCategories(categoryCodes);
                    form.setFieldsValue({
                        secondaryCategories: categoryCodes,
                    });
                    setInitialSecondaryCategories(categoryCodes);
                })
                .catch(err => {
                    // set to empty array if no secondary categories
                    setIndicatorData(prevIndicatorData => {
                        return {
                            ...prevIndicatorData,
                            secondaryCategories: [],
                        };
                    }
                    );
                    form.setFieldsValue({
                        secondaryCategories: [],
                    });
                    console.log(err);
                });
        }
    }, [record, form, isAddMode]);




    const handleEditSubmit = (values) => {
        if (isAddMode) {

            //extract the secondary categories from the main indicator values
            const { ...indicatorValues } = values;
            console.log(secondaryCategories, indicatorValues);

            // Handle add new indicator
            AxiosOICStat.post(`indicators`, indicatorValues).then(response => {
                message.success('Indicator with code ' + response.data.ind_code + ' is added successfully');
                onEdit(response.data);
                handleCancel();

                // Handle added categories
                secondaryCategories.forEach(cat => {
                    AxiosOICStat.post(`secondary-categories/`, { ind_code: response.data.ind_code, cat_code: cat });
                });
                dispatch(setIndicator(response.data));
                setFetch(true);
            }).catch(err => {
                message.error('Error adding indicator');
                handleCancel();
            });
        } else {
            // Separate the secondary categories from the main indicator values
            const { ...indicatorValues } = values;

            // Determine added and removed categories
            const addedCategories = secondaryCategories.filter(cat => !initialSecondaryCategories.includes(cat));
            const removedCategories = initialSecondaryCategories.filter(cat => !secondaryCategories.includes(cat));

            // Update the main indicator data
            AxiosOICStat.put(`indicators/${record.ind_code}`, indicatorValues).then(response => {
                message.success('Indicator updated successfully');
                //updateIndicators(response.data);
                //fetchIndicators();
                //console.log(response.data);
                //setIndicator(response.data);
                // Handle added categories
                addedCategories.forEach(cat => {
                    AxiosOICStat.post(`secondary-categories/`, { ind_code: record.ind_code, cat_code: cat });
                });

                // Handle removed categories
                removedCategories.forEach(cat => {
                    AxiosOICStat.delete(`secondary-categories/${record.ind_code}/${cat}`);
                });

                handleCancel(1);
                dispatch(setIndicator(response.data));
                fetchData()
                
                setFetch(true);
            }).catch(err => {
                message.error('Error updating indicator');
            });
        }
    };

    const handleCancel = (code = -1) => {
        // Reset the form when the modal is closed
        form.resetFields();
        setIndicatorData({});
        setInitialSecondaryCategories([]);
        setSecondaryCategories([]);

        if (code === 1) {
            onCancel(1);
        } else {
            onCancel();
        }
    };

    const handleSecondaryCategoriesChange = (selectedItems) => {
        setSecondaryCategories(selectedItems);
    };

    return (
        <Modal
            visible={visible}
            title={isAddMode ? "Add New Indicator" : (editable ? "Edit Indicator" : "Show Indicator")}
            onCancel={handleCancel}
            onOk={() => form.submit()}
            width="60%"
            footer={isAddMode || editable ? [
                <Button key="cancel" onClick={handleCancel}>Cancel</Button>,
                <Button key="submit" type="primary" onClick={() => form.submit()}>
                    {isAddMode ? "Add" : "Save"}
                </Button>,
            ] : [
                <Button key="close" onClick={handleCancel}>Close</Button>
            ]}
        >
            {indicatorData && (
                <Form
                    form={form}
                    onFinish={handleEditSubmit}
                    onCancel={handleCancel}
                    disabled={!editable}
                    labelCol={{ span: 8 }}
                    labelAlign='left'
                    wrapperCol={{ span: 16 }}
                >
                    <Form.Item label="Code" name="ind_code">
                        {isAddMode ? (
                            <p className="custom-input">Will be determined automatically</p>
                        ) : (
                            <InputNumber disabled className="custom-input" style={{ width: "100%" }} />
                        )}
                    </Form.Item>
                    <Form.Item label="Name" name="ind_name_eng">
                        <Input className="custom-input" />
                    </Form.Item>
                    <Form.Item label="Explanation" name="ind_eng_exp">
                        <Input.TextArea autoSize={{ minRows: 1, maxRows: 4 }} className="custom-input" />
                    </Form.Item>
                    <Form.Item label="Primary Category Name - Code" name="cat_code">
                        <Select showSearch optionFilterProp="children" className="custom-input">
                            {Object.values(categoriesState.value).map(category => (
                                <Select.Option key={category.cat_code} value={category.cat_code}>
                                    {category.cat_name_eng} - {category.cat_code}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Secondary Categories" name="secondaryCategories">
                        <Select
                            mode="multiple"
                            optionFilterProp="children"
                            placeholder="Select secondary categories"
                            showSearch
                            value={secondaryCategories}
                            onChange={handleSecondaryCategoriesChange}
                            className="custom-input"
                        >
                            {Object.values(categoriesState.value).map(category => (
                                category.cat_code !== indicatorData.cat_code && (
                                    <Select.Option key={category.cat_code} value={category.cat_code}>
                                        {category.cat_name_eng} - {category.cat_code}
                                    </Select.Option>
                                )))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Unit Name - Code" name="unit_code">
                        <Select showSearch optionFilterProp="children" className="custom-input">
                            {Object.values(unitsState.value).map(unit => (
                                <Select.Option key={unit.unit_code} value={unit.unit_code}>
                                    {unit.unit_name_eng} - {unit.unit_code}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Source Name - Code" name="source_code">
                        <Select showSearch optionFilterProp="children" className="custom-input">
                            {Object.values(sourcesState.value).map(source => (
                                <Select.Option key={source.source_code} value={source.source_code}>
                                    {source.source_name_eng} - {source.source_code}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Name (French)" name="ind_name_fr">
                        <Input className="custom-input" />
                    </Form.Item>
                    <Form.Item label="Name (Arabic)" name="ind_name_arb">
                        <Input className="custom-input" />
                    </Form.Item>
                    <Form.Item label="Explanation (French)" name="ind_fr_exp">
                        <Input.TextArea autoSize={{ minRows: 1, maxRows: 4 }} className="custom-input" />
                    </Form.Item>
                    <Form.Item label="Explanation (Arabic)" name="ind_arb_exp">
                        <Input.TextArea autoSize={{ minRows: 1, maxRows: 4 }} className="custom-input" />
                    </Form.Item>
                    <Form.Item label="Is Private" name="is_private" valuePropName="checked">
                        <Switch className="custom-input" />
                    </Form.Item>
                    <Form.Item label="Type" name="ind_type" initialValue={null}>
                        <Select allowClear className="custom-input">
                            <Select.Option value={null}>null</Select.Option>
                            <Select.Option value="raw">Raw</Select.Option>
                            <Select.Option value="calculated">Calculated</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Aggregation Method" name="aggr_method" initialValue={null}>
                        <Select allowClear disabled className="custom-input">
                            <Select.Option value={null}>null</Select.Option>
                            <Select.Option value="Sum">Sum</Select.Option>
                            <Select.Option value="Weighted">Weighted</Select.Option>
                            <Select.Option value="Median">Median</Select.Option>
                            <Select.Option value="Average">Average</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Aggregation Formula" name="aggr_formula" initialValue={null}>
                            <Input className="custom-input" />
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};