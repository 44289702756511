import React, { useEffect, useState } from 'react';
import { Input, Select, Button, Checkbox, Space, Typography, message, Row, Col } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import AxiosOICStat from '../AxiosOICStat';

const { Option } = Select;
const { Title, Paragraph } = Typography;

export const AddAggregation = () => {
    const [formulaName, setFormulaName] = useState("");
    const [baseIndicator, setBaseIndicator] = useState(undefined);
    const [weightIndicator, setWeightIndicator] = useState(undefined);
    const [aggregationType, setAggregationType] = useState("");
    const [operations, setOperations] = useState([]);
    const [formulaPreview, setFormulaPreview] = useState("");
    const [currentFormula, setCurrentFormula] = useState("");
    const [syncWithIndicator, setSyncWithIndicator] = useState(false);
    const [customFormulaMode, setCustomFormulaMode] = useState(false);
    const [warnings, setWarnings] = useState({});
    const indicatorsState = useSelector(state => Object.values(state.indicators.value));

    const addOperation = () => {
        setOperations(prevOperations => [
            ...prevOperations,
            { id: uuidv4(), operator: "", hasPrecedence: false, value: undefined, useConstant: false }
        ]);
    };

    const handleOperationChange = (value, field, id) => {
        setOperations(prevOperations =>
            prevOperations.map(op =>
                op.id === id ? { ...op, [field]: value } : op
            )
        );
    };

    const removeOperation = (id) => {
        setOperations(prevOperations => prevOperations.filter(op => op.id !== id));
    };

    const togglePrecedence = (id) => {
        setOperations(prevOperations =>
            prevOperations.map(op =>
                op.id === id ? { ...op, hasPrecedence: !op.hasPrecedence } : op
            )
        );
    };

    const toggleConstant = (id) => {
        setOperations(prevOperations =>
            prevOperations.map(op =>
                op.id === id ? { ...op, useConstant: !op.useConstant, value: undefined } : op
            )
        );
    };

    const handleBaseIndicatorChange = (value) => {
        const lastIndex = value.lastIndexOf('&');
        const name = value.substring(0, lastIndex);
        const formula = value.substring(lastIndex + 1);
        setCurrentFormula(formula);
        setBaseIndicator(name);

    };

    const handleWeightIndicatorChange = (value) => {
        setWeightIndicator(value);
    };

    const handleAggregationTypeChange = (value) => {
        setAggregationType(value);
    };

    useEffect(() => {
        const status = localStorage.getItem('saveStatus');
        if (status === 'success') {
            message.success('Formula saved successfully!');
            localStorage.removeItem('saveStatus'); // Clear the flag
        }
    }, []);
    

    const saveFormula = () => {
        const newWarnings = {};
    
        if (!formulaName) newWarnings.formulaName = 'Formula name is required';
        if (!baseIndicator) newWarnings.baseIndicator = 'Base indicator is required';
        if (!aggregationType) newWarnings.aggregationType = 'Aggregation type is required';
    
        setWarnings(newWarnings);
    
        if (Object.keys(newWarnings).length === 0) {
            const formulaText = formulaPreview + ' ';
            const inds = new Set();
            formulaText.split(',').filter(part => {
                if (part.startsWith('ind')) {
                    inds.add(part.substring(3));
                    return true;
                }
                return false;
            });
    
            const formData = {
                formulaName,
                baseIndicator: Number(baseIndicator.split(' - ')[0]),
                aggregationType,
                inds: Array.from(inds).join(','),
                formula: formulaPreview
            };
    
            AxiosOICStat.put('/indicators/indicator-aggrData-update/', formData)
            .then(response => {
                localStorage.setItem('saveStatus', 'success');
                window.location.reload();
            })
            .catch(error => {
                message.error('Error adding the formula to the indicator data.');
            });
        } else {
            message.error('Please fill in all fields.');
        }
    }
    
    useEffect(() => {
        if (syncWithIndicator && baseIndicator) {
            const selectedIndicatorName = baseIndicator.split('-')
            setFormulaName(selectedIndicatorName[1] || '');
        }
    }, [syncWithIndicator, baseIndicator, indicatorsState]);

    useEffect(() => {
        let formula = baseIndicator && ("ind" + baseIndicator.split(' - ')[0]);
        /*operations.forEach((op, i) => {
            let part = op.value;
            console.log(op.value);
            if (!part) {
                return;
            }
            part = part.split(' - ')[0];
            let cnt = i;
            let parentheses = 0;
            while (!op.hasPrecedence && operations[cnt + 1] && operations[cnt + 1].hasPrecedence) {
                parentheses++;
                cnt++;
            }
            if (!op.useConstant) {
                part = "ind" + part;
            }
            if (parentheses > 0) {
                part = '('.repeat(parentheses) + part;
            }
            if (op.hasPrecedence) {
                formula += `${part})${op.operator}`;
            } else {
                formula += `${part} ${op.operator}`;
            }
        });*/
        if(weightIndicator){
            formula = formula + ",ind" + weightIndicator.split(' - ')[0];
            setFormulaPreview(formula);
        }
        else{
            setFormulaPreview(formula);
        }
    }, [baseIndicator, operations, weightIndicator]);

    return (
        <>
            <Title level={3}>Add Aggregation Formula</Title>
            <Row gutter={16} align="middle" style={{ marginBottom: 16 }}>
                <Col span={18}>
                    <Input
                        placeholder="Enter formula name"
                        value={formulaName}
                        onChange={e => setFormulaName(e.target.value)}
                        disabled={syncWithIndicator}
                    />
                    {warnings.formulaName && <div style={{ color: 'red' }}>{warnings.formulaName}</div>}
                </Col>
                <Col span={6}>
                    <Checkbox
                        checked={syncWithIndicator}
                        onChange={e => setSyncWithIndicator(e.target.checked)}
                    >
                        Use base indicator name
                    </Checkbox>
                </Col>
            </Row>
            {warnings.baseIndicator && <div style={{ color: 'red' }}>{warnings.baseIndicator}</div>}
            <Select
                showSearch
                placeholder="Select base indicator"
                value={baseIndicator}
                onChange={handleBaseIndicatorChange}
                style={{ width: '100%', marginBottom: 16 }}
            >
                {indicatorsState.map(indicator => (
                    <Option key={indicator.ind_code} value={`${indicator.ind_code} - ${indicator.ind_name_eng} & ${indicator.aggr_formula}`}>
                        {indicator.ind_code} - {indicator.ind_name_eng} - {indicator.aggr_formula}
                    </Option>
                ))}
            </Select>
            
            <Select
                showSearch
                placeholder="Select the Indicator to be Weighted (Optional)"
                value={weightIndicator}
                onChange={handleWeightIndicatorChange}
                style={{ width: '100%', marginBottom: 16 }}
            >
                {indicatorsState.map(indicator => (
                    <Option key={indicator.ind_code} value={`${indicator.ind_code} - ${indicator.ind_name_eng}`}>
                        {indicator.ind_code} - {indicator.ind_name_eng}
                    </Option>
                ))}
            </Select>
            {!customFormulaMode && (
                <>
                    {operations.map((operation, index) => (
                        <Space key={operation.id} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                            <Select
                                placeholder="Select operator"
                                value={operation.operator}
                                style={{ width: 120 }}
                                onChange={(value) => handleOperationChange(value, 'operator', operation.id)}
                            >
                                <Option value="+">+</Option>
                                <Option value="-">-</Option>
                                <Option value="*">*</Option>
                                <Option value="/">/</Option>
                            </Select>
                            {
                                index !== 0 && <Checkbox
                                    checked={operation.hasPrecedence}
                                    onChange={() => togglePrecedence(operation.id)}
                                >
                                    Use parentheses
                                </Checkbox>
                            }
                            {
                                !operation.useConstant ? <Select
                                    showSearch
                                    placeholder="Select indicator"
                                    value={operation.value}
                                    style={{ width: 200 }}
                                    onChange={(value) => handleOperationChange(value, 'value', operation.id)}
                                >
                                    {indicatorsState.map(indicator => (
                                        <Option key={indicator.ind_code} value={"ad"}>{indicator.ind_code} - {indicator.ind_name_eng}</Option>
                                    ))}
                                </Select> : <Input
                                    placeholder="Enter constant value"
                                    style={{ width: 200 }}
                                    type="number"
                                    value={operation.value}
                                    onChange={(e) => handleOperationChange(e.target.value, 'value', operation.id)}
                                />
                            }
                            <Checkbox
                                checked={operation.useConstant}
                                onChange={() => toggleConstant(operation.id)}
                            >
                                Use constant value
                            </Checkbox>
                            <MinusCircleOutlined onClick={() => removeOperation(operation.id)} />
                        </Space>
                    ))}
                </>
            )}
            {warnings.aggregationType && <div style={{ color: 'red' }}>{warnings.aggregationType}</div>}
            <Select
                showSearch
                placeholder="Select aggregation type"
                value={aggregationType || undefined}
                onChange={handleAggregationTypeChange}
                style={{ width: '100%', marginBottom: 16 }}
                allowClear
            >
                <Option value="Sum">Sum</Option>
                <Option value="Median">Median</Option>
                <Option value="Weighted Average">Weighted Average</Option>
                <Option value="Growth Rate">Growth Rate</Option>
            </Select>
            <Paragraph>
            <strong>Current Formula:</strong> {currentFormula}
            </Paragraph>
            <Paragraph>
            <strong>Formula Preview:</strong> {formulaPreview}
            </Paragraph>
            <Button type="primary" onClick={saveFormula}>
                Save Formula
            </Button>
        </>
    );
};
