import { useEffect } from "react";
import { Tooltip } from 'antd';
import { LineOutlined, UpOutlined, DownOutlined } from '@ant-design/icons'
import { formatYWithDecimalDigits } from "./charts/DataUtils";

// Function to create filter options from data
export const createFilterOptions = (data, key) => {
    // Get the unique values from the data[key]
    const values = data.map((item) => item[key]);
    const uniqueValues = [...new Set(values)];
    return uniqueValues.map((name) => ({ text: name, value: name }));
    //const uniqueNames = new Set(Object.values(stateValues).map(item => item));
    //return Array.from(uniqueNames).map(name => ({ text: name, value: name }));
}

export function formatDataTableValue(value, decimalSymbol = '.', decimalPlaces = 1) {
    // Return NaN if the value is null, "NaN", or not a number
    if (value === null || value == "NaN" || isNaN(parseFloat(value))) {
        return NaN;
    }

    // Adjust decimal places based on the value
    if (Math.abs(value) < 100) {
        decimalPlaces = 1; 
    } else {
        decimalPlaces = 0; 
    }
    
    // Custom rounding function
    function customRound(num, decimalPlaces) {
        var factor = Math.pow(10, decimalPlaces);
        var m = Number((Math.abs(num) * factor).toPrecision(15));
        var q = Math.ceil(m);
        var i = parseInt(m, 10);
        var r = i === q ? (i % 2 === 0 ? i : q) : Math.round(m);
        return (Math.sign(num) * r / factor).toFixed(decimalPlaces);
    }

    // Format value
    const isNegative = value < 0; // Check if the value is negative
    const absoluteValue = Math.abs(value); // Get the absolute value
    const roundedValue = customRound(absoluteValue, decimalPlaces); // Round the absolute value

    // Add thousand separators
    var regexPattern = /\B(?=(\d{3})+(?!\d))/g;
    var formattedValue = roundedValue.replace(regexPattern, ',');

    // Replace the decimal symbol if necessary
    if (decimalSymbol !== '.') {
        formattedValue = formattedValue.replace('.', decimalSymbol);
    }

    // Prepend the negative sign if the value was negative
    return isNegative ? `-${formattedValue}` : formattedValue;
}


const formatPercentageDifference = (value) => {
    // Ensure value is a number
    const numericValue = Number(value);
    if (Number.isNaN(numericValue)) { // Checks if value is NaN
        console.error("Non-numeric value received:", value);
        return ""; // Or handle this case as appropriate
    }

    if (numericValue === 0) return "0"; // Returns "0" if the number is zero.

    // Determine the number of decimal places required
    let decimalPlaces = 3;
    while (numericValue.toFixed(decimalPlaces) === "0.000" && decimalPlaces < 10) { // Increase decimalPlaces up to a sane limit to avoid infinite loops
        decimalPlaces++;
    }
    return numericValue.toFixed(decimalPlaces);
};


export const renderPercentageChange = (_, colName, currentCount, percentageDifference, tableData, previousDate = null,  previousData = null, useDataTableFormat = false, decimalSymbol = '.', decimalPlaces = 6) => {
    if (percentageDifference == null) {
        percentageDifference = 0;
    }

    let text = "";
    if (useDataTableFormat) {
        text = formatDataTableValue(currentCount, decimalSymbol, decimalPlaces);
    } else {
        text = formatYWithDecimalDigits(currentCount);
    }

    let tooltipContent = undefined;
    if (previousDate) {
        if (previousData) {
            tooltipContent = `${previousDate}: ${previousData}`;
        } else {
            const previousCount = tableData.find(row => row.date_calculated === previousDate);
            tooltipContent = previousCount !== undefined ? `Previous: ${previousDate} - ${formatYWithDecimalDigits(previousCount[colName])}` : undefined;
        }
    }

    let color = 'black', Icon = LineOutlined;
    if (percentageDifference > 0) {
        color = 'green';
        Icon = UpOutlined;
    } else if (percentageDifference < 0) {
        color = 'red';
        Icon = DownOutlined;
    }

    if (percentageDifference !== 0) {
        if (tooltipContent) {
            return (
                <Tooltip title={tooltipContent}>
                    <span>
                        <span style={{ marginInline: "10px" }}>{text}</span>
                        <span style={{ color, fontWeight: "bold", float: "right" }}>
                            ({formatPercentageDifference(percentageDifference)}%) <Icon />
                        </span>
                    </span>
                </Tooltip>
            );
        } else {
            return (
                <>
                    <span style={{ marginInline: "10px" }}>{text}</span>
                    <span style={{ color, fontWeight: "bold", float: "right" }}>
                        ({formatPercentageDifference(percentageDifference)}%) <Icon />
                    </span>
                </>
            );
        }
    } else {
        return (
            <>
                <span style={{ marginInline: "10px" }}>{text}</span>
                <span style={{ color, fontWeight: "bold", float: "right" }}>
                    ({percentageDifference}%) <Icon />
                </span>
            </>
        );
    }
};