import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Card, Table, Button } from 'antd';
import AxiosOICStat from '../AxiosOICStat';
import { DataChart } from '../components/charts/DataChart';
import { convertDataForLineChart, convertDataForIndicatorsCount, formatYWithDecimalDigits } from '../components/charts/DataUtils';
import { CustomRangePicker } from '../components/CustomRangePicker';
import { UpOutlined, DownOutlined, LineOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import * as XLSX from 'xlsx';

const { Content } = Layout;

export function DataOverview() {
    const [dateRange, setDateRange] = useState([]);
    const [frequency, setFrequency] = useState('monthly');
    const [dataCounts, setDataCounts] = useState([]);
    const [indicatorsCount, setIndicatorsCount] = useState([]);
    const [type, setType] = useState('month');
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);


    const exportToExcel = () => {
        // Map tableData to a new format
        const formattedData = tableData.map(item => {
            return {
                'Date Calculated': item.date_calculated,
                'Data Count': item.data_counts,
                'Percentage Change in Data Count': item.percentageChange,
                'Public Indicators Count': item.public_indicators_count,
                'Percentage Change in Public Indicators': item.perDiffPubInd,
                'Private Indicators Count': item.private_indicators_count,
                'Percentage Change in Private Indicators': item.perDiffPriInd,
                // Add more fields here if needed
            };
        });

        // Create a new workbook and a worksheet with the formatted data
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'TableData');

        // Download the workbook
        XLSX.writeFile(wb, 'table_data.xlsx');
    };


    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const fetchData = async () => {
        try {
            const startDate = dateRange[0].format('YYYY-MM-DD');
            const endDate = dateRange[1].format('YYYY-MM-DD');

            const response = await AxiosOICStat.get(`metadata/data-points`, {
                params: { start_date: startDate, end_date: endDate, return_frequency: frequency }
            });

            if (response.data) {
                const processedData = response.data.map((item, index, array) => {
                    if (index === 0) {
                        return { ...item, percentageChange: 0, perDiffPubInd: 0, perDiffPriInd: 0, previousDate: null };
                    } else {
                        const previousDataCount = array[index - 1].data_counts;
                        const percentageDifference = ((item.data_counts - previousDataCount) / previousDataCount) * 100;
                        const prePubInd = array[index - 1].public_indicators_count;
                        const prePriInd = array[index - 1].private_indicators_count;
                        const perDiffPubInd = ((item.public_indicators_count - prePubInd) / prePubInd) * 100;
                        const perDiffPriInd = ((item.private_indicators_count - prePriInd) / prePriInd) * 100;
                        return { ...item, perDiffPubInd, perDiffPriInd, percentageChange: percentageDifference, previousDate: array[index - 1].date_calculated };
                    }
                });
                setDataCounts(convertDataForLineChart(response.data));
                setIndicatorsCount(convertDataForIndicatorsCount(response.data));
                setTableData(processedData);
            } else {
                setDataCounts([]);
                setIndicatorsCount([]);
                setTableData([]);
            }
        } catch (error) {
            console.error('Failed to fetch data', error);
        }
    };

    const formatPercentageDifference = (value) => {
        if (value === 0) return value.toFixed(0);

        // Determine the number of decimal places required
        let decimalPlaces = 3;
        while (value.toFixed(decimalPlaces) == 0) {
            decimalPlaces++;
        }
        return value.toFixed(decimalPlaces);
    };

    const renderPercentageChange = (_, colName, currentCount, percentageDifference, previousDate) => {
        const previousCount = tableData.find(row => {
            return row.date_calculated === previousDate
        });

        const tooltipContent = previousCount !== undefined ? `Previous: ${previousDate} - ${formatYWithDecimalDigits(previousCount[colName])}` : undefined;

        // Define color and icon based on the percentage difference
        let color = 'black', Icon = LineOutlined;
        if (percentageDifference > 0) {
            color = 'green';
            Icon = UpOutlined;
        } else if (percentageDifference < 0) {
            color = 'red';
            Icon = DownOutlined;
        }

        if (percentageDifference !== 0) {
            return (
                <Tooltip title={tooltipContent}>
                    <span>
                        {formatYWithDecimalDigits(currentCount)}
                        <span style={{ color, fontWeight: "bold", float: "right" }}>
                            ({formatPercentageDifference(percentageDifference)}%) <Icon />
                        </span>
                    </span>
                </Tooltip>
            );
        } else {
            return <>
                {formatYWithDecimalDigits(currentCount)}
                <span style={{ color, fontWeight: "bold", float: "right" }}>
                    ({percentageDifference}%) <Icon />
                </span>
            </>
        }
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date_calculated',
            key: 'date_calculated',
        },
        {
            title: 'Data Count',
            dataIndex: 'data_counts',
            key: 'data_counts',
            sorter: (a, b) => a.percentageChange - b.percentageChange,
            render: (_, record) => renderPercentageChange(_, "data_counts", record.data_counts, record.percentageChange, record.previousDate)
        },
        {
            title: 'Public Indicators',
            dataIndex: 'public_indicators_count',
            key: 'public_indicators_count',
            sorter: (a, b) => a.perDiffPubInd - b.perDiffPubInd,
            render: (_, record) => renderPercentageChange(_, "public_indicators_count", record.public_indicators_count, record.perDiffPubInd, record.previousDate)
        },
        {
            title: 'Private Indicators',
            dataIndex: 'private_indicators_count',
            key: 'private_indicators_count',
            sorter: (a, b) => a.perDiffPriInd - b.perDiffPriInd,
            render: (_, record) => renderPercentageChange(_, "private_indicators_count", record.private_indicators_count, record.perDiffPriInd, record.previousDate)
        },
    ];



    const isResultEmpty = (dataCounts[0] && dataCounts[0].data && dataCounts[0].data.length === 0);
    const isDataAvailable = !isResultEmpty && dataCounts.length > 0 && indicatorsCount.length > 0;

    return (
        <Layout>
            <Content style={{ padding: '20px' }}>
                <Row gutter={16}>
                    <CustomRangePicker dateRange={dateRange} setDateRange={setDateRange} type={type} setType={setType} setFrequency={setFrequency} frequency={frequency} fetchData={fetchData} />
                </Row>
                <Row gutter={16} style={{ marginTop: '20px' }}>
                    {isDataAvailable ? (
                        <>
                            <Col gutter={16} span={24} style={{ marginTop: '20px' }}>
                                <Col span={24}>
                                    <Table
                                        dataSource={tableData}
                                        columns={columns}
                                        onChange={handleTableChange}
                                        pagination={{ current: currentPage, pageSize: pageSize }}
                                    />
                                </Col>
                            </Col>
                            <Col span={12}>
                                <Card title="Data Counts Graph">
                                    <div style={{ height: "400px" }}>
                                        <DataChart data={dataCounts} axisNames={["Time", "Data Counts"]} type="data" />
                                    </div>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card title="Indicators Count Graph">
                                    <div style={{ height: "400px" }}>
                                        <DataChart data={indicatorsCount} axisNames={["Time", "Indicator Counts"]} type="indicator" />
                                    </div>
                                </Card>
                            </Col>
                            <Col span={12} style={{ marginTop: "20px" }}>
                                <Button onClick={exportToExcel} type="primary" style={{ marginLeft: '10px' }}>
                                    Export Table
                                </Button>
                            </Col>
                        </>
                    ) : isResultEmpty ? (
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <p>No data available for the selected date range and frequency.</p>
                        </div>
                    )
                        : (
                            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <p>Select a date range and frequency, then click 'Fetch Data'.</p>
                            </div>
                        )}

                </Row>
            </Content>
        </Layout>
    );
}