import React, { useState, useEffect } from 'react';
import AxiosOICStat from '../AxiosOICStat';
import { Select, Button, Row, Col, Tooltip, Checkbox, Card, Collapse, Tag } from 'antd';
import DataTable from '../components/DataTable';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import DatePicker from 'react-multi-date-picker';
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import PresetValuesPlugin from '../components/datePickerPlugins/PresetValuesPlugin';
import Icon from "react-multi-date-picker/components/icon";

const { Option } = Select;

export function DataComparator({ auto_search = false }) {
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const countriesState = useSelector((state) => state.countries);
    const indicatorsState = useSelector((state) => state.indicators);
    const [regionMappingsData, setRegionMappingsData] = useState([]);
    const [countryMap, setCountryMap] = useState({});
    const [selectedRegions, setSelectedRegions] = useState([]);

    const renderTagPlaceholder = (omittedValues) => {
        // Sort the omitted values alphabetically based on their labels
        const sortedOmittedValues = [...omittedValues].sort((a, b) => parseInt(a.value) - parseInt(b.value));

        // Create a multiline string of all sorted omitted values
        const fullList = sortedOmittedValues.map(val => val.label).join('\n');
        return (
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{fullList}</span>}>
                <span>{`+${omittedValues.length} more`}</span>
            </Tooltip>
        );
    };

    /* labelları tek tek azaltmak için kullanılacak customTagRender ile beraber ancak önceki yaptıklarımı da eziyor. Daha müsait bir zamanda bakılmalı.
    const customTagRender = (props) => {
        const { label, closable, onClose } = props;
        const truncatedLabel = label.length > 10 ? `${label.substring(0, 10)}...` : label;
        return (
            <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                {truncatedLabel}
            </Tag>
        );
    };
    */

    useEffect(() => {
        // Assume these are the endpoints to fetch platform and mission data
        if (!auto_search) {

        }
        else {
            handleSearch();
        }
    }, [auto_search]);

    const handleSearch = () => {
        setLoading(true);
        const formattedYears = [];

        selectedYears.forEach(range => {
            if (range.length === 2) {
                for (let i = range[0].year; i <= range[1].year; i++) {
                    formattedYears.push(i);
                }
            } else {
                formattedYears.push(range[0].year);
            }
        });

        const formattedCountries = selectedCountries.map(country => country.split(' - ')[0]);

        const formattedIndicators = selectedIndicators.map(indicator => indicator.split(' - ')[0]);

        AxiosOICStat.post('/data/compare', {
            countries: formattedCountries,
            indicators: formattedIndicators,
            years: formattedYears
        }).then(response => {
            const updatedData = response.data.map((element, index) => {
                element.key = index;
                return element;
            });
            console.log(updatedData);
            setSearchResults(updatedData);
            setLoading(false);
        });

    };

    const exportToExcel = () => {
        // Create mappings for country and indicator names
        const countryNameMapping = {};
        Object.values(countriesState.value).forEach(country => {
            countryNameMapping[country.c_code] = country.c_short_name_eng;
        });

        const indicatorNameMapping = {};
        Object.values(indicatorsState.value).forEach(indicator => {
            indicatorNameMapping[indicator.ind_code] = indicator.ind_name_eng;
        });

        // Create a new array for export with country names and indicator names
        const dataForExport = searchResults.map(({ key, c_code, ind_code, d_year, d_value_test, d_value_live }) => ({
            c_code,
            ind_code,
            country_name: countryNameMapping[c_code],
            indicator_name: indicatorNameMapping[ind_code],
            year: d_year,
            live_value: d_value_live,
            test_value: d_value_test
        }));

        const ws = XLSX.utils.json_to_sheet(dataForExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "PHPComparatorResults");
        XLSX.writeFile(wb, "php_comparator_results.xlsx");
    };

    useEffect(() => {
        // sort the selected values
        setSelectedCountries(selectedCountries.sort((a, b) => parseInt(a) - parseInt(b)));
        setSelectedIndicators(selectedIndicators.sort((a, b) => parseInt(a) - parseInt(b)));
        setSelectedYears(selectedYears.sort((a, b) => parseInt(a) - parseInt(b)));
    },
        [selectedCountries, selectedIndicators, selectedYears]
    );

    useEffect(() => {
        // Fetch the region mappings when the component is mounted
        const fetchRegionMappings = async () => {
            try {
                const response = await AxiosOICStat.get('/region-mappings/'); // Adjust the endpoint as needed
                setRegionMappingsData(response.data); // Assuming the response body will be the array of region mappings
            } catch (error) {
                console.error('Failed to fetch region mappings:', error);
                // Handle errors, e.g., show notification
            }
        };

        fetchRegionMappings();
    }, []);

    const fetchRegionCountries = async (regionId) => {
        try {
            const response = await AxiosOICStat.get(`/regions-countries/${regionId}`); // Adjust the endpoint as needed
            return response.data; // Assuming the response body will be the array of country codes
        } catch (error) {
            console.error('Failed to fetch region countries:', error);
            // Handle errors, e.g., show notification
        }
    };

    const regions = [
        {
            id: 1,
            label: 'Regions',
            children: <Row gutter={[8, 8]}>
                {regionMappingsData && regionMappingsData.map((region) => (
                    <Col span={6} key={region.id}>
                        <Checkbox
                            key={region.id}
                            checked={selectedRegions.includes(region.id)}
                            onChange={(e) => {
                                fetchRegionCountries(region.id).then(countries => {
                                    let updatedCountries = countries.map(fetchedCountry => {
                                        // find the country object in `countriesState` by its ID
                                        let country = Object.values(countriesState.value)
                                            .filter(stateCountry => stateCountry.c_code === fetchedCountry.country_id)[0]

                                        if (country && country.c_short_name_eng) {
                                            country = country.c_short_name_eng;
                                        } else return '';
                                        return `${fetchedCountry.country_id} - ${country}`;
                                    });

                                    updatedCountries = updatedCountries.filter(country => country !== '');

                                    const differenceCountries = []

                                    if (e.target.checked) {
                                        setCountryMap(prevMap => {
                                            const newMap = { ...prevMap };
                                            updatedCountries.forEach(country => {
                                                if (newMap[country] === undefined || newMap[country] === 0) {
                                                    newMap[country] = 1;
                                                    differenceCountries.push(country);
                                                } else {
                                                    newMap[country] += 1;
                                                }
                                            });
                                            return newMap;
                                        });
                                    } else {
                                        setCountryMap(prevMap => {
                                            const newMap = { ...prevMap };
                                            updatedCountries.forEach(country => {
                                                if (newMap[country] > 1) {
                                                    newMap[country] -= 1;
                                                } else {
                                                    newMap[country] = 0;
                                                    differenceCountries.push(country);
                                                }
                                            });
                                            return newMap;
                                        });
                                    }

                                    // handle checkbox change
                                    setSelectedRegions(e.target.checked
                                        ? [...selectedRegions, region.id]
                                        : selectedRegions.filter(selectedRegion => selectedRegion !== region.id)
                                    );

                                    setSelectedCountries(prevCountries => {
                                        if (e.target.checked) {
                                            return [...new Set([...prevCountries, ...differenceCountries])];
                                        } else {
                                            return prevCountries.filter(country => !differenceCountries.includes(country));
                                        }
                                    });

                                });
                            }}
                            value={region.id}
                        >
                            {region.region_name}
                        </Checkbox>
                    </Col>
                ))}
            </Row>
        }
    ]

    return (
        <>
            {!auto_search &&
                <Row style={{ marginBottom: '10px' }}>
                    <Collapse size="small" items={regions} bordered={false} style={{ marginBottom: "10px", width: "100%", backgroundColor: "white" }} />
                    <Row>
                        <Select
                            mode="multiple"
                            placeholder="Select Countries"
                            onChange={setSelectedCountries}
                            maxTagCount={2}
                            maxTagPlaceholder={renderTagPlaceholder}
                            style={{ minWidth: '200px', marginBottom: '5px', marginRight: '10px' }}
                            showSearch
                            value={selectedCountries}
                        >
                            {Object.values(countriesState.value)
                                .filter(country => selectedCountries.includes(String(country.c_code)))
                                .concat(Object.values(countriesState.value).filter(country => !selectedCountries.includes(String(country.c_code))))
                                .map(country => <Option key={country.c_code} value={`${country.c_code} - ${country.c_short_name_eng}`}>{`${country.c_code} - ${country.c_short_name_eng}`}</Option>)}
                        </Select>
                        <Select
                            mode="multiple"
                            placeholder="Select Indicators"
                            //defaultValue={preSelectedIndicators} 
                            onChange={setSelectedIndicators}
                            style={{ minWidth: '500px', marginBottom: '5px', marginRight: '10px' }}
                            maxTagCount={3}
                            maxTagPlaceholder={renderTagPlaceholder}
                            showSearch
                        >
                            {Object.values(indicatorsState.value)
                                .filter(indicator => selectedIndicators.includes(String(indicator.ind_code)))
                                .concat(Object.values(indicatorsState.value).filter(indicator => !selectedIndicators.includes(String(indicator.ind_code))))
                                .map(indicator => <Option key={indicator.ind_code} value={`${indicator.ind_code} - ${indicator.ind_name_eng}`}>{`${indicator.ind_code} - ${indicator.ind_name_eng}`}</Option>)}
                        </Select>

                        <Button shape="circle" value="large" type="primary" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <DatePicker
                                onlyYearPicker
                                multiple
                                range
                                sort
                                highlightToday={false}
                                placeholderText={"Please select a date"}
                                selected={selectedYears}
                                value={selectedYears}
                                onChange={setSelectedYears}
                                maxDate={new Date()}
                                minDate={new Date(1970, 0, 1)}
                                plugins={[
                                    <DatePanel />,
                                    <PresetValuesPlugin position="left" setValue={setSelectedYears} />,
                                ]}
                                render={<Icon style={{ color: 'white', width: '85%', marginTop: '2px' }} />}
                            />
                        </Button>

                        <Button type="primary" onClick={handleSearch} style={{ marginLeft: '10px', }}>Search</Button>
                    </Row>
                </Row>
            }
            <Row>
                <Col xs={24}>
                    <DataTable data={searchResults} columns={["c_name", "ind_name", "d_year", "d_value_live", "d_value_test"]} widths={['10%', '60%', '10%', '10%']} loading={loading} />
                </Col>
            </Row>

            <Button onClick={exportToExcel} type="primary" style={{ marginLeft: '10px' }}>
                Export to Excel
            </Button>
        </>
    );
};