import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: false,
        token: null,
    },
    reducers: {
        login: (state, action) => {
            if(action.payload === null){
                return;
            }
            state.isAuthenticated = true;
            state.token = action.payload;
            localStorage.setItem('token', state.token);
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.token = null;
            localStorage.removeItem('token');
            window.location.replace('/login');
        }
    },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
