import React, { useEffect, useState } from 'react';
import { FileTextOutlined, CheckCircleOutlined, LoadingOutlined, CloseCircleOutlined, DownOutlined } from '@ant-design/icons';
import CsvParser from '../components/CsvParser';
import { Steps, Button, Checkbox, Radio, Form, Card, Tree, Table, Col, Row, Divider, Tabs, Tag } from 'antd';
import AxiosOICStat from '../AxiosOICStat';
import { useMessage } from '../components/providers/MessageContext';
import { useSelector } from 'react-redux';
import { message } from 'antd/lib';

const { Step } = Steps;
const { TreeNode } = Tree;
const { TabPane } = Tabs;

export const CsvUploader = () => {
  const [step, setStep] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [csvDataToCheck, setCsvDataToCheck] = useState([]);
  const [isCsvUploaded, setIsCsvUploaded] = useState(false);
  const [columnsToCheck, setColumnsToCheck] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [scrollY, setScrollY] = useState(1000);
  const [options, setOptions] = useState({
    checkNullCells: false,
    checkNonNumberCells: true,
  });
  const [radioValue, setRadioValue] = useState('rowColumn');
  const [nullCheck, setNullCheck] = useState(false);
  const [nonNumberCheck, setNonNumberCheck] = useState(true);
  const [nullCells, setNullCells] = useState([]);
  const [nonNumberCells, setNonNumberCells] = useState([]);
  const [yearValues, setYearValues] = useState([]);
  const [uploadState, setUploadState] = useState('pending');
  const [uploadedIndsText, setUploadedIndsText] = useState("");
  const [errorReportingMethod, setErrorReportingMethod] = useState('rowColumn');
  const [groupedErrors, setGroupedErrors] = useState([]);
  const [fillWithNull, setFillWithNull] = useState(false);
  const [transformedData, setTransformedData] = useState([]);
  const { showMessage } = useMessage();
  const [checkedOnce, setCheckedOnce] = useState(false);
  const countriesState = useSelector((state) => state.countries);
  const [warningMessage, setWarningMessage] = useState(null);

  const columns = [
    { title: 'Country Code', dataIndex: 'countryCode', key: 'countryCode' },
    { title: 'Indicator Code', dataIndex: 'indicatorCode', key: 'indicatorCode' },
    { title: 'Year Value', dataIndex: 'yearValue', key: 'yearValue' },
    { title: 'Cell No', dataIndex: 'cellNo', key: 'cellNo' },
    { title: 'Value', dataIndex: 'value', key: 'value' },
  ];

  function transformDataForTable(data) {
    const dataWithoutHeader = data.slice(1);
    const years = Array.from({ length: 52 }, (_, i) => 1970 + i);
    const transformedData = [];

    dataWithoutHeader.forEach(row => {
      const [indicatorCode, countryCode, ...values] = row;
      values.forEach((value, index) => {
        if (value) {
          transformedData.push({
            countryCode,
            indicatorCode,
            yearValue: years[index],
            cellNo: `${indicatorCode}-${countryCode}-${years[index]}`,
            value: parseFloat(value),
          });
        }
      });
    });

    return transformedData;
  }

  // Function to find null cells
  const findNullCells = (csvData) => {
    return csvData.slice(1).flatMap((row, rowIndex) => {
      const [indicatorCode, countryCode, ...rowCells] = row;
      return rowCells.map((cell, columnIndex) => {
        if (cell === null || cell === '') {
          const cellRef = `${columnIndexToLetter(columnIndex + 2)}-${rowIndex + 2}`;
          return {
            countryCode,
            indicatorCode,
            yearValue: yearValues[columnIndex],
            cellNo: cellRef,
            value: 'null',
          };
        }
        return null;
      }).filter(cell => cell !== null);
    });
  };

  // Function to find non-number cells
  const findNonNumberCells = (csvData) => {
    return csvData.slice(1).flatMap((row, rowIndex) => {
      const [indicatorCode, countryCode, ...rowCells] = row;
      return rowCells.map((cell, columnIndex) => {
        if (isNaN(cell) && cell !== '') {
          const cellRef = `${columnIndexToLetter(columnIndex + 2)}-${rowIndex + 2}`;
          return {
            countryCode,
            indicatorCode,
            yearValue: yearValues[columnIndex],
            cellNo: cellRef,
            value: cell,
          };
        }
        return null;
      }).filter(cell => cell !== null);
    });
  };

  // Function to convert column index to letter (for Excel-like cell references)
  const columnIndexToLetter = (columnIndex) => {
    let columnLetter = '';
    let tempIndex = columnIndex;
    while (tempIndex >= 0) {
      columnLetter = String.fromCharCode('A'.charCodeAt(0) + (tempIndex % 26)) + columnLetter;
      tempIndex = Math.floor(tempIndex / 26) - 1;
    }
    return columnLetter;
  };

  const arrayToCSVString = (data) => {
    return data.map(row => row.join(',')).join('\n');
  };

  const handleReset = () => {
    setStep(0);
    resetStates(step, 0);
  };

  const downloadCSV = () => {
    const csvString = arrayToCSVString(csvData);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "fixed_data.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadUploadedIndsText = () => {
    if (!uploadedIndsText) return;

    const currentDate = new Date();
    const dateString = currentDate.toISOString().split('T')[0]; // Format as "YYYY-MM-DD"


    // Create a Blob from the text
    const blob = new Blob([uploadedIndsText], { type: 'text/plain;charset=utf-8' });
    // Generate a URL for the Blob
    const url = URL.createObjectURL(blob);
    // Create an anchor element and trigger download
    const link = document.createElement('a');
    link.href = url;
    link.download = `UploadedIndicators-${dateString}.txt`; // Append current date to the filename
    document.body.appendChild(link); // Append to the document
    link.click(); // Trigger click to download
    document.body.removeChild(link); // Clean up
    URL.revokeObjectURL(url); // Free up resources
  };

  const prepareDataForUpload = () => {
    // Assuming the first two columns are 'ind_code' and 'c_code', and the rest are years
    const yearColumns = csvData[0].slice(2); // Extract year columns from the first row

    return csvData.slice(1).flatMap(row => {
      const indCode = row[0];
      const cCode = row[1];

      return yearColumns.map((year, index) => ({
        c_code: parseInt(cCode),
        ind_code: parseInt(indCode),
        d_year: parseInt(year),
        d_value: parseFloat(row[index + 2]) // Offset by 2 to skip 'ind_code' and 'c_code'
      }));
    });
  };


  // Function to group errors by country
  const groupErrorsByCountry = (errors) => {
    const grouped = {};
    errors.forEach(error => {
      const { countryCode, indicatorCode, yearValue, value, cellNo } = error;
      if (!grouped[countryCode]) {
        grouped[countryCode] = {};
      }
      if (!grouped[countryCode][indicatorCode]) {
        grouped[countryCode][indicatorCode] = {};
      }
      if (!grouped[countryCode][indicatorCode][yearValue]) {
        grouped[countryCode][indicatorCode][yearValue] = [];
      }
      grouped[countryCode][indicatorCode][yearValue].push(`${value} (Cell: ${cellNo})`);
    });
    return grouped;
  };

  useEffect(() => {
    if (csvData.length > 0) {
      setYearValues(csvData[0].slice(2));
      setIsCsvUploaded(true);
    }
    const transformed = transformDataForTable(csvData);
    setTransformedData(transformed);
  }, [csvData]);

  const handleCsvData = (data) => {
    data.data = data.data.filter(row => row[0] !== '');
    setCsvData(data.data);
  };

  const handleCsvDataToCheck = (data) => {
    const rows = data.data ? data.data : data;
    const headers = rows[0]; // Assume the first row is the header with years
    const rowData = rows.slice(1); // Remaining rows contain data
  
    const generatedColumns = [
      { title: 'Problem Description', dataIndex: 'problemDescription', key: 'problemDescription' },
    ];
    setColumnsToCheck(generatedColumns);
  
    const columnIndexToLetter = (index) => {
      let letter = '';
      let tempIndex = index;
      while (tempIndex >= 0) {
        letter = String.fromCharCode('A'.charCodeAt(0) + (tempIndex % 26)) + letter;
        tempIndex = Math.floor(tempIndex / 26) - 1;
      }
      return letter;
    };
  
    const generatedData = [];
    let onlyZeroCountryCode = true;
  
    // Check for missing column names in the headers and non-empty cells below them
    headers.forEach((header, headerIndex) => {
      if (!header) {
        rowData.forEach((row, rowIndex) => {
          const cellValue = row[headerIndex];
          if (cellValue) {
            const cellRef = `${columnIndexToLetter(headerIndex)}${rowIndex + 2}`;
            generatedData.push({
              key: `boundary-${headerIndex}-${rowIndex}`,
              problemDescription: `Table Boundary Error: Non-empty cell at ${cellRef} with value "${cellValue}" in a column with no header`,
            });
          }
        });
      }
    });
  
    // Regex to match invalid characters, allowing numbers, dots, and negative signs
    const invalidCharRegex = /[^0-9.-]/;
  
    // Process data to find cells with invalid characters
    rowData.forEach((row, rowIndex) => {
      row.slice(2).forEach((cell, cellIndex) => {
        if (cell && invalidCharRegex.test(cell)) {
          const cellRef = `${columnIndexToLetter(cellIndex + 2)}${rowIndex + 2}`;
          generatedData.push({
            key: `${rowIndex}-${cellIndex}`,
            problemDescription: `Invalid data in ${cellRef} cell: ${cell}`,
          });
        }
      });
    });
  
    // Define the list of expected country codes
    const expectedCountryCodes = new Set([
      1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 
      41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58
    ]);
  
    // Track countries that have data for each indicator
    const indicatorCountryMap = {};
    const countryCodesInData = new Set();
  
    rowData.forEach((row) => {
      const indicatorCode = row[0];
      const countryCode = row[1];
      console.log(countryCode)
  
      // Track all country codes present in the data
      if (countryCode !== null && countryCode !== undefined) {
        countryCodesInData.add(countryCode);
      }
  
      // If there are country codes other than 0, set the flag to false
      
      if (countryCode !== 0 && countryCode !== "0" && countryCode !== null && countryCode !== undefined && countryCode !== '') {
        console.log(countryCode);
        onlyZeroCountryCode = false;
      }
      
  
      // Ensure indicatorCountryMap has a set for each unique indicator
      if (!indicatorCountryMap[indicatorCode]) {
        indicatorCountryMap[indicatorCode] = new Set();
      }
  
      // Add the country code to the set for the respective indicator
      if (countryCode !== null && countryCode !== undefined) {
        indicatorCountryMap[indicatorCode].add(countryCode);
      }
    });
    console.log(onlyZeroCountryCode)
    // Only perform the missing countries check if there are other country codes besides 0
    
    if (!onlyZeroCountryCode) {
      // Identify missing countries for each indicator
      Object.entries(indicatorCountryMap).forEach(([indicator, countriesSet]) => {
        const countriesAsNumbers = new Set([...countriesSet].map(Number));
        const missingCountries = [...expectedCountryCodes].filter(code => !countriesAsNumbers.has(code));
        if (missingCountries.length > 0 && indicator !== "") {
          generatedData.push({
            key: `indicator-${indicator}`,
            problemDescription: `Indicator ${indicator} is missing data for countries: ${missingCountries.join(', ')}`,
          });
        }
      });
    }
  
    // Detect duplicate rows, ignoring empty rows
    const uniqueRows = new Set();
    rowData.forEach((row, rowIndex) => {
      const isEmptyRow = row.every(cell => cell === '' || cell === null);
  
      if (!isEmptyRow) {
        const rowString = JSON.stringify(row);
        if (uniqueRows.has(rowString)) {
          generatedData.push({
            key: `duplicate-${rowIndex}`,
            problemDescription: `Duplicate row detected at row ${rowIndex + 2}`,
          });
        } else {
          uniqueRows.add(rowString);
        }
      }
    });
  
    setTableData(generatedData);
  
    if (generatedData.length === 0) {
      setCheckedOnce(true);
      message.success("Data format is correct!");
      setTimeout(() => setCheckedOnce(false), 5000);
    } else {
      message.warning("There are some issues detected in the data. Please review them.");
    }
  };
  
  const resetStates = (currentStep, nextStep) => {
    while (currentStep >= nextStep) {
      resetStep(currentStep);
      currentStep--;
    }
  };

  const proceedToCheck = () => {
    setStep(2);
    handleCsvDataToCheck({ data: csvData });
    if (tableData.length > 0) {
      setWarningMessage("There are some issues detected in the data. Please review them.");
    } else {
      setWarningMessage(null);
    }
  }

  const letterToColumnIndex = (letter) => {
    let column = 0;
    let length = letter.length;
    for (let i = 0; i < length; i++) {
      column += (letter.charCodeAt(i) - 64) * Math.pow(26, length - i - 1);
    }
    return column;
  };

  const handleReportingMethodChange = e => {
    setErrorReportingMethod(e.target.value);
    setRadioValue(e.target.value);
  };

  const getTitleByDepth = (key, depth) => {
    switch (depth) {
      case 0:
        return `Country Code: ${key}`;
      case 1:
        return `Indicator Code: ${key}`;
      case 2:
        return `Year: ${key}`;
      default:
        return key;
    }
  };

  const proceedToNextStep = () => {
    if (fillWithNull) {
      // Apply fill with zero functionality
      applyFillErrorsWithNull();
    }
    performBatchUpload();
    setStep(3); // Move to the next step
  };

  const applyFillErrorsWithNull = () => {
    let dataToFill = [];

    if (options.checkNonNumberCells) {
      dataToFill = [...nonNumberCells];
    }

    const updatedCsvData = csvData.map(row => [...row]); // Clone csvData to avoid direct mutation
    dataToFill.forEach((error) => {
      const [cellRef, rowIndex] = error.cellNo.split('-');
      const columnIndex = letterToColumnIndex(cellRef);
      updatedCsvData[parseInt(rowIndex) - 1][columnIndex - 1] = null; // Adjusting index as array is 0-based
    });

    setCsvData(updatedCsvData); // Update state with modified data
  };

  const performBatchUpload = async () => {
    setUploadState('uploading');
    const dataToUpload = prepareDataForUpload();
    console.log("performBatchUpload: ", dataToUpload);

    try {
      //const response = await AxiosOICStat.post('/data/batch-upload', dataToUpload);
      const response = await AxiosOICStat.post('/data/bulk_upsert', dataToUpload);
      console.log('Upload successful:', response.data);
      // Handle success (e.g., show success message or proceed to the next step)
      setUploadedIndsText(response.data);
      setUploadState('success');
      showMessage({ type: 'success', content: 'CSV Upload is successful', duration: 5 });
    } catch (error) {
      console.error('Upload failed:', error);
      // Handle different types of errors
      if (error.message.includes('Network Error') || error.code === 'ECONNREFUSED') {
        showMessage({
          type: 'error',
          content: (
            <div style={{ color: 'red', padding: '10px', fontFamily: 'Arial' }}>
              <p style={{ fontSize: '16px', fontWeight: 'bold' }}>Upload failed: Unable to connect to the server.</p>
              <p>Please check your internet connection or contact support if the problem persists.</p>
            </div>
          ),
          duration: 5
        });
      } else {
        showMessage({
          type: 'error',
          content: (
            <div style={{ color: 'red', fontFamily: 'Arial', padding: '10px' }}>
              <p style={{ fontWeight: 'bold' }}>CSV Upload failed due to an unexpected issue.</p>
              <p>Please contact the administrator.</p>
              <p><strong>Error Code:</strong> {error.code}</p>
              <p><strong>Message:</strong> {error.message}</p>
            </div>
          ),
          duration: 5
        });
      }
      setUploadState('error');
    }
  };

  const prepareDataSource = [...nonNumberCells, ...nullCells].map((error, index) => ({
    key: index,
    ...error,
  }));

  const renderTreeNodes = (data, path = '') => {
    return Object.keys(data).map(key => {
      const currentPath = path ? `${path}-${key}` : key;

      if (typeof data[key] === 'object') {
        return (
          <TreeNode title={getTitleByDepth(key, currentPath.split('-').length - 1)} key={currentPath}>
            {renderTreeNodes(data[key], currentPath)}
          </TreeNode>
        );
      }

      // Displaying only the value (without the path) for leaf nodes
      return <TreeNode title={data[key]} key={currentPath} />;
    });
  };

  const resetStep = (step) => {
    if (step === 2) {
      setUploadState('pending');
      setUploadedIndsText('');
    }
    if (step === 1) {
      setFillWithNull(false);
      setGroupedErrors([]);
      setNullCells([]);
      setNonNumberCells([]);
    }
    if (step === 0) {
      setCsvData([]);
      setIsCsvUploaded(false);
    }
  }

  const handleOptionsChange = (e) => {
    setOptions({ ...options, [e.target.name]: e.target.checked });
  };

  //const proceedToCheck = () => {
  //  let nullCellsFound = [];
  //  let nonNumberCellsFound = [];
  //  if (options.checkNullCells) {
  //    nullCellsFound = findNullCells(csvData);
  //    setNullCells(nullCellsFound);
  //  }
  //  if (options.checkNonNumberCells) {
  //    nonNumberCellsFound = findNonNumberCells(csvData);
  //    setNonNumberCells(nonNumberCellsFound);
  //  }
  //  if (errorReportingMethod === 'groupByCountry') {
  //    const grouped = groupErrorsByCountry([...nullCellsFound, ...nonNumberCellsFound]);
  //    setGroupedErrors(grouped);
  //  }
  //  setStep(2);
  //};

  //<Step title="Options" style={{ cursor: step > 1 ? 'pointer' : 'default' }} onClick={
  //  () => {
  //    if (step > 1) {
  //      resetStates(step, 1);
  //      setStep(1);
  //    }
  //  }
  //} />
  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Upload" key="1">
          <div>
            <h1 style={{ textAlign: 'center' }}>
              <FileTextOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
              CSV Uploader
            </h1>

            <Steps current={step} style={{ marginBottom: '30px' }}>
              <Step title="Upload CSV" style={{ cursor: step > 0 ? 'pointer' : 'default' }} onClick={
                () => {
                  if (step > 0) {
                    resetStates(step, 0);
                    setStep(0);
                  }
                }
              } />
              
              <Step title="Check Data" style={{ cursor: step > 2 ? 'pointer' : 'default' }} onClick={
                () => { 
                  if (step > 2) {
                    resetStates(step, 2);
                    setStep(2);
                  }
                }
              } />
              <Step title="Upload Completed" />
            </Steps>

            {step === 0 && (
              <>
                <CsvParser onCsvData={handleCsvData} />
                {isCsvUploaded && (
                  <Button
                    type="primary"
                    onClick={proceedToCheck}
                    style={{ marginTop: '16px', display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '200px' }}
                    disabled={csvData.length === 0}
                  >
                    Proceed to Next Step
                  </Button>
                )}
              </>
            )}

            {step === 1 && (
              <Card style={{ padding: '20px', textAlign: 'center' }}>
                <Row>
                  <Col span={11}>
                    <header style={{ fontWeight: 'bold', textDecoration: 'underline', textUnderlineOffset: '2px', fontSize: '16px' }}>Check Options</header>
                    <Row gutter={16} style={{ margin: '2%' }}>
                      <Col span={8}></Col>
                      <Checkbox name="checkNonNumberCells" onChange={(e) => {
                        setNonNumberCheck(!nonNumberCheck);
                        return handleOptionsChange(e);
                      }}
                        defaultChecked={nonNumberCheck}>
                        Check for non-number cells
                      </Checkbox>
                    </Row>
                    <Row gutter={16} style={{ margin: '2%' }}>
                      <Col span={8}></Col>
                      <Checkbox
                        name="checkNullCells"
                        onChange={(e) => {
                          setNullCheck(!nullCheck);
                          return handleOptionsChange(e)
                        }}
                        defaultChecked={nullCheck}
                      >
                        Check for null cells
                      </Checkbox>
                    </Row>
                  </Col>
                  <Col span={2}><Divider type="vertical" style={{ height: "100%" }} /></Col>
                  <Col span={11}>
                    <header style={{ fontWeight: 'bold', textDecoration: 'underline', textUnderlineOffset: '2px', fontSize: '16px' }}>Data Display Type</header>
                    <Radio.Group
                      onChange={handleReportingMethodChange}
                      defaultValue={radioValue}
                      style={{ width: '100%' }}
                    >
                      <Row gutter={16} style={{ margin: '2%' }}>
                        <Col span={4}></Col>
                        <Radio value="rowColumn">Give errors by row and column numbers (Table View)</Radio>
                      </Row>
                      <Row gutter={16} style={{ margin: '2%' }}>
                        <Col span={4}></Col>
                        <Radio value="groupByCountry">Give errors grouped by countries (Tree View)</Radio>
                      </Row>
                    </Radio.Group>
                  </Col>
                </Row>

                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Button type="primary" onClick={proceedToCheck}>
                    Proceed to Next Step
                  </Button>
                </div>
              </Card>
            )
            }

            {/* Step 3: Display Errors and Allow Fixing */}
            {
              step === 2 && (
                <Card>
                  <h2>Check Results</h2>
                  {errorReportingMethod === 'rowColumn' && (
                    <Card>
                      <Table dataSource={transformedData} columns={columns} />
                    </Card>
                  )}
                  {errorReportingMethod === 'groupByCountry' && (
                    <Tree
                      showIcon
                      height={233}
                      switcherIcon={<DownOutlined />}
                    >
                      {renderTreeNodes(groupedErrors)}
                    </Tree>
                  )}
                  <Checkbox
                    style={{ marginTop: '20px' }}
                    onChange={(e) => setFillWithNull(e.target.checked)}
                  >
                    Fill erroneous cells with null values
                  </Checkbox>
                  <div style={{ textAlign: 'center', marginTop: '16px' }}>
                    <Button type="primary" disabled={tableData.length !== 0} onClick={proceedToNextStep}>
                      Next Step
                    </Button>
                  </div>
                </Card>
              )
            }

            {/* Step 4: Display Successful Upload */}
            {
              step === 3 && (
                <Card style={{ padding: '20px', textAlign: 'center' }}>
                  <h2>
                    {
                      uploadState === 'uploading' && <LoadingOutlined style={{ color: 'blue', marginRight: '8px' }} />
                    }
                    {
                      uploadState === 'error' && <CloseCircleOutlined style={{ color: 'red', marginRight: '8px' }} />
                    }
                    {
                      uploadState === 'success' && <CheckCircleOutlined style={{ color: 'green', marginRight: '8px' }} />
                    }

                    {
                      uploadState === 'uploading' && 'Uploading data...'
                    }
                    {
                      uploadState === 'error' && 'Upload failed'
                    }
                    {
                      uploadState === 'success' && 'Upload successful'
                    }
                  </h2>
                  <Col style={{ textAlign: 'center' }}>
                    {
                      uploadedIndsText && <Col span={24}><Button type="primary" onClick={downloadUploadedIndsText} style={{ margin: '10px' }}>
                        Download Uploaded Indicators List
                      </Button></Col>
                    }
                    <Button type="primary" onClick={downloadCSV} style={{ margin: '10px' }}>
                      Download Fixed CSV
                    </Button>
                  </Col>
                </Card>
              )
            }

            {/* Reset Button */}
            {
              step > 0 && (
                <div style={{ textAlign: 'center', marginTop: '2%' }}>
                  <Button type="primary" onClick={handleReset}>
                    Reset and Upload Again
                  </Button>
                </div>
              )
            }
          </div >
        </TabPane>

        <TabPane tab="Check" key="2">
          <>
            <h1 style={{ textAlign: 'center' }}>
              <FileTextOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
              CSV Checker
            </h1>
            <CsvParser isCheck={true} onCsvData={handleCsvDataToCheck}/>
             {tableData.length > 0 ? (
                <Table
                  columns={columnsToCheck}
                  dataSource={tableData}
                  pagination={{ pageSize: 20 }}
                  scroll={{ x: '100%' }}
                  bordered
                />
              ) : (
                checkedOnce && (
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    <Tag color="green">There are no errors in the CSV file.</Tag>
                  </div>
                )
              )}
          </>
        </TabPane>

      </Tabs>
    </div>
  );
};
