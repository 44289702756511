import React, { useRef, useState, useEffect } from 'react';
import { Button, Card, List, Switch, Typography, Table } from 'antd';
import AxiosSSM from '../../AxiosOICStat';
import './style.scss';
import LogLevelManager from './LogLevelManager';

function logRowSytling(record) {
    try {
        if (record?.level == null) {
            console.error("record.level is null", record);
            return "";
        }
        if (record.level.toLowerCase().includes("err")) {
            return "log-viewer-row-error";
        }
        else if (record.level.toLowerCase().includes("warn")) {
            return "log-viewer-row-warn";
        }
        else if (record.level.toLowerCase().includes("info")) {
            return "log-viewer-row-info";
        }
        else if (record.level.toLowerCase().includes("debug")) {
            return "log-viewer-row-debug";
        }
    } catch (error) {
        console.error('Error styling log row:', error);
    }
    return "";
}

export const LogViewer = () => {
    const [logs, setLogs] = useState('');
    const [skip, setSkip] = useState(0);
    const [lastLines, setLastLines] = useState(100);
    const [autoRefresh, setAutoRefresh] = useState(true);
    const tableRef = useRef(null); // Create a ref for the table
    const [flagAutoScroll, setFlagAutoScroll] = useState(true);
    useEffect(() => {
        if (!flagAutoScroll)
            return;
        if (logs.length == 0)
            return;
        if (tableRef.current) {
            tableRef.current.scrollTop = tableRef.current.scrollHeight;
        }
        setFlagAutoScroll(false);
    }, [autoRefresh, logs]);

    const columns = [
        { title: 'date', dataIndex: 'date', key: 'date' },
        { title: 'level', dataIndex: 'level', key: 'level' },
        { title: 'name', dataIndex: 'name', key: 'name' },
        { title: 'log', dataIndex: 'log', key: 'log', render: text => <pre>{text}</pre>, width: '100%' },
    ];
    const fetchLogs = async () => {
        try {
            const response = await AxiosSSM.get('/logs/log_viewer', { params: { skip, last_lines: lastLines } });
            let valDate, valLevel, valName, valLog;
            let splittedLogLine;
            const formattedLogs = [];
            response.data.logs.forEach((logLine, index) => {
                splittedLogLine = logLine.split(' - ');
                if (splittedLogLine.length > 3) {
                    valDate = splittedLogLine[0];
                    valLevel = splittedLogLine[1];
                    valName = splittedLogLine[2];
                    if (splittedLogLine.length == 4)
                        valLog = splittedLogLine[3];
                    else
                        valLog = splittedLogLine.slice(3).join(" - ");
                }
                else {
                    valLog = logLine;
                }
                formattedLogs.push({
                    key: skip + index, // Unique key for each row
                    date: valDate,
                    level: valLevel,
                    name: valName,
                    log: valLog
                });
            });
            setLogs(formattedLogs);
        } catch (error) {
            console.error('Error fetching logs:', error);
        }
    };

    useEffect(() => {
        fetchLogs();
    }, [skip, lastLines]);

    useEffect(() => {
        let interval = null;
        if (autoRefresh) {
            interval = setInterval(fetchLogs, 1000); // Refresh logs every second
        }
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [autoRefresh, skip, lastLines, fetchLogs]);

    const handlePrevious = () => {
        setSkip((prevSkip) => Math.max(prevSkip - lastLines, 0));
    };

    const handleNext = () => {
        setSkip((prevSkip) => prevSkip + lastLines);
    };

    const toggleAutoRefresh = (checked) => {
        setAutoRefresh(checked);
    };

    return (
        <div >
            <Card title="Log Viewer" className='log_viewer' extra={<Switch checked={autoRefresh} onChange={setAutoRefresh} />}>
                <div className='log_viewer_table' ref={tableRef}>
                    <Table columns={columns} dataSource={logs} pagination={false} size='small' rowClassName={logRowSytling} />
                </div>
                <div style={{ marginTop: 16 }}>
                    <Button onClick={handlePrevious} disabled={skip === 0}>Previous</Button>
                    <Button onClick={handleNext} style={{ marginLeft: 8 }}>Next</Button>
                </div>
            </Card>
        </div>
    );
};