import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Select, Card } from 'antd';
import AxiosOICStat from '../AxiosOICStat';
import DataCard from '../components/DataCard';
import { DataChart } from '../components/charts/DataChart';
import { convertDataForLineChart, convertDataForIndicatorsCount } from '../components/charts/DataUtils';
import { CustomRangePicker } from '../components/CustomRangePicker';
import dayjs from 'dayjs';

const { Header, Content } = Layout;

const rowStyle = {
  marginBottom: '16px', // Add bottom margin to each row
};


export function Home() {
  const [dataMetadata, setDataMetadata] = useState([]);
  const [dateRange, setDateRange] = useState([dayjs().add(-180, 'd'), dayjs()]);
  const [frequency, setFrequency] = useState('monthly');
  const [dataCounts, setDataCounts] = useState([]);
  const [indicatorsCount, setIndicatorsCount] = useState([]);
  const [type, setType] = useState('month');

  const fetchData = async () => {
    try {
      const startDate = dateRange[0].format('YYYY-MM-DD');
      const endDate = dateRange[1].format('YYYY-MM-DD');

      const response = await AxiosOICStat.get(`metadata/data-points`, {
        params: { start_date: startDate, end_date: endDate, return_frequency: frequency }
      });

      if (response.data) {
        setDataCounts(convertDataForLineChart(response.data));
        setIndicatorsCount(convertDataForIndicatorsCount(response.data));
      } else {
        setDataCounts([]);
        setIndicatorsCount([]);
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Fetch the latest data metadata and set it in the state
    const fetchMetadata = async () => {
      try {
        const { data } = await AxiosOICStat.get('/metadata/latest'); // Update this URL to your API endpoint
        console.log(data);
        const date = new Date(data.date_calculated);
        date.setHours(date.getHours() + 3);
        // Update the date_calculated field in the fetched data
        setDataMetadata({
          ...data,
          date_calculated: date.toISOString(), // Use toISOString or any preferred format
        });
      } catch (error) {
        console.error('Failed to fetch data metadata', error);
      }
    };

    fetchMetadata();
  }, []);

  const isResultEmpty = (dataCounts[0] && dataCounts[0].data && dataCounts[0].data.length === 0);
  const isDataAvailable = !isResultEmpty && dataCounts.length > 0 && indicatorsCount.length > 0;

  return (
    <Layout>
      <Header style={{ background: '#fff', textAlign: 'center' }}>OIC Stat</Header>
      <Content style={{ padding: '20px' }}>
        <Row gutter={16} style={rowStyle}>
          <Col span={8}>
            <DataCard title="Number of Data Records" value={dataMetadata.data_counts} />
          </Col>
          <Col span={8}>
            <DataCard title="Number of Indicators" value={dataMetadata.indicators_count} note={`Hidden Indicator(s) : ${dataMetadata.private_indicators_count} Visible Indicator(s) : ${dataMetadata.public_indicators_count}`} />
          </Col>
          <Col span={8}>
            <DataCard title="Number of Countries" value={dataMetadata.number_of_countries} />
          </Col>
        </Row>

        <Row gutter={16} style={rowStyle}>

          <Col span={8}>
            <DataCard title="Number of Categories" value={dataMetadata.number_of_categories} />
          </Col>
          <Col span={8}>
            <DataCard title="Aggregates Count" value={dataMetadata.aggregates_count} />
          </Col>
          <Col span={8}>
            <DataCard title="Last Update Date" value={dataMetadata.date_calculated} isValueDate={true} />
          </Col>
        </Row>


        <Row gutter={16}>
          <CustomRangePicker dateRange={dateRange} setDateRange={setDateRange} type={type} setType={setType} setFrequency={setFrequency} frequency={frequency} fetchData={fetchData} />
        </Row>
        <Row gutter={16} style={{ marginTop: '20px' }}>
          {isDataAvailable ? (
            <>
              <Col span={12}>
                <Card title="Data Counts" style={{ textAlign: "center" }}>
                  <div style={{ height: "400px" }}>
                    <DataChart data={dataCounts} axisNames={["Time", "Data Counts"]} type="data" />
                  </div>
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Indicators Count" style={{ textAlign: "center" }}>
                  <div style={{ height: "400px" }}>
                    <DataChart data={indicatorsCount} axisNames={["Time", "Indicator Counts"]} type="indicator" />
                  </div>
                </Card>
              </Col>
            </>
          ) : isResultEmpty ? (
            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p>No data available for the selected date range and frequency.</p>
            </div>
          )
            : (
              <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p>Select a date range and frequency, then click 'Fetch Data'.</p>
              </div>
            )}

        </Row>
      </Content>
    </Layout>
  );
}
