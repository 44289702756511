import React, { useState, useEffect } from "react";
import { Tabs, Form, Input, Button, Switch, message, Table, Modal, Select, Popconfirm} from "antd";
import AxiosOICStat from "AxiosOICStat";
import { useSelector } from "react-redux";
import { GenerateSyb } from "./GenerateSyb";
import DatePicker from 'react-multi-date-picker';
import DatePanel from "react-multi-date-picker/plugins/date_panel"

const { TabPane } = Tabs;
const { Option } = Select;

export function Syb() {
  const [formConfig] = Form.useForm();
  const [formIndicator] = Form.useForm();
  const [configurations, setConfigurations] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [isConfigModalVisible, setConfigModalVisible] = useState(false);
  const [isIndicatorModalVisible, setIndicatorModalVisible] = useState(false);
  const [selectedYears, setSelectedYears] = useState([]);

  const indicatorsState = useSelector((state) => state.indicators);

  // Fetch existing configurations
  const fetchConfigurations = async () => {
    try {
      const response = await AxiosOICStat.get("/syb/syb_configs/");
      setConfigurations(response.data);
    } catch (error) {
      //message.error("Failed to fetch configurations: " + error.message);
    }
  };

  // Fetch existing indicators
  const fetchIndicators = async () => {
    try {
      const response = await AxiosOICStat.get("/syb/syb_indicators/");
      setIndicators(response.data);
    } catch (error) {
      //message.error("Failed to fetch indicators: " + error.message);
    }
  };

  useEffect(() => {
    fetchConfigurations();
    fetchIndicators();
  }, []);

  const handleAddConfig = async (values) => {
    if (Array.isArray(values.years)) {
      values.years = values.years.map((year) => year.toString()).join(",");
    }
    try {
      await AxiosOICStat.post("/syb/syb_configs/", values);
      message.success("Configuration added successfully!");
      formConfig.resetFields();
      fetchConfigurations(); // Refresh configurations
      setConfigModalVisible(false);
    } catch (error) {
      message.error("Failed to add configuration: " + (error.response?.data?.detail || error.message));
    }
  };

  const handleAddIndicator = async (values) => {
    try {
      await AxiosOICStat.post("/syb/syb_indicators/", values);
      message.success("Indicator added successfully!");
      formIndicator.resetFields();
      fetchIndicators(); // Refresh indicators
      setIndicatorModalVisible(false);
    } catch (error) {
      message.error("Failed to add indicator: " + (error.response?.data?.detail || error.message));
    }
  };

  const handleDeleteConfig = async (config_id) => {
    try {
      await AxiosOICStat.delete(`/syb/syb_configs/${config_id}`);
      message.success("Configuration deleted successfully!");
      fetchConfigurations(); // Refresh configurations
    } catch (error) {
      message.error("Failed to delete configuration: " + error.message);
    }
  };

  const handleDeleteIndicator = async (ind_code, config_id) => {
    try {
      await AxiosOICStat.delete(`/syb/syb_indicators/`, {
        data: { ind_code, config_id },
      });
      message.success("Indicator deleted successfully!");
      fetchIndicators(); // Refresh indicators
    } catch (error) {
      message.error("Failed to delete indicator: " + error.message);
    }
  };

  const enrichedIndicators = indicators.map((indicator) => {
    const config = configurations.find((c) => c.config_id === indicator.config_id);
    const indicatorDetails = indicatorsState.value[indicator.ind_code] || {};
  
    return {
      ...indicator,
      config_name: config?.config_name || "Unknown Configuration", // Add config name
      indicator_name: indicatorDetails.ind_name_eng || "Unknown Indicator", // Add indicator name
    };
  });

  const configColumns = [
    { title: "ID", dataIndex: "config_id", key: "config_id" },
    { title: "Name", dataIndex: "config_name", key: "config_name" },
    { title: "OIC Calculation Needed", dataIndex: "is_oic_calc_needed", key: "is_oic_calc_needed", render: (val) => (val ? "Yes" : "No") },
    { title: "World Calculation Needed", dataIndex: "is_world_calc_needed", key: "is_world_calc_needed", render: (val) => (val ? "Yes" : "No") },
    { title: "Selected Years", dataIndex: "years", key: "years"},
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure you want to delete this configuration?"
          onConfirm={() => handleDeleteConfig(record.config_id)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      ),
    },
  ];

  const indicatorColumns = [
    { title: "Indicator Code", dataIndex: "ind_code", key: "ind_code" },
    { title: "Indicator Name", dataIndex: "indicator_name", key: "indicator_name" },
    { title: "Configuration ID", dataIndex: "config_id", key: "config_id" },
    { title: "Configuration Name", dataIndex: "config_name", key: "config_name" },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure you want to delete this indicator?"
          onConfirm={() => handleDeleteIndicator(record.ind_code, record.config_id)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <h1>Statistical Yearbook</h1>
      <Tabs defaultActiveKey="1">
        {/* Tab for displaying configurations */}
        <TabPane tab="Configurations" key="1">
          <Button style={{ marginBottom: 10 }} type="primary" onClick={() => setConfigModalVisible(true)}>
            Add Configuration
          </Button>
          <Table
            dataSource={configurations}
            columns={configColumns}
            rowKey="config_id"
          />
        </TabPane>

        {/* Tab for displaying indicators */}
        <TabPane tab="Indicators" key="2">
          <Button style={{ marginBottom: 10 }} type="primary" onClick={() => setIndicatorModalVisible(true)}>
            Add Indicator
          </Button>
          <Table
            dataSource={enrichedIndicators}
            columns={indicatorColumns}
            rowKey={(record) => `${record.ind_code}-${record.config_id}`}
          />
        </TabPane>

        {/* Placeholder for the "Generate Report" tab */}
        <TabPane tab="Generate Report" key="3">
          <GenerateSyb />
        </TabPane>
      </Tabs>

      {/* Modal for adding configuration */}
      <Modal
        title="Add Configuration"
        visible={isConfigModalVisible}
        onCancel={() => setConfigModalVisible(false)}
        footer={null}
      >
        <Form
          form={formConfig}
          layout="vertical"
          onFinish={handleAddConfig}
        >
          <Form.Item
            name="config_name"
            label="Configuration Name"
            rules={[{ required: true, message: "Please enter a configuration name" }]}
          >
            <Input placeholder="Enter configuration name" />
          </Form.Item>
          <Form.Item
            name="is_oic_calc_needed"
            label="Is OIC Calculation Needed"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="is_world_calc_needed"
            label="Is World Calculation Needed"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
          name="years"
          label="Select Years"
          rules={[{ required: true, message: "Please select at least one year" }]}
        >
          <DatePicker
            onlyYearPicker
            multiple
            sort
            highlightToday={false}
            placeholder="Please select years"
            value={selectedYears}
            onChange={setSelectedYears}
            maxDate={new Date()}
            minDate={new Date(1970, 0, 1)}
            plugins={[
              <DatePanel />,
            ]}
          />
        </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Configuration
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal for adding indicator */}
      <Modal
        title="Add Indicator"
        visible={isIndicatorModalVisible}
        onCancel={() => setIndicatorModalVisible(false)}
        footer={null}
      >
        <Form
          form={formIndicator}
          layout="vertical"
          onFinish={handleAddIndicator}
        >
          <Form.Item
            name="ind_code"
            label="Indicator Code"
            rules={[{ required: true, message: "Please select an indicator code" }]}
          >
            <Select
              showSearch
              placeholder="Select Indicator Code"
              optionFilterProp="label"
              filterOption={(input, option) =>
                option?.label?.toLowerCase().includes(input.toLowerCase())
              }
            >
              {Object.values(indicatorsState.value || {}).map((indicator) => (
                <Option
                  key={indicator.ind_code}
                  value={indicator.ind_code}
                  label={`${indicator.ind_code} - ${indicator.ind_name_eng}`}
                >
                  {indicator.ind_code} - {indicator.ind_name_eng}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="config_id"
            label="Configuration ID"
            rules={[{ required: true, message: "Please select a configuration ID" }]}
          >
            <Select placeholder="Select Configuration ID">
              {configurations.map((config) => (
                <Option key={config.config_id} value={config.config_id}>
                  {config.config_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Indicator
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
