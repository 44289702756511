import React, { useState, useEffect } from 'react';
import { Table, Button, message, Modal, Form, Input, Select } from 'antd';
import AxiosOICStat from '../AxiosOICStat';
import { useSelector } from 'react-redux';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};


const formatMapping = (mapping, indicatorsState) => {
    mapping.date_modified = formatDate(mapping.date_modified);
    if (mapping.last_retrieved_datetime) {
        mapping.last_retrieved_datetime = formatDate(mapping.last_retrieved_datetime);
    }
    mapping.indicator_name = indicatorsState.value[mapping.indicator_id].ind_name_eng;
    mapping.key = mapping.id;
    return mapping;
};

const formatMappings = (mapping, indicatorsState) => {
    mapping.map((mapping) => {
        return formatMapping(mapping, indicatorsState);
    });
    return mapping;
};



const EditMappingModal = ({ visible, onCancel, onEdit, record, isAddMode, indicatorsState }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (isAddMode) {
            form.resetFields(); // Reset fields when adding a new mapping
        } else {
            form.setFieldsValue(record); // Set form fields with record data when editing
        }
    }, [record, isAddMode, form]);

    useEffect(() => {
        console.log(indicatorsState);
        console.log(record);
    }, [indicatorsState]);

    const handleSubmit = async (values) => {
        try {
            if (!isAddMode) {
                // delete date_modified from values
                delete values.date_modified;
                const response = await AxiosOICStat.put(`/indicators/mappings/${record.indicator_id}`, values);
                message.success('Mapping updated successfully');

                onEdit(formatMapping(response.data, indicatorsState));
            } else {
                const response = await AxiosOICStat.post('/indicators/mappings', values);
                message.success('Mapping added successfully');
                console.log(response.data);
                onEdit(formatMapping(response.data, indicatorsState));
            }
        } catch (error) {
            console.error('Error updating mapping:', error);
            message.error('Error updating mapping');
        }
    };

    return (
        <Modal
            visible={visible}
            title="Edit Mapping"
            onCancel={onCancel}
            onOk={() => form.submit()}
            destroyOnClose={true}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={record}
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="Indicator ID"
                    name="indicator_id"
                    rules={[{ required: true, message: 'Please select an indicator!' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select an indicator"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={!isAddMode}
                    >
                        {Object.values(indicatorsState.value).map((ind) => (
                            <Select.Option key={ind.ind_code} value={ind.ind_code}>
                                {ind.ind_code} - {ind.ind_name_eng}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="WBG Corresponding Code"
                    name="wbg_corresponding_code"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="WBG Corresponding Description"
                    name="wbg_corresponding_desc"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="SDG Corresponding Code"
                    name="sdg_corresponding_code"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="SDG Corresponding Description"
                    name="sdg_corresponding_desc"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Multiplication Factor"
                    name="multiplication_factor"
                >
                    <Input />
                </Form.Item>
                {
                    !isAddMode && (
                        <>
                            <Form.Item
                                label="Date Modified"
                                name="date_modified"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </>
                    )
                }

            </Form>
        </Modal>
    );
};

export const IndicatorMapping = () => {
    const [mappingData, setMappingData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isAddMode, setIsAddMode] = useState(false);
    const indicators = useSelector((state) => state.indicators);

    useEffect(() => {
        console.log(indicators);
    }, [indicators]);

    useEffect(() => {
        const fetchMappingData = async () => {
            setLoading(true);
            try {
                const response = await AxiosOICStat.get('/indicators/mappings');
                response.data = formatMappings(response.data, indicators);
                setMappingData(response.data);
            } catch (error) {
                message.error('Error fetching mapping data');
            } finally {
                setLoading(false);
            }
        };

        fetchMappingData();
    }, []);

    const handleEdit = (record) => {
        setSelectedRecord(record);
        setEditModalVisible(true);
        setIsAddMode(false); // Setting add mode to false
    };

    const handleDelete = (indCode) => {
        console.log(indCode);
        Modal.confirm({
            title: 'Are you sure you want to delete this indicator?',
            onOk: () => {
                AxiosOICStat.delete(`indicators/mappings/${indCode}`)
                    .then(() => {
                        message.success('Indicator deleted successfully');
                        setMappingData(prevIndicators => prevIndicators.filter(ind => ind.indicator_id !== indCode));
                    })
                    .catch(err => {
                        message.error('Error deleting indicator');
                    });
            }
        });
    };

    const handleEditModalCancel = () => {
        setEditModalVisible(false);
    };

    const handleUpdateMapping = (updatedRecord) => {
        console.log(updatedRecord);
        if (isAddMode) {
            setMappingData([...mappingData, updatedRecord]); // Add the new record to the table
        } else {
            setMappingData(mappingData.map(item => item.id === updatedRecord.id ? updatedRecord : item)); // Update the record in the table
        }
        setEditModalVisible(false);
    };

    const handleAddNewMapping = () => {
        setSelectedRecord({}); // Empty record for a new entry
        setEditModalVisible(true);
        setIsAddMode(true); // Setting add mode to true
    };

    const columns = [
        // Define your table columns here
        {
            title: 'Indicator ID',
            dataIndex: 'indicator_id',
            key: 'indicator_id',
        },
        {
            title: 'Indicator Name',
            dataIndex: 'indicator_name',
            key: 'indicator_name',
        },
        {
            title: 'WBG Corresponding Code',
            dataIndex: 'wbg_corresponding_code',
            key: 'wbg_corresponding_code',
        },
        {
            title: 'WBG Corresponding Description',
            dataIndex: 'wbg_corresponding_desc',
            key: 'wbg_corresponding_desc',
        },
        {
            title: 'SDG Corresponding Code',
            dataIndex: 'sdg_corresponding_code',
            key: 'sdg_corresponding_code',
        },
        {
            title: 'SDG Corresponding Description',
            dataIndex: 'sdg_corresponding_desc',
            key: 'sdg_corresponding_desc',
        },
        {
            title: 'Multiplication Factor',
            dataIndex: 'multiplication_factor',
            key: 'multiplication_factor',
        },
        /*
        {
            title: 'Last Retrieved Datetime',
            dataIndex: 'last_retrieved_datetime',
            key: 'last_retrieved_datetime',
        },
        */
        {
            title: 'Date Modified',
            dataIndex: 'date_modified',
            key: 'date_modified',
        },
        {
            title: 'Actions',
            dataIndex: '',
            key: 'actions',
            render: (_, record) => (
                <>
                    <span style={{ display: "flex" }}>
                        <Button style={{ marginLeft: 8 }} type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record)}></Button>
                        <Button style={{ marginLeft: 8 }} type="danger" icon={<DeleteOutlined />} onClick={() => handleDelete(record.indicator_id)}></Button>
                    </span>
                </>
            ),
        },
    ];

    return (
        <div>
            <Table
                dataSource={mappingData}
                columns={columns}
                rowKey="id"
                loading={loading}
            />
            <Button type="primary" onClick={
                handleAddNewMapping} style={{ marginTop: 16 }}>
                Add New Mapping
            </Button>
            {editModalVisible && (
                <EditMappingModal
                    visible={editModalVisible}
                    onCancel={handleEditModalCancel}
                    onEdit={handleUpdateMapping}
                    record={selectedRecord}
                    isAddMode={isAddMode}
                    indicatorsState={indicators}
                />
            )}
        </div>
    );
};