import React, { useState, useEffect } from 'react';
import { Select, Button, Typography, message } from 'antd';
import AxiosOICStat from '../AxiosOICStat'; // Ensure AxiosOICStat is set up correctly to make API calls
import { useSelector } from 'react-redux';

const { Option } = Select;
const { Title, Paragraph } = Typography;

export const DeleteAggregation = () => {
    const [aggregations, setAggregations] = useState([]);
    const [selectedAggregationId, setSelectedAggregationId] = useState(undefined);
    const [aggregationDetails, setAggregationDetails] = useState(null);
    const indicatorsState = useSelector((state) => state.indicators);

    // Fetch all aggregation records
    useEffect(() => {
        if (indicatorsState.status === 'succeeded') {
            AxiosOICStat.get('/aggregations/inds')
                .then(response => {
                    let temp = response.data.map(ind => ({
                        indCode: ind,
                        indLabel: `${ind} - ${indicatorsState.value[ind].ind_name_eng}`
                    }));
                    setAggregations(temp);
                })
                .catch(error => {
                    message.error('Failed to fetch aggregations.');
                });
        }
    }, [indicatorsState.status, indicatorsState.value]);

    // Handle selection change
    const handleSelectChange = (aggregationId) => {
        setSelectedAggregationId(aggregationId);
        // Fetch the aggregation details
        AxiosOICStat.get(`/aggregations/${aggregationId}`)
            .then(response => {
                setAggregationDetails(response.data);
            })
            .catch(error => {
                message.error('Failed to fetch aggregation details.');
                setAggregationDetails(null);
            });
    };

    // Handle delete operation
    const handleDelete = () => {
        if (!selectedAggregationId) {
            message.warning('Please select an aggregation to delete.');
            return;
        }

        AxiosOICStat.delete(`/aggregations/${selectedAggregationId}`)
            .then(() => {
                message.success('Aggregation deleted successfully.');
                setAggregations(aggregations.filter(aggr => aggr.indCode !== selectedAggregationId));
                setSelectedAggregationId(undefined);
                setAggregationDetails(null);
            })
            .catch(error => {
                message.error('Failed to delete aggregation.');
            });
    };

    return (
        <>
            <Title level={3}>Delete Aggregation</Title>
            <Select
                showSearch
                placeholder="Select Aggregation"
                style={{ width: '100%', marginBottom: 20 }}
                onChange={handleSelectChange}
                value={selectedAggregationId}
            >
                {aggregations.map(aggr => (
                    <Option key={aggr.indCode} value={aggr.indCode}>{aggr.indLabel}</Option>
                ))}
            </Select>
            {aggregationDetails && (
                <div>
                    <Paragraph>
                        <strong>Formula Name:</strong> {aggregationDetails.formulaName}
                    </Paragraph>
                    <Paragraph>
                        <strong>Aggregation Type:</strong> {aggregationDetails.aggregationType}
                    </Paragraph>
                    <Paragraph>
                        <strong>Formula:</strong> {aggregationDetails.formula}
                    </Paragraph>
                    <Button onClick={handleDelete} danger>
                        Delete Aggregation
                    </Button>
                </div>
            )}
        </>
    );
};
