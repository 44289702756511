import React, { useState } from 'react';
import { Button } from 'antd';

export const ExpandableTextView = ({ text, limit: char_limit=100 }) => {
    const safeText = text || '';

    const [displayText, setDisplayText] = useState(safeText.slice(0, char_limit));
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <div style={{ maxWidth: `${char_limit}ch` }}>
            <div>{!expanded ? displayText : safeText}
                {safeText.length > char_limit && (
                    <Button type="link" onClick={toggleExpanded}>
                        {!expanded && 'See more'}
                    </Button>
                )}
            </div>
        </div>
    );
};