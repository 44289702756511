import React, { useState, useEffect } from 'react';
import { Table, Drawer, message, Checkbox, Modal, Form, Input, Button, Row, Col, Space, Switch, Popconfirm, Select } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined, EditOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import AxiosOICStat from '../AxiosOICStat';
import ColumnBasedLayout from './ColumnBasedLayout';

export const RegionMappings = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [drawerMode, setDrawerMode] = useState('add'); // 'add' or 'edit'
    const [isEditing, setIsEditing] = useState(false);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [regionMappingsData, setRegionMappingsData] = useState([]);
    const [originalRegionMappings, setOriginalRegionMappings] = useState([]);
    const [regionCountriesData, setRegionCountriesData] = useState([]);
    const countries = useSelector(state => state.countries);
    const [initialFormValues, setInitialFormValues] = useState({});
    const [form] = Form.useForm();
    const user = useSelector(state => state.user);
    const [searchValue, setSearchValue] = useState('');
    const { Search } = Input;


    useEffect(() => {
        // Fetch the region mappings when the component is mounted
        const fetchRegionMappings = async () => {
            try {
                const response = await AxiosOICStat.get('/region-mappings/'); // Adjust the endpoint as needed
                console.log("RES",response.data)
                setRegionMappingsData(response.data); // Assuming the response body will be the array of region mappings
                setOriginalRegionMappings(response.data);
            } catch (error) {
                console.error('Failed to fetch region mappings:', error);
                // Handle errors, e.g., show notification
            }
        };

        fetchRegionMappings();
    }, []);

    useEffect(() => {
        if (drawerMode === 'edit') {
            const countryIds = regionCountriesData.map(rc => rc.country_id);
            setSelectedCountries(countryIds);
        }
    }, [regionCountriesData, drawerMode]);


    const fetchRegionCountries = async (regionId) => {
        try {
            const response = await AxiosOICStat.get(`/regions-countries/${regionId}`);
            setRegionCountriesData(response.data);
            form.setFieldsValue({
                selectedCountries: response.data.map(rc => rc.country_id)
            });
        } catch (error) {
            console.error('Failed to fetch region countries:', error);
            // Handle errors, e.g., show notification
        }
    };

    const toggleVisibility = async (record) => {
        try {
            const updatedRegion = {
                ...record,
                is_visible: !record.is_visible, // Toggle the visibility
            };
            const response = await AxiosOICStat.put(`/region-mappings/${record.id}`, updatedRegion);
            setRegionMappingsData(regionMappingsData.map(r => r.id === record.id ?
                { ...r, ...response.data } : r
            ));

            message.success('Visibility updated');
        } catch (error) {
            console.error('Failed to update visibility:', error);
            message.error('Failed to update visibility');
        }
    };

    const openDrawer = async (mode, record = null) => {
        setDrawerVisible(false); // Temporarily hide the drawer to reset states without showing UI changes to the user
        setDrawerMode(mode);
        console.log("RECORD",record);
        if (mode === 'edit' && record) {
            setSelectedRegion(record);
            // Reset form and selectedCountries before fetching new data
            form.resetFields();
            setSelectedCountries([]);

            // Fetch new data
            await fetchRegionCountries(record.id);
            // Assuming fetchRegionCountries updates regionCountriesData and is now awaited
            // After fetching, update the form values and selectedCountries
            form.setFieldsValue({
                regionName: record.region_name,
                is_visible: record.is_visible,
                regionExp: record.region_exp_en,
                regionCategory: record.region_category
            });

            const countryIds = regionCountriesData.map(rc => rc.country_id);
            setSelectedCountries(countryIds);
        } else if (mode === 'add') {
            setIsEditing(true);
            form.resetFields();
            setSelectedCountries([]);
            setSelectedRegion(null); // Ensure no selected region in add mode
        }

        setDrawerVisible(true); // Show the drawer after states are correctly set
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
        setSelectedRegion(null);
        setIsEditing(false);
        setSelectedCountries([]);
        setInitialFormValues({});
    };

    const onCountrySelectChange = (checked, countryId) => {
        setSelectedCountries(prev => {
            if (checked) {
                // Add country ID if checked
                return [...prev, countryId];
            } else {
                // Remove country ID if unchecked
                return prev.filter(id => id !== countryId);
            }
        });
    };

    const handleAdd = async (values) => {
        try {
            // Step 1: Create the new region mapping
            const newMapping = {
                region_name: values.regionName,
                is_visible: values.is_visible,
                region_exp_en: values.regionExp,
                region_category: values.regionCategory
            };
            //console.log(newMapping);

            const mappingResponse = await AxiosOICStat.post('/region-mappings/', newMapping);
            const regionId = mappingResponse.data.id; // Assuming the response includes the region ID
            message.success('Region mapping added successfully');

            const batch = {
                region_id: regionId,
                country_ids: selectedCountries,
            };
            await AxiosOICStat.post('/regions-countries/batch/', batch);
            message.success('Countries associated with region successfully');
            const newRegionData = {
                ...mappingResponse.data, // Assuming mappingResponse.data contains all the necessary fields
                country_count: selectedCountries.length, // Initial country count is the length of selectedCountries
            }
            setRegionMappingsData(currentData => [...currentData, newRegionData]);


        } catch (error) {
            console.error('Error during the add operation:', error);
            message.error('Error adding region mapping and associating countries');
        }
    };

    const handleEdit = async (values) => {
        if (!selectedRegion || !selectedRegion.id) return; // Guard clause in case selectedRegion is not set

        // Determine if name or visibility has changed
        const nameChanged = values.regionName !== selectedRegion.region_name;
        const visibilityChanged = values.is_visible !== selectedRegion.is_visible;
        const explanationChanged = values.regionExp !== selectedRegion.region_exp_en;
        const categoryChanged = values.regionCategory !== selectedRegion.region_category
        console.log(values)
        

        // Determine countries that have been added or removed
        const initialCountryIds = new Set(regionCountriesData.map(rc => rc.country_id));
        const finalCountryIds = new Set(selectedCountries);
        const addedCountries = selectedCountries.filter(id => !initialCountryIds.has(id));
        const removedCountries = [...initialCountryIds].filter(id => !finalCountryIds.has(id));
        const countChanged = addedCountries.length > 0 || removedCountries.length > 0;

        try {
            if (nameChanged || visibilityChanged || explanationChanged || categoryChanged) {
                const updatedMapping = {
                    region_name: values.regionName,
                    region_exp_en: values.regionExp,
                    is_visible: values.is_visible,
                    region_category: values.regionCategory
                };
                await AxiosOICStat.put(`/region-mappings/${selectedRegion.id}`, updatedMapping);
                message.success('Region mapping updated successfully');
            }

            // Step 2: Handle added countries
            if (addedCountries.length > 0) {
                const batch = {
                    region_id: selectedRegion.id,
                    country_ids: addedCountries,
                };
                await AxiosOICStat.post('/regions-countries/batch/', batch);
            }

            // Step 3: Handle removed countries
            if (removedCountries.length > 0) {
                const batchDelete = {
                    region_id: selectedRegion.id,
                    country_ids: removedCountries,
                };
                await AxiosOICStat.delete('/regions-countries/batch-delete/', { data: batchDelete });
            }

            if (addedCountries.length > 0 || removedCountries.length > 0 || nameChanged || visibilityChanged || explanationChanged || categoryChanged) {
                message.success('Region updated successfully');
            } else {
                message.info('No changes to update');
            }

            // Update the table state
            setRegionMappingsData(currentData => currentData.map(item => {
                console.log("ITEM",item);
                if (item.id === selectedRegion.id) {
                    return {
                        ...item,
                        region_name: values.regionName ?? item.region_name,
                        is_visible: values.is_visible ?? item.is_visible,
                        region_exp_en: values.regionExp ?? item.region_exp_en,
                        region_category: values.regionCategory ?? item.region_category,
                        country_count: countChanged ? item.country_count + addedCountries.length - removedCountries.length : item.country_count,
                    };
                }
                return item;
            }));

            // Optionally, refresh the list to show updated details
        } catch (error) {
            console.error('Error updating region mapping and countries:', error);
            message.error('Error updating region mapping and countries');
        }
    };

    const handleDelete = async () => {
        if (!selectedRegion || !selectedRegion.id) return; // Guard clause in case selectedRegion is not set

        try {
            // Step 1: Delete the region mapping
            await AxiosOICStat.delete(`/region-mappings/${selectedRegion.id}`);
            message.success('Region mapping deleted successfully');

            // Step 2: Delete the region-countries association
            await AxiosOICStat.delete(`/regions-countries/batch-delete-by-region/${selectedRegion.id}`);
            message.success('Associated countries removed successfully');

            // Update the table data to remove the deleted region
            setRegionMappingsData(currentData => currentData.filter(item => item.id !== selectedRegion.id));

            closeDrawer(); // Close the drawer after successful deletion
        } catch (error) {
            console.error('Error deleting region and its associations:', error);
            message.error('Error during deletion process');
        }
    };


    const handleFormSubmit = (values) => {
        if (drawerMode === 'add') {
            handleAdd(values);
        } else if (drawerMode === 'edit') {
            handleEdit(values);
        }
        closeDrawer();
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        const lowerCaseValue = value.toLowerCase();
        const filtered = originalRegionMappings.filter(region => {
            const regionName = region.region_name || '';
            return regionName.toLowerCase().includes(lowerCaseValue)
        });
        setRegionMappingsData(filtered);
    };

    const columns = [
        {
            title: 'Region Name',
            dataIndex: 'region_name',
            key: 'region_name',
            sorter: (a, b) => a.region_name.localeCompare(b.region_name),
        },
        {
            title: 'Region Explaination',
            dataIndex: 'region_exp_en',
            key: 'region_exp_en',
            sorter: (a, b) => a.region_name.localeCompare(b.region_name),
        },
        {
            title: 'Visibility',
            dataIndex: 'is_visible',
            key: 'is_visible',
            render: (is_visible, record) => (
                user.role !== 2 ? (
                    <Popconfirm
                        title={`Are you sure you want to ${is_visible ? 'hide' : 'show'} this region?`}
                        onConfirm={() => toggleVisibility(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <span style={{ cursor: 'pointer' }}>
                            {is_visible ? (
                                <EyeOutlined style={{ color: 'green' }} />
                            ) : (
                                <EyeInvisibleOutlined style={{ color: 'red' }} />
                            )}
                        </span>
                    </Popconfirm>
                ) : (
                    is_visible ? (
                        <EyeOutlined style={{ color: 'green' }} />
                    ) : (
                        <EyeInvisibleOutlined style={{ color: 'red' }} />
                    )
                )
            ),
            sorter: (a, b) => a.is_visible - b.is_visible,
        },
        {
            title: 'Count',
            dataIndex: 'country_count',
            key: 'country_count',
            sorter: (a, b) => a.country_count - b.country_count,
        },
        {
            title: 'Category',
            dataIndex: 'region_category',
            key: 'region_category',
            sorter: (a, b) => a.country_count - b.country_count,
            render: (text) => capitalizeCategoryText(text)
        },
        {
            title: 'Actions',
            dataIndex: '',
            key: 'actions',
            render: (text, record) => (
                <>
                    <span style={{ display: "flex" }}>
                        <Button style={{ marginLeft: 8 }} icon={<EditOutlined />} onClick={() => openDrawer('edit', record)}></Button>
                    </span>
                </>
            ),
            width: "5%",
        },
    ];

    const renderCountryItem = (country) => (
        <Checkbox
            key={country.c_code}
            checked={selectedCountries.includes(country.c_code)}
            onChange={(e) => {

                return isEditing ? onCountrySelectChange(e.target.checked, country.c_code) : null;
            }}
            style={{ pointerEvents: isEditing ? 'auto' : 'none' }}
        >
            {country.c_short_name_eng}
        </Checkbox>
    );

    const responsiveColumns = {
        xl: 3,
        lg: 3,
        md: 3,
        sm: 2,
        xs: 1
    };

    const capitalizeCategoryText = (text) => {
        if(text == undefined){
            return '';
        }
        else if (text === 'oic') {
          return text.toUpperCase(); // All letters capitalized if 'oic'
        }
        else{
            return text.charAt(0).toUpperCase() + text.slice(1); // Capitalize the first letter otherwise
        }
        
      };

    return (
        <>
            {
                user.role !== 2 && (
                    <Button type="primary" onClick={() => openDrawer('add')} style={{ marginBottom: 16 }}>
                        Add New Mapping
                    </Button>
                )
            }
            <Search
                placeholder="Search"
                allowClear
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ marginBottom: 10 }}
            />
            <Table
                columns={columns}
                dataSource={regionMappingsData}
                rowKey="id"
                pagination={{ pageSize: 10 }}
            />
            <Drawer
                title={drawerMode === 'add' ? "Add New Region Mapping" : "Edit Region Mapping"}
                placement="right"
                onClose={closeDrawer}
                visible={drawerVisible}
                size='large'
                extra={
                    user.role !== 2 && drawerMode === "edit" && (
                        <Space>
                            <Button onClick={() => {
                                setIsEditing(!isEditing);
                            }} type="primary" danger={isEditing && true}>
                                {isEditing ? 'Cancel' : 'Edit'}
                            </Button>
                            <Popconfirm
                                title="Are you sure you want to delete this region?"
                                onConfirm={handleDelete}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger>
                                    Delete
                                </Button>
                            </Popconfirm>
                        </Space>
                    )
                }
            >
                <Form
                    form={form}
                    layout="horizontal"
                    onFinish={handleFormSubmit}
                    initialValues={initialFormValues}
                >
                    <Form.Item label="Region Name" name="regionName" rules={[{ required: true, message: 'Please input the region name!' }]}>
                        {isEditing ? (
                            <Input />
                        ) : (
                            <span>{form.getFieldValue('regionName')}</span> // Display the name as text if not editing
                        )}
                    </Form.Item>
                    <Form.Item label="Region Explanation" name="regionExp" rules={[{ required: true, message: 'Please input the region explanation!' }]}>
                        {isEditing ? (
                            <Input />
                        ) : (
                            <span>{form.getFieldValue('regionExp')}</span> // Display the name as text if not editing
                        )}
                    </Form.Item>
                    <Form.Item
                    label="Region Category"
                    name="regionCategory"
                    rules={[{ required: true, message: 'Please input the region explanation!' }]}
                    >
                    {isEditing ? (
                        <Select>
                        <Select.Option value="oic">OIC</Select.Option>
                        <Select.Option value="income">Income</Select.Option>
                        <Select.Option value="geographical">Geographical</Select.Option>
                        </Select>
                    ) : (
                        <span>{capitalizeCategoryText(form.getFieldValue('regionCategory'))}</span>
                    )}
                    </Form.Item>
                    <Form.Item
                        label="Visibility"
                        name="is_visible"
                        valuePropName="checked"
                    >
                        <Switch disabled={!isEditing} />
                    </Form.Item>
                    <Form.Item
                        label="Select Countries"
                        labelCol={{ span: 24 }}
                    >
                        {
                            countries && <ColumnBasedLayout
                                items={Object.values(countries.value).sort((a, b) => a.c_short_name_eng.localeCompare(b.c_short_name_eng))}
                                renderItem={renderCountryItem}
                                responsiveColumns={responsiveColumns}
                            />
                        }
                    </Form.Item>
                    <Form.Item>
                        {(drawerMode === 'add' || isEditing) && (
                            <Button type="primary" htmlType="submit">
                                {drawerMode === 'add' ? 'Add' : 'Update'}
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};