import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosOICStat from '../AxiosOICStat';

export const fetchSources = createAsyncThunk(
    'sources/fetchSources',
    async () => {
        const response = await AxiosOICStat.get(`sources`);
        let sources = {};
        response.data.forEach(source => {
            sources[source.source_code] = source;
        });
        return sources;
    }
);

export const sourcesSlice = createSlice({
    name: 'sources',
    initialState: {
        status: 'idle',
        ready: false,
        value: {},
    },
    reducers: {
        setSources(state, action) {
            state.value = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSources.pending, (state) => {
            state.status = 'loading';
        }).addCase(fetchSources.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.value = action.payload;
            state.ready = true;
        }).addCase(fetchSources.rejected, (state) => {
            state.status = 'failed';
        });
    },
});

export default sourcesSlice.reducer;
