import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, message, Modal, Table, Spin } from "antd";
import AxiosOICStat from "AxiosOICStat";
import { useSelector } from "react-redux";

const { Option } = Select;

export function GenerateSyb() {
  const [configurations, setConfigurations] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const indicatorsState = useSelector((state) => state.indicators);
  const [csvContent, setCsvContent] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isPdfModalVisible, setIsPdfModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedIndicator, setSelectedIndicator] = useState(null);

const downloadCsv = () => {
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", "report.csv"); // Set the filename
  document.body.appendChild(link);
  link.click();

  // Cleanup
  link.remove();
  window.URL.revokeObjectURL(downloadUrl);
};


  console.log("indicatorsState", indicatorsState.value);

  // Fetch existing configurations
  const fetchConfigurations = async () => {
    try {
      const response = await AxiosOICStat.get("/syb/syb_configs/");
      setConfigurations(response.data);
    } catch (error) {
      message.error("Failed to fetch configurations: " + error.message);
    }
  };

  // Fetch existing indicators
  const fetchIndicators = async () => {
    try {
      const response = await AxiosOICStat.get("/syb/syb_indicators/");
      setIndicators(response.data);
    } catch (error) {
      message.error("Failed to fetch indicators: " + error.message);
    }
  };

  useEffect(() => {
    fetchConfigurations();
    fetchIndicators();
  }, []);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
  
      const response = await AxiosOICStat.post("/syb/generate_csv/", values, {
        responseType: "blob",
      });
  
      setSelectedIndicator(values.ind_code);
      setLoading(false);
  
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const csvText = fileReader.result;
  
      const rows = csvText.split("\n").map((row) => {
        return row.split(",").map((cell, index) => {
          const trimmedCell = cell.trim().replace(/^"|"$/g, "");
        
          const numericValue = Number(trimmedCell.replace(/,/g, ""));

          if (!isNaN(numericValue) && numericValue > 1000 && !/^\d{4}$/.test(trimmedCell)) {
            return numericValue.toLocaleString();
          }

          return trimmedCell;
        });
      });

        setCsvContent(rows);
        setIsModalVisible(true);
      };
      fileReader.readAsText(new Blob([response.data], { type: "text/csv" }));
  
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "text/csv" }));
      setDownloadUrl(url);
    } catch (error) {
      const errorMessage = error.response?.data?.detail || error.message || "Failed to generate report.";
      message.error(errorMessage);
    }
  };
  
  const handlePdfGeneration = async (values) => {
    try {
      setLoading(true);
      // Request to generate the PDF
      const response = await AxiosOICStat.post("/syb/generate_pdf/", values, {
        responseType: "blob", // Ensure response is treated as binary file
      });
      setLoading(false);
  
      // Create a Blob URL for preview
      const pdfBlobUrl = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
      setPdfUrl(pdfBlobUrl);
      setIsPdfModalVisible(true); // Show PDF modal
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response?.data?.detail || error.message || "Failed to generate PDF.";
      message.error(errorMessage);
    }
  };
  

  return (
    <div>
      <h1>Generate SYB</h1>
      <Form
  layout="vertical"
  onFinish={(values) => {
    console.log("Form submitted with values:", values); // Debug: Log form values on successful submission
    handleSubmit(values); // Call the actual submission function
  }}
  onFinishFailed={(errorInfo) => {
    console.log("Form submission failed with errors:", errorInfo); // Debug: Log validation errors if submission fails
  }}
>
  <Form.Item
    name="ind_code"
    label="Indicator"
    rules={[{ required: true, message: "Please select an indicator" }]}
  >
    <Select
      placeholder="Select Indicator"
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.label?.toLowerCase().includes(input.toLowerCase())
      }
    >
      {indicators.map((indicator) => (
        <Option
          key={indicator.ind_code}
          value={indicator.ind_code}
          label={`${indicator.ind_code} - ${indicatorsState.value[indicator.ind_code]?.ind_name_eng || "Unknown Indicator"}`}
        >
          {indicator.ind_code} - {indicatorsState.value[indicator.ind_code]?.ind_name_eng || "Unknown Indicator"}
        </Option>
      ))}
    </Select>
  </Form.Item>

  <Form.Item>
    <Spin spinning={loading}>
      <Button
        type="primary"
        htmlType="submit"
        onClick={() => {
          console.log("Submit button clicked"); // Debug: Log when the submit button is clicked
        }}
      >
        Generate Report
      </Button>
    </Spin>
  </Form.Item>
</Form>

      <Modal
          title="Preview CSV Content"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <Button
              key="convert-to-pdf"
              type="primary"
              onClick={() => {
                const values = {
                  // Pass necessary values to generate PDF
                  ind_code: selectedIndicator, // Replace with your selected indicator
                };
                handlePdfGeneration(values);
              }}
            >
              Convert to PDF
            </Button>,
            <Button key="download" type="primary" onClick={() => downloadCsv()}>
              Download CSV
            </Button>,
            <Button key="close" onClick={() => setIsModalVisible(false)}>
              Close
            </Button>,
          ]}
          width={800} // Set modal width
          bodyStyle={{ maxHeight: "60vh", overflow: "auto" }} // Limit modal height and add scrolling
        >
          {csvContent.length > 0 ? (
           <Table
           dataSource={(() => {
             const aggregationRows = [];
             const normalRows = [];
             csvContent.slice(1).forEach((row, index) => {
               const rowData = {
                 key: index,
                 ...row.reduce(
                   (acc, col, i) => ({ ...acc, [csvContent[0][i]]: col }),
                   {}
                 ),
               };
         
               if (rowData["Country Name"] === "OIC" || rowData["Country Name"] === "World") {
                aggregationRows.push(rowData);
              } else {
                normalRows.push(rowData);
              }
             });
         
             return [...normalRows, ...aggregationRows];
           })()}
           columns={csvContent[0].map((header) => ({ title: header, dataIndex: header }))}
           pagination={false}
           bordered
         />
         
          ) : (
            <p>No data to display</p>
          )}
        </Modal>
        <Modal
          title="Preview PDF Report"
          visible={isPdfModalVisible}
          onCancel={() => setIsPdfModalVisible(false)}
          footer={[
            <Button
              key="download-pdf"
              type="primary"
              onClick={() => {
                const link = document.createElement("a");
                link.href = pdfUrl;
                link.setAttribute("download", "report.pdf");
                document.body.appendChild(link);
                link.click();
                link.remove();
              }}
            >
              Download PDF
            </Button>,
            <Button key="close-pdf" onClick={() => setIsPdfModalVisible(false)}>
              Close
            </Button>,
          ]}
          width={800}
          style={{ maxHeight: "80vh", overflow: "auto" }}
        >
          {pdfUrl ? (
            <iframe
              src={pdfUrl}
              title="PDF Preview"
              style={{ width: "100%", height: "60vh" }}
            ></iframe>
          ) : (
            <Spin spinning={loading} />
          )}
        </Modal>

     
    </div>
  );
}