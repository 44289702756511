import React, { useEffect } from 'react';
import AxiosOICStat from '../AxiosOICStat';
import { Row, Card, Col } from 'antd';
import { useParams } from 'react-router-dom';
import { Descriptions } from 'antd';

export const CountryInfo = ({ c_code: propCCode }) => {
    const { c_code: urlCCode } = useParams();
    const c_code = propCCode || urlCCode;
    const [country, setCountry] = React.useState({});

    useEffect(() => {
        if (!c_code) return;
        AxiosOICStat.get(`countries/${c_code}`)
            .then(response => {
                setCountry(response.data);
            })
            .catch(err => {
                console.log(err);
            });
    }, [c_code]);

    return (
        <Row>
            <Col xs={24}>
                <Card title={country?.c_short_name_eng}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="Code">{country?.c_code}</Descriptions.Item>
                        <Descriptions.Item label="Name">{country?.c_short_name_eng}</Descriptions.Item>
                        <Descriptions.Item label="Phone Prefix">{country?.c_phone_prefix}</Descriptions.Item>
                        <Descriptions.Item label="Traffic Code">{country?.c_traffic_code}</Descriptions.Item>
                        <Descriptions.Item label="Full Name (Arabic)">{country?.c_full_name_arb}</Descriptions.Item>
                        <Descriptions.Item label="Full Name (English)">{country?.c_full_name_eng}</Descriptions.Item>
                        <Descriptions.Item label="Full Name (French)">{country?.c_full_name_fr}</Descriptions.Item>
                        <Descriptions.Item label="Full Name (Turkish)">{country?.c_full_name_tr}</Descriptions.Item>
                        <Descriptions.Item label="Short Name (Arabic)">{country?.c_short_name_arb}</Descriptions.Item>
                        <Descriptions.Item label="Short Name (English)">{country?.c_short_name_eng}</Descriptions.Item>
                        <Descriptions.Item label="Short Name (French)">{country?.c_short_name_fr}</Descriptions.Item>
                        <Descriptions.Item label="Short Name (Turkish)">{country?.c_short_name_tr}</Descriptions.Item>
                    </Descriptions>
                </Card>
            </Col>
        </Row>
    );
};