import axios from 'axios';

const baseURL = `${window.location.protocol}//${window.location.hostname}${process.env.REACT_APP_API_URL}/`;

const AxiosOICStat = axios.create({
  baseURL: baseURL,
});

// Set the default Authorization header
if (process.env.REACT_APP_PROJECT_TYPE === 'internal') {
  AxiosOICStat.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
}

export default AxiosOICStat;
