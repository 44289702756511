import { ResponsiveLine } from "@nivo/line";
import React from 'react';
import { formatYWithDecimalDigits } from "./DataUtils";

const customYFormat = (value) => {
  if (value >= 1000000) {
    const millionValue = value / 1000000;
    return `${millionValue.toFixed(3)}M`;
  }
  return value;
};

// Function to calculate evenly distributed dates
const getEvenlyDistributedDates = (data) => {
  const sortedData = [...data].sort((a, b) => new Date(a.x) - new Date(b.x));
  const totalDates = sortedData.length;
  const step = Math.floor(totalDates / 8); // Calculate step to get approximately 9 dates

  let distributedDates = [];
  for (let i = 0; i < totalDates; i += step) {
    distributedDates.push(sortedData[i].x);
  }

  return distributedDates;
};

const getAllDates = (data) => {
  const sortedData = [...data].sort((a, b) => new Date(a.x) - new Date(b.x));
  return sortedData.map(d => d.x);
};



const DataTooltip = function ({ slice }) {
  return (
    <div
      style={{
        background: "white",
        padding: "9px 12px",
        borderRadius: "10px",
        borderBottom: "1px solid #ccc",
      }}
    >
      {slice.points.map(point => (
        <div
          key={point.id}
          style={{
            color: "black",
            padding: "3px 0",
          }}
        >
          <strong>Date</strong>: {point.data.xFormatted} <strong>Data Count</strong>: {formatYWithDecimalDigits(point.data.y)}
        </div>
      ))}
    </div>
  );
};

const IndicatorTooltip = function ({ slice }) {
  return (
    <div
      style={{
        background: "white",
        padding: "9px 12px",
        borderRadius: "10px",
        borderBottom: "1px solid #ccc",
      }}
    >
      <div>
        <strong>Date</strong>: {slice.points[0].data.xFormatted}
      </div>
      {slice.points.map(point => (
        <div
          key={point.id}
          style={{
            color: "black",
            padding: "3px 0",
          }}
        >
          <strong>{point.id.split(".")[0]} Count</strong>: {point.data.y}
        </div>
      ))}
    </div>
  );
};




// type = data, indicator
export const DataChart = ({ data, axisNames, type }) => {
  const isLessThanThreshold = data[0].data.length < 20;
  const customTickValues = isLessThanThreshold ? getAllDates(data[0].data) : getEvenlyDistributedDates(data[0].data);

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 30, right: 130, bottom: 50, left: 80 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        reverse: false
      }}
      yFormat={customYFormat}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: customTickValues.length > 10 ? 15 : 0,
        legend: axisNames[0],
        legendOffset: 36,
        legendPosition: 'middle',
        tickValues: customTickValues,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: axisNames[1],
        format: customYFormat,
        legendOffset: -70,
        legendPosition: 'middle',
      }}
      enablePoints={data && data[0].data && isLessThanThreshold}
      enableGridX={data && data[0].data && isLessThanThreshold}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      theme={{
        axis: {
          legend: {
            text: {
              fontSize: "14px",
              fontWeight: "bold"
            }
          }
        }
      }}
      enableSlices="x"
      sliceTooltip={({ slice }) => {
        return type === "data" ? <DataTooltip slice={slice} /> : <IndicatorTooltip slice={slice} />;
      }}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 130,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 110,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    />);
}