import React, { useState, useEffect } from 'react';
import { Select, Button, Typography, message, Input } from 'antd';
import AxiosOICStat from '../AxiosOICStat'; // Ensure AxiosOICStat is set up correctly to make API calls
import { useSelector } from 'react-redux';

const { Option } = Select;
const { Title, Paragraph } = Typography;

export const EditAggregation = () => {
    const [aggregations, setAggregations] = useState([]);
    const [selectedAggregationId, setSelectedAggregationId] = useState(undefined);
    const [aggregationDetails, setAggregationDetails] = useState({
        formulaName: '',
        aggregationType: '',
        formula: ''
    });
    const indicatorsState = useSelector((state) => state.indicators);

    // Fetch all aggregation records
    useEffect(() => {
        if (indicatorsState.status === 'succeeded') {
            AxiosOICStat.get('/aggregations/inds')
                .then(response => {
                    let temp = response.data.map(ind => ({
                        indCode: ind,
                        indLabel: `${ind} - ${indicatorsState.value[ind].ind_name_eng}`
                    }));
                    setAggregations(temp);
                })
                .catch(error => {
                    message.error('Failed to fetch aggregations.');
                });
        }
    }, [indicatorsState.status, indicatorsState.value]);

    // Handle selection change
    const handleSelectChange = (aggregationId) => {
        setSelectedAggregationId(aggregationId);
        // Fetch the aggregation details
        AxiosOICStat.get(`/aggregations/${aggregationId}`)
            .then(response => {
                setAggregationDetails(response.data);
            })
            .catch(error => {
                message.error('Failed to fetch aggregation details.');
                setAggregationDetails({
                    formulaName: '',
                    aggregationType: '',
                    formula: ''
                });
            });
    };

    // Handle input changes
    const handleInputChange = (field, value) => {
        setAggregationDetails(prevDetails => ({ ...prevDetails, [field]: value }));
    };

    // Handle save operation
    const handleSave = () => {
        if (!selectedAggregationId) {
            message.warning('Please select an aggregation to edit.');
            return;
        }

        AxiosOICStat.put(`/aggregations/${selectedAggregationId}`, aggregationDetails)
            .then(() => {
                message.success('Aggregation updated successfully.');
            })
            .catch(error => {
                message.error('Failed to update aggregation.');
            });
    };

    return (
        <>
            <Title level={3}>Edit Aggregation</Title>
            <Select
                showSearch
                placeholder="Select Aggregation"
                style={{ width: '100%', marginBottom: 20 }}
                onChange={handleSelectChange}
                value={selectedAggregationId}
            >
                {aggregations.map(aggr => (
                    <Option key={aggr.indCode} value={aggr.indCode}>{aggr.indLabel}</Option>
                ))}
            </Select>
            {selectedAggregationId && (
                <div>
                    <Paragraph>
                        <strong>Formula Name:</strong>
                        <Input
                            value={aggregationDetails.formulaName}
                            onChange={(e) => handleInputChange('formulaName', e.target.value)}
                            style={{ width: '100%' }}
                        />
                    </Paragraph>
                    <Paragraph>
                        <strong>Aggregation Type:</strong>
                        <Input
                            value={aggregationDetails.aggregationType}
                            onChange={(e) => handleInputChange('aggregationType', e.target.value)}
                            style={{ width: '100%' }}
                        />
                    </Paragraph>
                    <Paragraph>
                        <strong>Formula:</strong>
                        <Input
                            value={aggregationDetails.formula}
                            onChange={(e) => handleInputChange('formula', e.target.value)}
                            style={{ width: '100%' }}
                        />
                    </Paragraph>
                    <Button onClick={handleSave} type="primary">
                        Save Changes
                    </Button>
                </div>
            )}
        </>
    );
};
