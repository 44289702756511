import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Modal, Form, Input, InputNumber, message } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import AxiosOICStat from '../AxiosOICStat';
import { CountryInfo } from './CountryInfo';  // Import CountryInfo
import { useSelector } from 'react-redux';

const EditModal = ({ visible, record, onCancel, onUpdate }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        // Fetch country data when the modal is opened
        if (record && record.c_code) {
            AxiosOICStat.get(`countries/${record.c_code}`)
                .then(response => {
                    form.setFieldsValue(response.data); // Set form values
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [record]);

    const handleEditSubmit = (values) => {
        AxiosOICStat.put(`countries/${record.c_code}`, values)
            .then(response => {
                message.success('Country updated successfully');
                onUpdate(values); // Call the onUpdate callback to update the state
                onCancel();
            })
            .catch(err => {
                console.error('Error updating country:', err);
                message.error('Error updating country');
            });
    };

    return (
        <Modal
            visible={visible}
            title="Edit Country"
            onCancel={onCancel}
            width="50%"
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="edit" type="primary" onClick={() => form.submit()}>
                    Save
                </Button>,
            ]}
        >
            {record && (
                <Form form={form} onFinish={handleEditSubmit}>
                    <Form.Item label="Code" name="c_code">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item label="Name" name="c_short_name_eng">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Full Name" name="c_full_name_eng">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Phone Prefix" name="c_phone_prefix">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Traffic Code" name="c_traffic_code">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Full Name (Arabic)" name="c_full_name_arb">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Full Name (English)" name="c_full_name_eng">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Full Name (French)" name="c_full_name_fr">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Full Name (Turkish)" name="c_full_name_tr">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Short Name (Arabic)" name="c_short_name_arb">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Short Name (English)" name="c_short_name_eng">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Short Name (French)" name="c_short_name_fr">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Short Name (Turkish)" name="c_short_name_tr">
                        <Input />
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};

const ShowModal = ({ visible, record, onCancel }) => (
    <Modal
        visible={visible}
        title="Country Details"
        onCancel={onCancel}
        footer={[
            <Button key="cancel" onClick={onCancel}>
                Close
            </Button>,
        ]}
    >
        {record && (
            <>
                <CountryInfo c_code={record.c_code} />
            </>
        )}
    </Modal>
);

export const Countries = () => {
    const [countries, setCountries] = useState([]);
    const [originalCountries, setOriginalCountries] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [showModalVisible, setShowModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [loading, setLoading] = useState(true);
    const user = useSelector((state) => state.user);
    const [searchValue, setSearchValue] = useState('');
    const { Search } = Input;

    const columns = [
        { title: 'Code', dataIndex: 'c_code' },
        { title: 'Name', dataIndex: 'c_short_name_eng' },
        // Add other columns as needed
        {
            title: 'Actions',
            dataIndex: '',
            key: 'actions',
            render: (text, record) => (
                <span style={{ display: "flex" }}>
                    {user.role !== 2 && <Button type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record)}></Button> }
                    <Button style={{ marginLeft: 8 }} icon={<EyeOutlined />} onClick={() => handleShow(record)}>
                    </Button>
                </span>
            ),
        },
    ];

    useEffect(() => {
        AxiosOICStat.get(`countries`)
            .then((response) => {
                setCountries(response.data);
                setOriginalCountries(response.data)
                console.log(response.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false); // Set loading to false when fetch is complete
            });
    }, []);

    const handleEdit = (record) => {
        setSelectedRecord(record);
        setEditModalVisible(true);
    };

    const handleShow = (record) => {
        setSelectedRecord(record);
        setShowModalVisible(true);
    };

    const handleEditModalCancel = () => {
        setEditModalVisible(false);
    };

    const handleShowModalCancel = () => {
        setShowModalVisible(false);
    };

    const handleUpdate = (updatedRecord) => {
        setCountries(prevCountries => 
            prevCountries.map(country => 
                country.c_code === updatedRecord.c_code ? updatedRecord : country
            )
        );
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        const lowerCaseValue = value.toLowerCase();
        const filtered = originalCountries.filter(country => {
            const countryCode = country.c_code || '';
            const countryName = country.c_short_name_eng || '';
            return countryName.toLowerCase().includes(lowerCaseValue) || countryCode.toString().includes(lowerCaseValue);
        });
        setCountries(filtered);
    };

    return (
        <Row>
            <Search
                placeholder="Search"
                allowClear
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ marginBottom: 10 }}
            />
            <Col xs={24}>
                <Table
                    rowKey="c_code"
                    dataSource={countries}
                    columns={columns}
                    size="small"
                    pagination={{ pageSize: 10 }}
                    loading={loading}
                />
            </Col>

            <EditModal
                visible={editModalVisible}
                onCancel={handleEditModalCancel}
                onUpdate={handleUpdate}
                record={selectedRecord}
            />

            <ShowModal
                visible={showModalVisible}
                onCancel={handleShowModalCancel}
                record={selectedRecord}
            />
        </Row>
    );
};