import React, { useState, useEffect } from 'react';
import { Card, Typography, Divider, Space, Alert } from 'antd';
import { InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import AxiosOICStat from 'AxiosOICStat';

const { Paragraph, Title } = Typography;

export const About = () => {
    const [releaseNotes, setReleaseNotes] = useState([]);

    useEffect(() => {
        const fetchReleaseNotes = async () => {
            try {
                const response = await AxiosOICStat.get('/release-notes/get-json');
                setReleaseNotes(response.data['release-notes']);
            } catch (error) {
                console.error('Failed to fetch release notes:', error);
            }
        };

        fetchReleaseNotes();
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <Alert
                message="Page Maintenance Notice"
                description="This page is not currently maintained. Please contact support if this page is useful."
                type="warning"
                showIcon
                style={{ marginBottom: 24 }}
            />
            {releaseNotes.map((note, index) => (
                <Card
                    key={note.date}
                    style={{ marginBottom: 24, borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
                    title={
                        <Title level={4} style={{ fontWeight: 'normal', color: '#1890ff' }}>
                            <Space>
                                <InfoCircleOutlined />
                                Release Date: {note.date}
                            </Space>
                        </Title>
                    }
                >
                    <h3 style={{ marginTop: "-12px" }}>Changes</h3>
                    {note.changes.map((change, index) => (
                        <Paragraph key={index} style={{ textAlign: 'justify' }}>
                            <CheckCircleOutlined style={{ color: '#52c41a', marginRight: 8 }} />
                            {change}
                        </Paragraph>
                    ))}
                </Card>
            ))}
        </div>
    );
};