import { Tabs } from 'antd';
import './style.scss';
import LogLevelManager from './LogLevelManager';
import { LogViewer } from './LogViewer';
import { FileSearchOutlined, SettingOutlined } from '@ant-design/icons';

export const LogHomePage = () => {
    return (
        <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={<span><FileSearchOutlined style={{ marginRight: '5px' }} />Log Viewer</span>} key="1">
                <LogViewer />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span><SettingOutlined style={{ marginRight: '5px' }} />Settings</span>} key="2">
                <LogLevelManager />
            </Tabs.TabPane>
        </Tabs>
    );
};