import React, { useMemo } from 'react';
import { Modal, Table, Button } from 'antd';
import { Link } from "react-router-dom";
import { FilterDropdown } from '../FilterDropdown';
import { createFilterOptions } from "../Utils";

export const IndicatorTableShowModal = ({ visible, record, onCancel, indicators }) => {
    console.log(indicators);
    const indicatorFilterOptions = useMemo(() => createFilterOptions(indicators, 'ind_name_eng'), [indicators]);
    
    return <Modal
        visible={visible}
        title={`Indicators`}
        onCancel={onCancel}
        footer={[
            <Button key="cancel" onClick={onCancel}>
                Close
            </Button>,
        ]}
        width="80%"
        destroyOnClose={true}
    >
        <Table
            rowKey="ind_code"
            dataSource={indicators}
            columns={[
                { 
                    title: 'Code', 
                    dataIndex: 'ind_code',
                    sorter: (a, b) => a.ind_code - b.ind_code,
                    render: (indCode) => <Link to={`/indicator/${indCode}`}>{indCode}</Link>,
                },
                { 
                    title: 'Name', 
                    dataIndex: 'ind_name_eng',
                    filters: indicatorFilterOptions,
                    onFilter: (value, record) => record.ind_name_eng === value,
                    sorter: (a, b) => a.ind_name_eng.localeCompare(b.ind_name_eng),
                    render: (indNameEng, record) => <Link to={`/indicator/${record.ind_code}`}>{indNameEng}</Link>,
                    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                        <FilterDropdown
                            options={indicatorFilterOptions}
                            setSelectedKeys={setSelectedKeys}
                            selectedKeys={selectedKeys}
                            confirm={confirm}
                            clearFilters={clearFilters}
                        />
                    ),
                },
                { title: 'Explanation', dataIndex: 'ind_eng_exp' },
            ]}
            size="small"
            pagination={{ pageSize: 50 }}
        />
    </Modal>
};