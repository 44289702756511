import React, { createContext, useContext, useState } from 'react';
import { message } from 'antd';

const MessageContext = createContext();

export const useMessage = () => useContext(MessageContext);

export const MessageProvider = ({ children }) => {
  const showMessage = ({ type, content, duration = 3 }) => {
    message[type](content, duration);
  };

  return (
    <MessageContext.Provider value={{ showMessage }}>
      {children}
    </MessageContext.Provider>
  );
};