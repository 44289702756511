import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosOICStat from '../AxiosOICStat';

export const fetchCategories = createAsyncThunk(
    'categories/fetchCategories',
    async () => {
        const response = await AxiosOICStat.get(`categories`);
        let categories = {};
        response.data.forEach(category => {
            categories[category.cat_code] = category;
        });
        return categories;
    }
);

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        status: 'idle',
        ready: false,
        value: [],
    },
    reducers: {
        setCategories(state, action) {
            state.value = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCategories.pending, (state) => {
            state.status = 'loading';
        }).addCase(fetchCategories.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.value = action.payload;
            state.ready = true;
        }).addCase(fetchCategories.rejected, (state) => {
            state.status = 'failed';
        });
    },
});

export default categoriesSlice.reducer;