import React, { useState, useEffect } from 'react';
import AxiosOICStat from '../AxiosOICStat';
import { Select, Button, Row, Col, Tooltip, Checkbox, Card, Collapse } from 'antd';
import DataTable from '../components/DataTable';
import { useSelector } from 'react-redux';
import { renderPercentageChange } from '../components/Utils';

const { Option } = Select;

export function GrowthRate({ auto_search = false }) {
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState("yearly");
    const [selectedYears, setSelectedYears] = useState([]);
    const [startYear, setStartYear] = useState(null);
    const [endYear, setEndYear] = useState(null);
    const [useYearRange, setUseYearRange] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const countriesState = useSelector((state) => state.countries);
    const indicatorsState = useSelector((state) => state.indicators);
    const categoriesState = useSelector((state) => state.categories);
    const unitsState = useSelector((state) => state.units);
    const [regionMappingsData, setRegionMappingsData] = useState([]);
    const [countryMap, setCountryMap] = useState({});
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [isRegionIncluded, setIsRegionIncluded] = useState(false);
    const [columns, setColumns] = useState(["c_name", "ind_name", "d_date", "u_name"]);

    const renderTagPlaceholder = (omittedValues) => {
        const sortedOmittedValues = [...omittedValues].sort((a, b) => parseInt(a.value) - parseInt(b.value));
        const fullList = sortedOmittedValues.map(val => val.label).join('\n');
        return (
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{fullList}</span>}>
                <span>{`+${omittedValues.length} more`}</span>
            </Tooltip>
        );
    };

    useEffect(() => {
        if (auto_search) {
            handleSearch();
        }
    }, [auto_search]);

    const generateYears = () => {
        const currentYear = new Date().getFullYear();
        const startYear = 1970;
        let years = [];

        switch (selectedPeriod) {
            case "yearly":
                for (let year = startYear; year <= currentYear; year++) {
                    years.push(year);
                }
                break;
            case "5 yearly":
                for (let year = startYear; year <= currentYear; year += 5) {
                    years.push(year);
                    years.push(year + 1);
                }
                break;
            case "10 yearly":
                for (let year = startYear; year <= currentYear; year += 10) {
                    years.push(year);
                    years.push(year + 1);
                }
                break;
            default:
                break;
        }
        if (selectedPeriod !== "yearly") {
            years.pop();
        }

        return years;
    }

    function transformData(logs) {
        const transformed = [];
        let prevData = "";
        let prevDate = "";

        let i = 0;
        while (i < logs.length) {
            const log = logs[i];
            const { d_year, d_value, ...newLog } = log;  // Exclude d_year and d_value

            let j = i;
            while (j < logs.length && logs[j].c_code == newLog.c_code && logs[j].ind_code == newLog.ind_code) {
                if (selectedPeriod !== "yearly" && logs[j].d_year % 5 !== 0) {
                    prevData = logs[j].d_value;
                    prevDate = logs[j].d_year;
                    j++;
                    continue;
                }

                newLog[logs[j].d_year] = logs[j].d_value;
                newLog[logs[j].d_year + "_growth"] = logs[j].growth_rate;
                if (prevData) {
                    newLog[logs[j].d_year + "_prev_data"] = prevData;
                    newLog[logs[j].d_year + "_prev_date"] = prevDate;
                    prevData = "";
                    prevDate = "";
                }

                if (!newLog.d_date) {
                    newLog.d_date = logs[j].d_date;
                }
                j++;
            }
            transformed.push(newLog);
            i = j; // Update i to skip over all processed logs
        }
        return transformed;
    }

    const handleSearch = () => {
        setLoading(true);
        let formattedYears = [];

        if (useYearRange && startYear && endYear) {
            formattedYears = [startYear, endYear];
        } else {
            formattedYears = generateYears();
        }

        let tableYears = formattedYears.filter(year => {
            switch (selectedPeriod) {
                case "yearly":
                    return true;
                case "5 yearly":
                    return year % 5 === 0;
                case "10 yearly":
                    return year % 10 === 0;
                default:
                    return true;
            }
        });

        console.log("tableYears", tableYears);

        setSelectedYears(tableYears);
        const formattedCountries = selectedCountries.map(country => country.split(' - ')[0]);
        const formattedIndicators = selectedIndicators.map(indicator => indicator.split(' - ')[0]);

        setIsRegionIncluded(false);
        AxiosOICStat.post('/data/query-growth-rate', {
            countries: formattedCountries,
            indicators: formattedIndicators,
            years: formattedYears
        }).then(response => {
            // also send the same request to /aggregations/query-growth-rate and merge the results
            AxiosOICStat.post('/aggregations/query-growth-rate', {
                countries: selectedRegions,
                indicators: formattedIndicators,
                years: formattedYears
            }).then(aggregationsResponse => {
                const mergedData = response.data.concat(aggregationsResponse.data);
                console.log("mergedData", mergedData);

                let updatedData = mergedData.map((element, index) => {
                    element.key = index;
                    element.u_name = unitsState.value[element.u_code]?.unit_name_eng;
                    element.cat_name = categoriesState.value[element.cat_code]?.cat_name_eng;
                    element.c_name = countriesState.value[element.c_code]?.c_short_name_eng;
                    if (element.r_code) {
                        element.r_name = regionMappingsData.find(region => region.id === element.r_code)?.region_name;
                        setIsRegionIncluded(true);
                    }
                    return element;
                });
                updatedData = transformData(updatedData);
                console.log("updatedData", updatedData);
                setSearchResults(updatedData);
                setLoading(false);
            });
        });
    };

    const exportToExcel = () => {
        let prevDate = "";
        switch (selectedPeriod) {
            case "yearly":
                prevDate = 1;
                break;
            case "5 yearly":
                prevDate = 4;
                break;
            case "10 yearly":
                prevDate = 9;
                break;
            default:
                prevDate = 1;
                break;
        }

        if (useYearRange && startYear && endYear) {
            prevDate = endYear - startYear;
        }

        const years = !useYearRange ? selectedYears : [selectedYears[1]] 
                    
        const headers = [
            "Region", "Country", "Category", "Indicator", "Unit",
            ...years.map(year => `${year - prevDate} - ${year}`)
        ];
    
        const dataForExport = searchResults.map(item => {
            const row = {
                "Region": item.r_name ? `"${item.r_name}"` : "\" \"",
                "Country": item.c_name != null ? `"${item.c_name}"` : "\"\"",
                "Category": `"${item.cat_name}"`,
                "Indicator": `"${item.ind_name}"`,
                "Unit": `"${item.u_name}"`
            };
    
            years.forEach(year => {
                // Use a consistent key format as defined in headers
                const key = `${year - prevDate} - ${year}`;
                row[key] = item[`${year}_growth`] != null ? `"${Number(item[`${year}_growth`]).toFixed(3)}"` : "\"\"";
            });
            return row;
        });
    
        // CSV file generation process
        const BOM = '\uFEFF';
        const csvContent = BOM + [
            headers.join(','),
            ...dataForExport.map(row => headers.map(header => row[header]).join(','))
        ].join('\n');
    
        // Create a Blob from the CSV String
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        // Create an anchor element and use it for the download
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'growth_rates.csv'); // Set the file name for the download
        document.body.appendChild(link);
        link.click();
        // Clean up
        document.body.removeChild(link);
    };

    useEffect(() => {
        setSelectedPeriod("yearly");
    }, [useYearRange]);

    useEffect(() => {
        setSelectedCountries(selectedCountries.sort((a, b) => parseInt(a) - parseInt(b)));
        setSelectedIndicators(selectedIndicators.sort((a, b) => parseInt(a) - parseInt(b)));
    },
        [selectedCountries, selectedIndicators]
    );

    useEffect(() => {
        const fetchRegionMappings = async () => {
            try {
                const response = await AxiosOICStat.get('/region-mappings/');
                setRegionMappingsData(response.data);
            } catch (error) {
                console.error('Failed to fetch region mappings:', error);
            }
        };

        fetchRegionMappings();
    }, []);

    const fetchRegionCountries = async (regionId) => {
        try {
            const response = await AxiosOICStat.get(`/regions-countries/${regionId}`);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch region countries:', error);
        }
    };

    const regions = [
        {
            id: 1,
            label: 'Regions',
            children: <Row gutter={[8, 8]}>
                {regionMappingsData && regionMappingsData.map((region) => (
                    <Col span={6} key={region.id}>
                        <Checkbox
                            key={region.id}
                            checked={selectedRegions.includes(region.id)}
                            onChange={(e) => {
                                fetchRegionCountries(region.id).then(countries => {
                                    let updatedCountries = countries.map(fetchedCountry => {
                                        let country = Object.values(countriesState.value)
                                            .filter(stateCountry => stateCountry.c_code === fetchedCountry.country_id)[0]

                                        if (country && country.c_short_name_eng) {
                                            country = country.c_short_name_eng;
                                        } else return '';
                                        return `${fetchedCountry.country_id} - ${country}`;
                                    });

                                    updatedCountries = updatedCountries.filter(country => country !== '');

                                    const differenceCountries = []

                                    if (e.target.checked) {
                                        setCountryMap(prevMap => {
                                            const newMap = { ...prevMap };
                                            updatedCountries.forEach(country => {
                                                if (newMap[country] === undefined || newMap[country] === 0) {
                                                    newMap[country] = 1;
                                                    differenceCountries.push(country);
                                                } else {
                                                    newMap[country] += 1;
                                                }
                                            });
                                            return newMap;
                                        });
                                    } else {
                                        setCountryMap(prevMap => {
                                            const newMap = { ...prevMap };
                                            updatedCountries.forEach(country => {
                                                if (newMap[country] > 1) {
                                                    newMap[country] -= 1;
                                                } else {
                                                    newMap[country] = 0;
                                                    differenceCountries.push(country);
                                                }
                                            });
                                            return newMap;
                                        });
                                    }

                                    setSelectedRegions(e.target.checked
                                        ? [...selectedRegions, region.id]
                                        : selectedRegions.filter(selectedRegion => selectedRegion !== region.id)
                                    );

                                    setSelectedCountries(prevCountries => {
                                        if (e.target.checked) {
                                            return [...new Set([...prevCountries, ...differenceCountries])];
                                        } else {
                                            return prevCountries.filter(country => !differenceCountries.includes(country));
                                        }
                                    });

                                });
                            }}
                            value={region.id}
                        >
                            {region.region_name}
                        </Checkbox>
                    </Col>
                ))}
            </Row>
        }
    ]

    /*
    const dataColumn = {
        title: 'Value',
        dataIndex: '1976',
        key: '1976',
        sorter: (a, b) => a.d_value - b.d_value,
        render: (_, record) => renderPercentageChange(_, "1976", record["1976"], record.growth_rate)
    }
    */

    useEffect(() => {
        if (isRegionIncluded) {
            setColumns(["c_name", "r_name", "ind_name", "d_date", "u_name"]);
        } else {
            setColumns(["c_name", "ind_name", "d_date", "u_name"]);
        }
    }, [isRegionIncluded]);

    return (
        <>
            {!auto_search &&
                <Row style={{ marginBottom: '10px' }}>
                    <Collapse size="small" items={regions} bordered={false} style={{ marginBottom: "10px", width: "100%", backgroundColor: "white" }} />
                    <Row gutter={16} align="middle" style={{ marginBottom: 16 }}>
                        <Select
                            mode="multiple"
                            placeholder="Select Countries"
                            onChange={setSelectedCountries}
                            maxTagCount={2}
                            maxTagPlaceholder={renderTagPlaceholder}
                            style={{ minWidth: '200px', marginBottom: '5px', marginRight: '10px' }}
                            showSearch
                            value={selectedCountries}
                        >
                            {Object.values(countriesState.value)
                                .filter(country => selectedCountries.includes(String(country.c_code)))
                                .concat(Object.values(countriesState.value).filter(country => !selectedCountries.includes(String(country.c_code))))
                                .map(country => <Option key={country.c_code} value={`${country.c_code} - ${country.c_short_name_eng}`}>{`${country.c_code} - ${country.c_short_name_eng}`}</Option>)}
                        </Select>
                        <Select
                            mode="multiple"
                            placeholder="Select Indicators"
                            onChange={setSelectedIndicators}
                            style={{ minWidth: '500px', marginBottom: '5px', marginRight: '10px' }}
                            maxTagCount={3}
                            maxTagPlaceholder={renderTagPlaceholder}
                            showSearch
                        >
                            {Object.values(indicatorsState.value)
                                .filter(indicator => selectedIndicators.includes(String(indicator.ind_code)))
                                .concat(Object.values(indicatorsState.value).filter(indicator => !selectedIndicators.includes(String(indicator.ind_code))))
                                .map(indicator => <Option key={indicator.ind_code} value={`${indicator.ind_code} - ${indicator.ind_name_eng}`}>{`${indicator.ind_code} - ${indicator.ind_name_eng}`}</Option>)}
                        </Select>


                        <Col>
                            <Checkbox
                                checked={useYearRange}
                                onChange={(e) => setUseYearRange(e.target.checked)}
                            >
                                Select Specific Years
                            </Checkbox>
                        </Col>

                        {useYearRange ? (
                            <>
                                <Select
                                    placeholder="Base Year"
                                    onChange={setStartYear}
                                    value={startYear}
                                    style={{ minWidth: '100px', marginRight: '10px' }}
                                >
                                    {generateYears().map(year => (
                                        <Option key={year} value={year}>{year}</Option>
                                    ))}
                                </Select>
                                <Select
                                    placeholder="End Year"
                                    onChange={setEndYear}
                                    value={endYear}
                                    style={{ minWidth: '100px', marginRight: '10px' }}
                                >
                                    {generateYears().map(year => (
                                        <Option key={year} value={year}>{year}</Option>
                                    ))}
                                </Select>
                            </>
                        ) : (
                            <Select
                                placeholder="Select Period"
                                onChange={setSelectedPeriod}
                                value={selectedPeriod}
                                style={{ minWidth: '200px', marginBottom: '5px', marginRight: '10px' }}
                            >
                                <Option value="yearly">Yearly</Option>
                                <Option value="5 yearly">5 Yearly</Option>
                                <Option value="10 yearly">10 Yearly</Option>
                            </Select>
                        )}

                        <Button type="primary" onClick={handleSearch} style={{ marginLeft: '10px', }}>Search</Button>
                    </Row>
                </Row>
            }
            <Row>
                <Col xs={24}>
                    <DataTable data={searchResults} columns={columns} widths={['10%', '60%', '10%', '10%']} loading={loading} colYearView={true} yearRange={selectedYears} showGrowthRate={true} />
                </Col>
            </Row>

            <Button onClick={exportToExcel} type="primary" style={{ marginLeft: '10px' }}>
                Export to CSV
            </Button>
        </>
    );
};
