import React from 'react';
import AxiosOICStat from '../AxiosOICStat';
import { Form, Input, Button, message } from 'antd';
import { Card, Row, Col } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom'; // Import useHistory

export const Register = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate(); // Initialize useNavigate hook

    const onFinish = async (values) => {
        try {
            const response = await AxiosOICStat.post('/users', values);
            toast.success('Registration successful!');
            setTimeout(() => {
                navigate('/login');
            }, 500);
        } catch (error) {
            toast.error('Registration failed' + error.message);
        }
    };

    // Custom validator for password match
    const validatePassword = (_, value) => {
        if (value && value !== form.getFieldValue('password')) {
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        }
        return Promise.resolve();
    };
    const registerRowStyle = {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };
    const registerCardStyle = {
        boxShadow: '0px 4px 8px 0 rgba(0,0,0,0.2)',
        width: '100%',
        maxWidth: '500px'
    };
    return (
        <Row style={registerRowStyle}>
            <Col xs={24} sm={12} md={8} lg={6}>
                <Card style={registerCardStyle} title="Register" bordered={false}>
                    <Form form={form} name="register" onFinish={onFinish} scrollToFirstError layout="vertical">
                        <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
                            <Input placeholder="Username" />
                        </Form.Item>

                        <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
                            <Input.Password placeholder="Password" />
                        </Form.Item>

                        <Form.Item name="confirm" dependencies={['password']} hasFeedback rules={[
                            { required: true, message: 'Please confirm your Password!' },
                            { validator: validatePassword }
                        ]}>
                            <Input.Password placeholder="Confirm Password" />
                        </Form.Item>

                        <Form.Item name="first_name" rules={[{ required: true, message: 'Please input your First Name!' }]}>
                            <Input placeholder="First Name" />
                        </Form.Item>

                        <Form.Item name="last_name" rules={[{ required: true, message: 'Please input your Last Name!' }]}>
                            <Input placeholder="Last Name" />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">Register</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};
