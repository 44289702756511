import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosOICStat from '../AxiosOICStat';

export const fetchCountries = createAsyncThunk(
    'countries/fetchCountries',
    async () => {
        const response = await AxiosOICStat.get(`countries`);
        let countries = {};
        response.data.forEach(country => {
            countries[country.c_code] = country;
        });
        return countries;
    }
);

export const countriesSlice = createSlice({
    name: 'countries',
    initialState: {
        status: 'idle',
        ready: false,
        value: [],
    },
    reducers: {
        setCountries(state, action) {
            state.value = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCountries.pending, (state) => {
            state.status = 'loading';
        }).addCase(fetchCountries.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.value = action.payload;
            state.ready = true;
        }).addCase(fetchCountries.rejected, (state) => {
            state.status = 'failed';
        });
    },
});

export default countriesSlice.reducer;
