import React from 'react';
import { Switch, DatePicker } from 'antd';
import dayjs from 'dayjs'; 

export function CarryForwardCheck({ carryForwardCheck, onSwitchChange, onYearChange }) {
    const handleDateChange = (date) => {
        if (date) {
            const year = date.year();  
            onYearChange(year);  
        } else {
            onYearChange(null);  
        }
    };

    const minYear = 1970;
    const currentYear = dayjs().year();

    const disabledDate = (current) => {
        return current && (current.year() < minYear || current.year() > currentYear);
    };

    const handleSwitchChange = (checked) => {
        onSwitchChange(checked); 
    };

    return (
        <div style={{ display: 'flex', height: '30px' }}>
            <div style={{ alignItems: "center", justifyContent: "center", alignSelf: "center", marginRight: "5px" }}>
                <Switch
                    checked={carryForwardCheck}
                    onChange={handleSwitchChange} 
                    checkedChildren={<span>Carry Forward On</span>}
                    unCheckedChildren={<span>Carry Forward Off</span>}
                />
            </div>
            {carryForwardCheck && (
                <DatePicker
                    placeholder='Select Base Year'
                    picker="year"
                    style={{ marginLeft: '10px', height: '25px' }}
                    onChange={handleDateChange}
                    disabledDate={disabledDate}  
                />
            )}
        </div>
    );
}
