import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosOICStat from '../AxiosOICStat';

export const fetchUnits = createAsyncThunk(
  'units/fetchUnits',
  async () => {
    const response = await AxiosOICStat.get('units/summary');
    let units = {};
    response.data.forEach(unit => {
      units[unit.unit_code] = unit;
    });
    return units;
  }
);

export const unitsSlice = createSlice({
  name: 'units',
  initialState: {
    status: 'idle',
    ready: false,
    value: [],
  },
  reducers: {
    setUnits(state, action) {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnits.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUnits.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.value = action.payload;
        state.ready = true;
      })
      .addCase(fetchUnits.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default unitsSlice.reducer;