import React, { useEffect } from 'react';
import { Button, Col, Row } from 'antd';
import { DateObject } from 'react-multi-date-picker';

function PresetValuesPlugin({ state, setState, setValue }) {

  const handlePresetSelection = (preset) => {
    const currentYear = new Date().getFullYear();
    const startYear = new DateObject()
    const endYear = new DateObject()
    startYear.setFormat('YYYY')
    endYear.setFormat('YYYY')

    if (preset === 'lastYear') {
      startYear.subtract(1, "year") // January 1st of the start year;
      endYear.subtract(1, "year") // December 31st of the end year;
    } else if (preset === 'last5Years') {
      startYear.subtract(5, "year") // January 1st of the start year;
      endYear.subtract(1, "year") // December 31st of the end year;
    } else if (preset === 'last10Years') {
      startYear.subtract(10, "year") // January 1st of the start year;
      endYear.subtract(1, "year") // December 31st of the end year;
    } else if (preset === 'all') {
      startYear.setYear(1970) // January 1st of the start year;
      endYear.setYear(currentYear) // December 31st of the end year;
    }

    const dates = [[
      startYear,
      endYear
    ]];

    setState({
      ...state,
      selectedDate: dates,
      focused: dates,
    });

    setValue(dates);
  };

  // setState({
  //   ...state,
  //   date: new DateObject(date),
  //   focused: multiple && range ? date : selectedDate[index],
  // });

  return (
    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', rowGap: '8px', margin: '8px' }}>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="text" onClick={() => handlePresetSelection('thisYear')}>This Year</Button>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="text" onClick={() => handlePresetSelection('lastYear')}>Last Year</Button>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="text" onClick={() => handlePresetSelection('last5Years')}>Last 5 Years</Button>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="text" onClick={() => handlePresetSelection('last10Years')}>Last 10 Years</Button>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="text" onClick={() => handlePresetSelection('all')}>All</Button>
      </Row>
    </Col>
  );
}



export default PresetValuesPlugin;