import React, { useEffect, useState, useMemo } from 'react';
import AxiosOICStat from '../AxiosOICStat';
import { Row, Card, Col, Table, Spin, Button, Form, Input } from "antd";
import { useParams } from 'react-router-dom';
import { Descriptions } from 'antd';
import SDMXExporter from '../components/SDMXExporter';
import { useSelector } from 'react-redux';
import { FilterDropdown } from '../components/FilterDropdown';
import * as XLSX from 'xlsx';
import { IndicatorEditModal } from '../components/modals/IndicatorEdit';


export const IndicatorInfo = ({ ind_code: propIndCode }) => {
    const { ind_code: urlIndCode } = useParams();
    const ind_code = propIndCode || urlIndCode;
    const [indicator, setIndicator] = useState({});
    const [countryData, setCountryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    //const [editMode, setEditMode] = useState(false);
    const [form] = Form.useForm();
    const [editModalVisible, setEditModalVisible] = useState(false);
    const user = useSelector((state) => state.user);

    const countriesState = useSelector(state => state.countries.value);
    const categoriesState = useSelector((state) => state.categories);
    const sourcesState = useSelector((state) => state.sources);
    const unitsState = useSelector((state) => state.units);



    // Define filter options for Country Name
    const countryNameFilterOptions = useMemo(() => {
        const uniqueCountryNames = new Set(Object.values(countriesState).map(country => country.c_short_name_eng));
        return Array.from(uniqueCountryNames).map(name => ({ text: name, value: name }));
    }, [countriesState]);


    // Define filter options for Year
    const yearFilterOptions = useMemo(() => {
        const uniqueYears = new Set(countryData.map(data => data.year));
        return Array.from(uniqueYears).map(year => ({ text: year.toString(), value: year.toString() }));
    }, [countryData]);


    const fetchCountryMetadata = () => {
        AxiosOICStat.get(`indicators/${ind_code}`).then(response => {
            setIndicator(response.data);
            setFilteredData(response.data);
            form.setFieldsValue(response.data);
        }).catch(err => {
            console.log(err);
        });
    };

    useEffect(() => {
        if (!ind_code) return;
        setLoading(true);
        fetchCountryMetadata();

        // Fetch last year's data for this indicator
        AxiosOICStat.get(`indicators/${ind_code}/last-year-data`).then(response => {
            const updatedData = response.data.map(item => {
                const countryName = countriesState[item.country_id]?.c_short_name_eng || 'Unknown';
                return { ...item, country_name: countryName };
            });
            setCountryData(updatedData);
        }).catch(err => {
            console.log(err);
        }).finally(() => setLoading(false));
    }, [countriesState, ind_code]);

    const columns = [
        {
            title: 'Country Code',
            dataIndex: 'country_id',
            key: 'country_id',
            sorter: (a, b) => a.country_id - b.country_id,
        },
        {
            title: 'Country Name',
            dataIndex: 'country_name',
            key: 'country_name',
            filters: countryNameFilterOptions,
            onFilter: (value, record) => record.country_name === value,
            sorter: (a, b) => a.country_name.localeCompare(b.country_name),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <FilterDropdown
                    options={countryNameFilterOptions}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                />
            ),
        },
        {
            title: 'Last Year Available',
            dataIndex: 'year',
            key: 'year',
            filters: yearFilterOptions,
            onFilter: (value, record) => record.year.toString() === value, // Ensure correct comparison
            sorter: (a, b) => a.year - b.year,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <FilterDropdown
                    options={yearFilterOptions}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                />
            ),
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            sorter: (a, b) => a.value - b.value,
        }
        // Add more columns if needed
    ];


    const exportToExcel = () => {
        // edit the order of the columns
        const columnsOrder = ['country_id', 'country_name', 'year', 'value'];
        const orderedData = countryData.map(item => {
            const orderedItem = {};
            columnsOrder.forEach(col => {
                orderedItem[col] = item[col];
            });
            return orderedItem;
        });


        const worksheet = XLSX.utils.json_to_sheet(orderedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Country Data");

        // Define the file name
        const fileName = `Indicator_${ind_code}_LastYearData.xlsx`;

        // Write and download the file
        XLSX.writeFile(workbook, fileName);
    };

    /*
    const toggleEditMode = () => {
        setEditMode(!editMode);
    };
    */

    const handleEdit = (record) => {
        setEditModalVisible(true);
    };


    const handleEditModalCancel = (status=0) => {
        setEditModalVisible(false);
        if (status === 1) {
            fetchCountryMetadata();
        }
    };

    /*
    const onSave = () => {
        form.validateFields().then(values => {
            AxiosOICStat.put(`indicators/${ind_code}`, values)
                .then(() => {
                    setIndicator(values);
                    setEditMode(false);
                })
                .catch(err => {
                    console.log(err);
                });
        });
    };
    */

    const cardTitle = (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{indicator?.ind_name_eng}</span>
            { user.role !== 2 && <Button type="primary" onClick={handleEdit}>Edit</Button> }
        </div>
    );

    const indDescription = (
        <Descriptions column={1}>
            <Descriptions.Item label="Code">{indicator?.ind_code}</Descriptions.Item>
            <Descriptions.Item label="Name">{indicator?.ind_name_eng}</Descriptions.Item>
            <Descriptions.Item label="Explanation">{indicator?.ind_eng_exp}</Descriptions.Item>

            <Descriptions.Item label="Category Name">
                {categoriesState.value[indicator?.cat_code]?.cat_name_eng} - {indicator?.cat_code}
            </Descriptions.Item>
            <Descriptions.Item label="Unit Name">
                {unitsState.value[indicator?.unit_code]?.unit_name_eng} - {indicator?.unit_code}
            </Descriptions.Item>
            <Descriptions.Item label="Source Name">
                {sourcesState.value[indicator?.source_code]?.source_name_eng}
            </Descriptions.Item>
            <Descriptions.Item label="Source Code">{indicator?.source_code}</Descriptions.Item>
            <Descriptions.Item label="Name (French)">{indicator?.ind_name_fr}</Descriptions.Item>
            <Descriptions.Item label="Explanation (French)">{indicator?.ind_fr_exp}</Descriptions.Item>
            {
                // <Descriptions.Item label="Name (Arabic)">{indicator?.ind_name_arb}</Descriptions.Item>
                // <Descriptions.Item label="Explanation (Arabic)">{indicator?.ind_arb_exp}</Descriptions.Item>
            }
            <Descriptions.Item label="Is Private">{indicator?.is_private === 0 ? "non-private" : "private"}</Descriptions.Item>
            <Descriptions.Item label="Type">
                {indicator?.ind_type ? indicator?.ind_type : 'null'}
            </Descriptions.Item>
            <Descriptions.Item label="Aggregation Method">
                {indicator?.aggr_method ? indicator?.aggr_method : 'null'}
            </Descriptions.Item>
        </Descriptions>
    );

    /*
    const indForm = (
        <Form form={form} onFinish={onSave}>
            <Form.Item label="Code" name="ind_code">
                <Input />
            </Form.Item>
            <Form.Item label="Name" name="ind_name_eng">
                <Input />
            </Form.Item>
            <Form.Item label="Explanation" name="ind_eng_exp">
                <Input.TextArea />
            </Form.Item>
            <Form.Item label="Name (French)" name="ind_name_fr">
                <Input />
            </Form.Item>
            <Form.Item label="Name (Arabic)" name="ind_name_arb">
                <Input />
            </Form.Item>
            <Form.Item label="Explanation (French)" name="ind_fr_exp">
                <Input.TextArea />
            </Form.Item>
            <Form.Item label="Explanation (Arabic)" name="ind_arb_exp">
                <Input.TextArea />
            </Form.Item>
            <Form.Item label="Is Private" name="is_private">
                <Input />
            </Form.Item>
            <Form.Item label="Type" name="ind_type">
                <Input />
            </Form.Item>
            <Form.Item label="Aggregation Method" name="aggr_method">
                <Input />
            </Form.Item>
        </Form>
    );
    */

    return (
        <Row>
            <Col xs={24}>
                <Card title={cardTitle}>
                    {indDescription}
                </Card>
                <Card title="Last Year Data" style={{ marginTop: 20 }}>
                    {loading ? (
                        <Spin size="large" />
                    ) : (
                        <Table
                            dataSource={countryData}
                            columns={columns}
                            loading={loading}
                            rowKey={record => `${record.country_id}-${record.year}`}
                            onChange={
                                (pagination, filters, sorter, extra) => {
                                    setFilteredData(extra.currentDataSource)
                                }
                            }
                        />
                    )}
                </Card>
            </Col>
            <Col xs={24} style={{ marginTop: 8 }}>
                <Button type="primary" onClick={exportToExcel} style={{ marginRight: 8 }}>
                    Export to Excel
                </Button>
                <SDMXExporter indicatorData={indicator} />
            </Col>
            <IndicatorEditModal
                visible={editModalVisible}
                onCancel={handleEditModalCancel}
                onEdit={handleEdit}
                record={indicator}
                editable={true}
                isAddMode={false}
            />
        </Row>
    );
};

export default IndicatorInfo;