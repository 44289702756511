import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Modal, Form, Input, message, Switch } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import AxiosOICStat from '../AxiosOICStat';
import { useSelector } from 'react-redux';
import { IndicatorTableShowModal } from '../components/modals/IndicatorTableShow';

const ActionButtons = ({ record, onEdit, onShow }) => (
    <span style={{ display: "flex", justifyContent: 'center' }}>
        <Button type="primary" style={{ marginRight: '10px' }} icon={<EditOutlined />} onClick={() => onEdit(record)}></Button>
        <Button icon={<EyeOutlined />} onClick={() => onShow(record)}>View Indicators</Button>
    </span>
);

const EditModal = ({ visible, record, onCancel, onEditSubmit }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (record && record.unit_code) {
            AxiosOICStat.get(`units/${record.unit_code}`)
                .then(response => {
                    form.setFieldsValue(response.data);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [record, form]);

    return (
        <Modal
            visible={visible}
            title="Edit Unit"
            onCancel={onCancel}
            width="50%"
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="edit" type="primary" onClick={() => form.submit()}>
                    Save
                </Button>,
            ]}
        >
            {record && (
                <Form form={form} onFinish={onEditSubmit}>
                    <Form.Item label="Code" name="unit_code">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item label="Name (ENG)" name="unit_name_eng">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Name (FR)" name="unit_name_fr">
                        <Input />
                    </Form.Item>
                    {/* Add the Arabic name field here if needed */}
                    <Form.Item label="Is Private" name="is_private">
                        <Switch />
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};

export const Units = () => {
    const [units, setUnits] = useState([]);
    const [originalUnits, setOriginalUnits] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [showModalVisible, setShowModalVisible] = useState(false);
    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const user = useSelector((state) => state.user);
    const { Search } = Input;

    const columns = [
        { title: 'Code', dataIndex: 'unit_code', key: 'unit_code' },
        { title: 'Name (ENG)', dataIndex: 'unit_name_eng', key: 'unit_name_eng' },
        { title: 'Name (FR)', dataIndex: 'unit_name_fr', key: 'unit_name_fr' },
        {
            title: 'Is Private',
            dataIndex: 'is_private',
            key: 'is_private',
            render: (is_private) => (is_private ? 'private' : 'non-private'),
        }
    ];

    if (user.role !== 2) {
        columns.push({
            title: 'Actions',
            dataIndex: '',
            key: 'actions',
            render: (text, record) => (
                <ActionButtons record={record} onEdit={handleEdit} onShow={handleShow} />
            ),
        });
    }

    useEffect(() => {
        AxiosOICStat.get('units')
            .then((response) => {
                setUnits(response.data);
                setOriginalUnits(response.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleEdit = (record) => {
        setSelectedRecord(record);
        setEditModalVisible(true);
    };

    const handleEditModalCancel = () => {
        setEditModalVisible(false);
    };

    const handleEditSubmit = (values) => {
        AxiosOICStat.put(`units/${selectedRecord.unit_code}`, values)
            .then(response => {
                message.success('Unit updated successfully');
                setUnits(prevUnits =>
                    prevUnits.map(unit =>
                        unit.unit_code === selectedRecord.unit_code ? { ...unit, ...values } : unit
                    )
                );
                handleEditModalCancel();
            })
            .catch(err => {
                console.error('Error updating unit:', err);
                message.error('Error updating unit');
            });
    };

    const handleShow = (record) => {
        setSelectedRecord(record);
        setShowModalVisible(true);
        AxiosOICStat.get(`indicators/unit/${record.unit_code}`)
            .then(response => {
                setSelectedIndicators(response.data);
            })
            .catch(err => {
                console.error('Error fetching indicators for category', record.cat_code, ':', err);
            });
    };

    const handleShowModalCancel = () => {
        setShowModalVisible(false);
        setSelectedIndicators([]);
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        const lowerCaseValue = value.toLowerCase();
        const filtered = originalUnits.filter(unit => {
            const unitCode = unit.unit_code || '';
            const unitName = unit.unit_name_eng || '';
            return unitName.toLowerCase().includes(lowerCaseValue) || unitCode.toString().includes(lowerCaseValue);
        });
        setUnits(filtered);
    };

    return (
        <Row>
            <Search
                placeholder="Search"
                allowClear
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ marginBottom: 10 }}
            />
            <Col xs={24}>
                <Table
                    rowKey="unit_code"
                    dataSource={units}
                    columns={columns}
                    size="small"
                    pagination={{ pageSize: 10 }}
                    loading={loading}
                />
            </Col>

            <IndicatorTableShowModal
                visible={showModalVisible}
                onCancel={handleShowModalCancel}
                record={selectedRecord}
                indicators={selectedIndicators}
            />

            <EditModal
                visible={editModalVisible}
                onCancel={handleEditModalCancel}
                onEditSubmit={handleEditSubmit}
                record={selectedRecord}
            />
        </Row>
    );
};

export default Units;
