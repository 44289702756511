import React, { useEffect } from "react";
import { AppLayout } from "./layout";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home } from "../pages/Home";
import { NoMatch } from "../pages/NoMatch";
import { About } from "../pages/About";
import { Users } from "../pages/Users";
import { Indicators } from "../pages/Indicators";
import { Categories } from "../pages/Categories";
import { Countries } from "../pages/Countries";
import { DataQuery } from "../pages/DataQuery";
import { CountryInfo } from "../pages/CountryInfo";
import { IndicatorInfo } from "../pages/IndicatorInfo";
import { CsvUploader } from "../pages/CsvUploader";
import Units from "../pages/Units";
import { Login } from "../pages/Login";
import { Register } from "../pages/Register";
import { DataOverview } from "../pages/DataOverview";
import { DataFirstLastYear } from "../pages/DataFirstLastYear";
import { Source } from "../pages/Source";
import { IndicatorMapping } from "../pages/IndicatorMapping";
import { ConcordanceTable } from "../pages/ConcordanceTable";
import { RegionTable } from "../pages/RegionTable";
import { useSelector } from 'react-redux';
import { ExternalMappings } from "../pages/ExternalMappings";
import { LogHomePage } from "../pages/log-viewer";
import { DataComparator } from "../pages/DataComparator";
import { DataMatching } from "../pages/DataMatching";
import { AddAggregation } from "../pages/AddAggregation";
import { AggregationQuery } from "../pages/AggregationQuery";
import { AggregationOperations } from "../pages/AggregationOperations";
import { GrowthRate } from "../pages/GrowthRate";
import { SummaryTable } from "pages/SummaryTable";
import { AdminPanel } from "pages/AdminPanel";
import { Syb } from "pages/Syb";

export const DefaultRouterProvider = () => {
  const user = useSelector((state) => state.user);

  const DefaultRouter = [
    { path: "/register", element: <Register />, },
    { path: "/login", element: <Login />, },
    {
      element: <AppLayout />,
      children: [
        { path: "/", element: <Home />, },
        { path: "/data_query", element: <DataQuery />, },
        { path: "/categories", element: <Categories />, },
        { path: "/countries", element: <Countries />, },
        { path: "/country/:c_code", element: <CountryInfo />, },
        { path: "/indicators", element: <Indicators />, },
        { path: "/indicator/:ind_code", element: <IndicatorInfo />, },
        { path: "/indicators_mapping", element: <IndicatorMapping />, },
        { path: "/external_mappings", element: <ExternalMappings />, },
        { path: "/units", element: <Units />, },
        { path: "/users", element: <Users />, },
        { path: "/about", element: <About />, },
        { path: "/csv_uploader", element: <CsvUploader />, },
        { path: "/data_overview", element: <DataOverview />, },
        { path: "/data_first_last_year", element: <DataFirstLastYear />, },
        { path: "/growth_rate", element: <GrowthRate />,},
        { path: "/php_data_comparator", element: <DataComparator />,},
        { path: "/concordance_table", element: <ConcordanceTable />, },
        { path: "/region_table", element: <RegionTable />, },
        { path: "/data-matching", element: <DataMatching />, },
        { path: "/source", element: <Source />, },
        { path: "/add_aggregation", element: <AddAggregation /> },
        { path: "/manage_aggregations", element: <AggregationOperations />},
        { path: "/summary_table", element: <SummaryTable />},
        { path: "/aggregation_query", element: <AggregationQuery /> },
        { path: "/admin_panel", element: <AdminPanel /> },
        { path: "/log_viewer", element: <LogHomePage />, },
        { path: "/syb", element: <Syb />, },
      ].filter(x => x !== null),
      errorElement: <NoMatch />
    },


  ].filter(x => x !== null);
  const router = createBrowserRouter(DefaultRouter);
  return (
    <RouterProvider router={router} />
  );
}